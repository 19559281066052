.mfp-preloader {
  color: #000;
}
.mfp-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.mfp-content {
  .wrapper {
    border-radius: 6px;
  }
  .btn.btn-primary:hover {
    background-color: rgba(153, 153, 153, 0.6);
    color: #fff;
  }
}
.mfp-close {
  width: 70px;
  height: 70px;
  line-height: 70px;
}

// posicionament botó al fer preview: https://mcps.amadoconsultores.antaviana.net/backoffice/workspace/posters/edit.php
.mfp-content .mfp-iframe-actions {
  position: absolute;
  width: 100%;
  bottom: -80px;
}

// iframe popup
.mfp-iframe-holder,
.mfp-image-holder {
  .mfp-close {
    color: #000;
    right: 0;
    top: 0;
    text-align: center;
    padding-right: 0;
    width: 70px;
  }
}
.mfp-image-holder {
  .mfp-close {
    color: #fff;
    right: -50px;
    top: -7px;
  }
}
.mfp-iframe-scaler iframe {
  border-radius: 6px;
}
