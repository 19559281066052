.navbar {
  box-shadow: none;
  background-color: theme-color("primary");
  margin-bottom: 2rem;

  @include media-breakpoint-down(sm) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 4rem;
  }

  .container {
    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .nav-item {
    border-right: 1px solid rgba($white, 0.4);
    @include media-breakpoint-down(sm) {
      border-right: 0;
    }
  }
  .nav-link {
    text-transform: none;
    color: $white !important;
    font-size: 1.125rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
    @include media-breakpoint-down(sm) {
      padding: 0.5rem 0;
    }
    i {
      vertical-align: bottom;
    }
    &--config {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .dropdown-toggle:after {
    display: none;
  }
  .dropdown-menu {
    box-shadow: none;
    background-color: theme-color("primary");
    background-image: linear-gradient(to top, #00b6e1, #007fc0);
    padding: 1rem 1rem 0;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    ul {
      list-style: none;
      padding-left: 0;
      li:last-child a {
        border-bottom: none;
      }
    }
  }
  .dropdown-item {
    display: block;
    text-align: center;
    color: $white;
    font-size: 1rem;
    border-bottom: 1px solid rgba($white, 0.4);
    padding-left: 1rem;
    padding-right: 1rem;
    @include media-breakpoint-down(sm) {
      max-width: none;
      text-align-last: left;
    }
    &:hover {
      background-color: theme-color("primary");
    }
    &.active {
      color: #fff;
      background-color: theme-color("primary");
    }
  }
}
.navbar .navbar-collapse {
  @include media-breakpoint-down(sm) {
    margin: 1rem 0;
  }
  .navbar-nav .nav-link {
    @include media-breakpoint-up(sm) {
      padding-left: 0.7rem;
      padding-right: 0.7rem;
    }
    @include media-breakpoint-up(lg) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
.navbar-expand-md .navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
  @include media-breakpoint-up(lg) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.navbar-expand-md .navbar-nav > li:first-child a {
  padding-left: 0;
}

.usr-dropdown {
  a {
    color: $black;
    font-size: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }
    img {
      vertical-align: middle;
    }
  }
  .dropdown-header {
    padding: 0.5rem 1rem;
  }
  .dropdown-menu {
    top: 45px !important;
    .dropdown-item {
      padding: 0.5rem 1rem;
      &.active {
        font-weight: 700;
        &:hover {
          background-color: #f8f9fa;
        }
      }
    }
    a {
      font-size: 1rem;
    }
  }
}
