// https://google.github.io/material-design-icons/#icon-font-for-the-web

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

// botó més info
.icon-more {
  display: inline-block;
  border: 1px solid theme-color("primary");
  border-radius: 7px;
  i {
    vertical-align: middle;
  }
}

// icon-pdf
.icon-pdf-small {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url("img/ico-pdf-small.png") no-repeat;
  .svg & {
    background-image: url("img/ico-pdf-small.svg");
  }
}
.icon-pdf {
  &:hover {
    .icon-pdf-small {
      background-position: -24px 0;
    }
  }
}

// icon mailchimp
.icon-mailchimp {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  width: 26px;
  height: 27px;
  background: url("img/ico-mailchimp.png") no-repeat;
  .svg & {
    background-image: url("img/ico-mailchimp.svg");
  }
}
// icon mailerlite
.icon-mailerlite {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  width: 26px;
  height: 27px;
  background: url("img/ico-mailerlite.png") no-repeat;
  .svg & {
    background-image: url("img/ico-mailerlite.svg");
  }
}
.icon-mail {
  &:hover {
    .icon-mailchimp {
      background-position: -26px 0;
    }
    .icon-mailerlite {
      background-position: -24px 0;
    }
  }
}

// icon amb xifra
.material-icons + strong {
  margin-left: 0.25rem;
}
