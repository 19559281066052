.box {
  padding: 1.5rem;
  height: 100%;

  // accions portada
  &--action {
    background-color: #9dbecf;
    position: relative;
    transition: background-color 0.2s ease-in-out;
    text-align: center;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
    &:hover {
      background-color: #7b9ba5;
    }
    a {
      color: #fff;
      &:hover {
        text-decoration: none;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
    .box__title {
      &:before {
        content: "";
        background-repeat: no-repeat;
        display: block;
        margin: 0 auto 1rem;
        @include media-breakpoint-down(sm) {
          transform: scale(0.8);
          transform-origin: left;
        }
        @include media-breakpoint-up(md) {
          margin-left: 0;
        }
      }
    }
  }
  &--butlleti {
    .box__title {
      &:before {
        background-image: url("img/ico-butlleti.svg");
        width: 100px;
        height: 38px;
        @include media-breakpoint-up(md) {
          margin-top: 44px;
        }
      }
    }
  }
  &--pdf {
    .box__title {
      &:before {
        background-image: url("img/ico-pdf.svg");
        width: 62px;
        height: 57px;
        @include media-breakpoint-up(md) {
          margin-top: 25px;
        }
      }
    }
  }
  &--compartir {
    .box__title {
      &:before {
        background-image: url("img/ico-compartir.svg");
        width: 53px;
        height: 53px;
        @include media-breakpoint-up(md) {
          margin-top: 17px;
        }
      }
    }
  }
  &--crear-doc {
    .box__title {
      &:before {
        background-image: url("img/ico-personalizar-informes.svg");
        width: 84px;
        height: 82px;
      }
    }
  }
  &--crear-poster {
    .box__title {
      &:before {
        background-image: url("img/ico-posters-neg.svg");
        width: 101px;
        height: 82px;
      }
    }
  }
  &--crear-fulleto {
    .box__title {
      &:before {
        background-image: url("img/ico-folletos-neg.svg");
        width: 78px;
        height: 47px;
        @include media-breakpoint-up(md) {
          margin-top: 35px;
        }
      }
    }
  }
  &--crear-informe {
    .box__title {
      &:before {
        background-image: url("img/ico-informes-neg.svg");
        width: 90px;
        height: 72px;
        @include media-breakpoint-up(md) {
          margin-top: 10px;
        }
      }
    }
  }
  &--crear-banner {
    .box__title {
      &:before {
        background-image: url("img/ico-crear-personalizar-imagenes.svg");
        width: 106px;
        height: 48px;
        @include media-breakpoint-up(md) {
          margin-top: 34px;
        }
      }
    }
  }
  &--crear-documentsrisc {
    .box__title {
      &:before {
        background-image: url("img/ico-documentsrisc-neg.svg");
        width: 90px;
        height: 72px;
        @include media-breakpoint-up(md) {
          margin-top: 10px;
        }
      }
    }
  }
  &--crear-documentaciogescom {
    .box__title {
      &:before {
        background-image: url("img/ico-documentaciogescom-neg.svg");
        width: 90px;
        height: 85px;
        @include media-breakpoint-up(md) {
          margin-top: 10px;
        }
      }
    }
  }

  // novetats
  &--news {
    @include media-breakpoint-up(md) {
      position: relative;
    }
    .row {
    height: 100%;
    }
    &-1 {
      background-color: #E3E8EB;
      .box__title {
        color: #7e9eae;
      }
    }
    &-2 {
      background-color: theme-color("secondary");
      .box__title {
        color: #fff;
      }
    }
    .box__title {
      margin-bottom: 1rem;
    }
    .box__text {
      line-height: 1.25;
      color: $gray-800;
      @include media-breakpoint-up(lg) {
        width: 60%;
      }
    }
    .btn {
      @include media-breakpoint-up(md) {
        position: absolute;
        right: 1.5rem;
        bottom: 1.5rem;
      }
    }
    &-image {
      .box__text {
	    @include media-breakpoint-up(lg) {
	      width: 100%;
	    }
	  }
      img {
        max-width: 100%;
        @include media-breakpoint-up(md) {
            margin-top: .5rem;
        }
      }
      .btn {
        @include media-breakpoint-up(md) {
          position: absolute;
          right: auto;
          bottom: 0;
        }
      }
    }
  }
}
.box__title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0;
  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1rem;
  }
  @media screen and (min-width: 1400px) {
    font-size: 1.25rem;
  }
}
