header {
  background-image: linear-gradient(
    to right,
    #ffffff 0%,
    #ffffff 40%,
    #00befc 100%
  );
  @include media-breakpoint-up(lg) {
    > .container,
    .navbar > .container {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}
.site-logo {
  width: 70px;
  padding-bottom: 0;
  margin-bottom: 0;
  border: 0;
  font-size: 1rem;
  span {
  	text-transform: none;
  	display: none;
  }
  a:hover {
  	text-decoration: none;
  }
  @include media-breakpoint-up(sm) {
    width: auto;
    
    span {
    	display: inline;
    	font-size: 1.2rem;
    	margin-left: 1em; 
    	font-weight: bold;
    }
  }
  &.site-title {
    span {
        display: block;
/*         font-size: 1.2rem; */
/*         margin-left: 0;  */
        font-weight: bold;
        margin-top: .5em;
        color: #007fc0;
    }  
  }
}
