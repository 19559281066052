.profiled-content {
	a {
	    color: #666666;
	    &:hover {
	       color: #333;
	    }
	}
	
    header{
	    background: none;
	}
    footer{
	    background: none;
	    color: #333;
	    p {
		    color: #333;
		    margin-bottom: 1rem;
		}
		a {
		    text-decoration: none;
		}   
	}
	
	.corporative--mark {
	    height: 33px;
	    background-color: #ccc;
	}
	.preview-text .summary {
	    border-bottom: 5px solid #ccc;
	}
	.preview-text blockquote {
	    border-top: 2px solid #ccc;
	    border-bottom: 2px solid #ccc;
	}
	.preview-text blockquote::before {
	    content: '“';
	    font-size: 110px;
	    color: #ccc;
	    display: block;
	    text-align: left;
	    width: 100%;
	    transform: none;
	    font-weight: bold;
	    top: 0;
	    left: .5rem;
	}
	.preview-text ul li::before {
	    background-color: #ccc;
	}	
	
}