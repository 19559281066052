.dropdown-menu {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.dropdown--icon {
  display: inline;
}
.dropdown-share {
  min-width: 7rem;
  font-size: 0.875rem;
  text-transform: none;
  padding-top: 0;
  padding-bottom: 0;
  .dropdown-item {
    padding: 0.5rem 1rem;
    min-height: none;
  }
}
