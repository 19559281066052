h1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 3px solid #000;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  @include media-breakpoint-up(md) {
    font-size: 1.875rem;
  }
}
// i.material-icons {
//   font-size: 1.5em;
// }
h2 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  
  &.big {  
    font-size: 1.375rem;
  }
}
h3 {
  text-transform: uppercase;
  font-size: 1rem;
  
  &.big {  
    font-size: 1.25rem;
  }  
}

// redefinir :focus
a:focus {
  outline: 1px dotted #000;
}

// status
.status--active,
.status--active a {
  color: green;
}
.status--inactive,
.status--inactive a {
  color: red;
}
