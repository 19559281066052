.heading {
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  &--icon {
    &:before {
      content: "";
      display: block;
      margin: 0 0 2rem;
      background-repeat: no-repeat;
    }
  }
  &--butlleti {
    &:before {
      width: 100px;
      height: 38px;
      background-image: url("img/ico-butlleti-2.svg");
    }
  }
  &--pdf {
    &:before {
      width: 62px;
      height: 57px;
      background-image: url("img/ico-pdf-2.svg");
    }
  }
  &--compartir {
    &:before {
      width: 59px;
      height: 59px;
      background-image: url("img/ico-compartir-2.svg");
    }
  }
  &--crear-doc {
    &:before {
      width: 84px;
      height: 82px;
      background-image: url("img/ico-personalizar-informes-2.svg");
    }
  }
  
  &--crear-poster {
    &:before {
      width: 101px;
      height: 82px;
      background-image: url("img/ico-posters.svg");
    }
  }
  &--crear-fulleto {
    &:before {
      width: 78px;
      height: 47px;
      background-image: url("img/ico-folletos.svg");
    }
  }
  &--crear-informe {
    &:before {
      width: 90px;
      height: 72px;
      background-image: url("img/ico-informes.svg");
    }
  } 
  &--crear-documentsrisc {
    &:before {
      width: 90px;
      height: 72px;
      background-image: url("img/ico-documentsrisc.svg");
    }
  }  
  &--crear-documentaciogescom {
    &:before {
      width: 90px;
      height: 85px;
      background-image: url("img/ico-documentaciogescom.svg");
    }
  }  
  &--crear-banner {
    &:before {
      width: 106px;
      height: 47px;
      background-image: url("img/ico-crear-personalizar-imagenes-2.svg");
    }
  }
  &--logo {
    &:before {
      width: 91px;
      height: 54px;
      background-image: url("img/logo-mcps.svg");
    }
  }
}
