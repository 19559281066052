.skip-link {
  position: absolute;
  top: 1.5rem;
  right: 100%; /* moves off screen */
  background-color: $white;
  padding: 0.5rem;
  font-size: 1rem;
  text-decoration: none;
  color: #000;

  &:focus {
    right: auto;
    left: 1.5rem;
    z-index: 10;
    outline: 2px solid theme-color("primary");
  }
}
