table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 1.5rem !important;
}
.dataTables_length select {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-width: 0 0 1px 0;
  border-radius: 0;

  // display: inline-block;
  height: calc(2.4375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  // line-height: 1.5;
  // color: #495057;
  // vertical-align: middle;
  background: rgba(0, 0, 0, 0)
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center !important;
  background-color: rgba(0, 0, 0, 0) !important;
  background-size: auto auto;
  background-size: 8px 10px !important;
}
.dataTables_length label {
  @include media-breakpoint-up(md) {
    margin-bottom: 0 !important;
  }
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: center !important;
  @include media-breakpoint-up(md) {
    justify-content: right !important;
  }
}
div.dataTables_wrapper div.dataTables_info {
  margin-bottom: 1rem;
}
.dataTables_filter {
  input {
    background: #e9ecef url("img/round-search-24px.svg") 90% 50% no-repeat;
    background-positon: calc(100% - 32px) 50%;
    padding-right: 3rem;
    &:focus {
      background-image: url("img/round-search-24px.svg");
      background-size: 24px 24px;
    }
  }
}
