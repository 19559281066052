.btn.btn {
  border-radius: 7px;
  border: 0;
  &:focus {
    box-shadow: none;
  }
  &-sm {
    font-size: 0.75rem;
  }
  &-lg {
    font-size: 0.875rem;
    padding: 1rem 1.5rem;
    @include media-breakpoint-up(md) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
  &-primary {
    color: $white;
    background-color: theme-color("primary");
  }
  &-more {
    color: $white;
    background-color: #7b9ba5;
    @include media-breakpoint-down(sm) {
      white-space: normal;
    }
    &:hover,
    &:focus {
      background-color: darken(#7b9ba5, 10%);
    }
  }
  &-news {
    color: #7b9ba5;
    background-color: #fff;
    &:hover,
    &:focus {
/*       background-color: darken(#fff, 10%); */
      background-color:#9dbecf !important;
      color: #fff !important;
    }
  }
  &-preview {
    color: #fff;
    background-color: #727272;
    // i {
    //   vertical-align: middle;
    //   border: 1px solid red;
    // }
    &:hover,
    &:focus {
      background-color: darken(#727272, 10%);
    }
  }

  &-save {
    background-image: url("img/round-save-24px.png");
    background-repeat: no-repeat;
    background-position: 1.5rem 50%;
    padding-left: 3.5rem;
    @include media-breakpoint-down(sm) {
      white-space: normal;
    }
    .svg & {
      background-image: url("img/round-save-24px.svg");
    }
  }

  &-delete {
    color: #fff;
    background-color: #737373;
    background-image: url("img/round-delete-24px.png");
    background-repeat: no-repeat;
    background-position: 1.5rem 50%;
    padding-left: 3.5rem;
    .svg & {
      background-image: url("img/round-delete-24px.svg");
    }
    &:hover,
    &:focus {
      background-color: darken(#737373, 10%);
    }
  }

  &-nuevo-contenido {
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(md) {
      float: right;
      margin-top: -13rem;
    }
//    @include media-breakpoint-up(xl) {
//      margin-top: -8rem;
//    }
  }
  &-loading {
  	opacity: .4;
  }
}
.btn-preview i {
  vertical-align: bottom;
  margin-right: 0.25rem;
}

.btn-container {
  text-align: center;
}
