.actions-list {
  list-style: none;
  font-size: 0.75rem;
  font-weight: 900;
  text-transform: uppercase;
  border-top: 1px solid #afafaf;
  padding-left: 0;
  > li > a,
  > li > div > a {
    display: block;
    padding: 1.25rem 0.5rem;
    color: #5a5a5a;
    border-bottom: 1px solid #afafaf;
    &:hover i {
      color: theme-color("secondary");
      text-decoration: none;
    }
  }
  i,
  span {
    color: #000;
    vertical-align: middle;
    margin-right: 1rem;
  }
  
  .dropdown .icon {
    display: inline-flex;
  }
}
