.plantilla-edit {
  position: relative;
  @include media-breakpoint-down(sm) {
    margin-top: 3rem;
  }
  @include media-breakpoint-up(md) {
    top: -5px;
  }
  h2 {
    font-weight: 400;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
    & + a {
      display: inline-block;
      vertical-align: middle;
      padding: 0.25rem;
      color: #000;
      i {
        vertical-align: middle;
      }
    }
  }
}

.plantilla-preview {
  background-color: #fff;
  margin: 1rem 0;
  padding: 1rem;
  clear: both;
  @include media-breakpoint-up(md) {
    padding: 2rem 4rem;
  }
  .logo {
    display: block;
    margin: 0 auto 2rem;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
}

.plantilla-element {
  background-color: #a2e7fe;
  padding: 1rem 3rem 1rem 1rem;
  margin-bottom: 1rem;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    cursor: move;
  }
  &--empty {
    min-height: 4rem;
    background-color: #f2f2f2;
    padding-right: 1rem;
    &:hover {
      cursor: default;
    }
  }
  span.move {
  	margin: -1rem -3rem -1rem -1rem;
  	padding: 1rem 3rem 1rem 1rem;
  	display:block;	
  }
  .title {
    font-weight: bold;
    text-transform: none;
    color: #323232;
    margin-bottom: 0;
  }
  .deleteContent,
  .editContent,
  .icon-done {
    display: block;
    position: absolute;
    color: #000;
  }
  .deleteContent {
    right: -1.75rem;
    top: 0;
    color: #000;
  }
  .editContent {
    color: #000;
    top: 0.5rem;
    right: 0.5rem;
  }
  .icon-done {
    display: none;
    top: 0.25rem;
    right: 0.25rem;
    color: #fff;
  }
  &--highlight {
    background-color: darken(#a2e7fe, 15%);
    .deleteContent {
      display: none;
    }
    .icon-done {
      display: block;
    }
  }
  
  .alert {
	margin: 0 0 .75rem;
	padding: .5rem;
	line-height: 110%;
	font-size: .85rem;
	position: relative;
	
	&.alert-warning {
		&:after {
		    left: 100%;
		    top: 0;
		    border: solid transparent;
		    content: " ";
		    height: 0;
		    width: 0;
		    position: absolute;
		    pointer-events: none;
		    border-color: rgba(136, 183, 213, 0);
		    border-left-color: #fff3cd;
		    border-top-color: #fff3cd;
		    border-width: 5px;
		}	
	}
	
    &.alert-danger {
        &:after {
            left: 100%;
            top: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-left-color: #f8d7da;
            border-top-color: #f8d7da;
            border-width: 5px;
        }   
    }	
	
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
}


ul.ui-sortable {
	li.ui-sortable-placeholder {
		background-color: #ebebeb;
		padding: 1rem 0;
		margin-bottom: 1rem;
	}
}


.editContent.rippleEffect:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, .5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;	
  animation: ripple 1s infinite;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(10, 10);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(20, 20);
  }
}