.preview-text {
  margin-bottom: 1.5rem;
  .mfp-content & {
    margin-bottom: 0;
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
  h1 {
    text-transform: none;
    padding-bottom: 0;
    border: 0;
    font-size: normal;
  }
  p,
  ul,
  ol {
    font-size: 1.125rem;
    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
      .mfp-content & {
        font-size: 1.125rem;
      }
    }
    
    

	  // classes directes a l'element de text
	  &.smallest {
		 font-size: .75rem;
	  }
	  &.smaller {
		 font-size: .875rem;
	  }
	  &.small {
		 font-size: 1rem;
	  }
	  &.big {
		 font-size: 1.5rem;
	  }
	  &.bigger {
	   font-size: 1.75rem;
	  }
	  &.biggest {
	   font-size: 2rem;
	  }    
  }
  .summary {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    .mfp-content & {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
        .mfp-content & {
          font-size: 1.125rem;
        }
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
        .mfp-content & {
          font-size: 1.125rem;
        }
      }
    }
  }
  blockquote {
    margin: 3rem 0;
    font-style: italic;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    padding: 1.5rem 0 1.5rem 6rem;
    position: relative;
    &:before {
      content: "";
      display: block;
      background: url("img/round-format_quote-24px.svg") no-repeat;
      background-size: 100%;
      width: 80px;
      height: 80px;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      transform: scaleX(-1);
    }
    p {
      margin-bottom: 0;
    }
  }
  ul,
  ol {
    padding-left: 4rem;
  }
  ul {
    list-style: none;
    li {
      position: relative;
      &:before {
        content: "";
        width: 0.5em;
        height: 0.5em;
        display: block;
        position: absolute;
        background-color: #007fc0;
        left: -1em;
        top: 0.5em;
      }
    }
  }
  img {
    max-width: 100%;
  }
  p.attachment {
    /*font-size: 1rem;*/
    margin-top: 2rem;
    margin-bottom: 2rem;
    a {
      padding: 0.25em 0 0.25em 30px;
      background: url("img/round-attach_file-24px.png") 0 50% no-repeat;
      .svg & {
        background-image: url("img/round-attach_file-24px.svg");
      }
    }
  }
  .subtitle {
    font-weight: 700;
    text-transform: uppercase;
    margin: 2rem 0;
  }
  table {
    td,
    th {
      vertical-align: top;
      border-bottom: 1px solid #666;
      padding: 0.5rem;
    }
    th {
      vertical-align: bottom;
    }
  }
  p img {
    margin-bottom: 1.5rem;
  }
}
