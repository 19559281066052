fieldset {
  margin-bottom: 2rem;
}
legend {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
}
.col-form-label {
  text-transform: uppercase;
  display: block;
}
.custom-file-control,
.form-control {
  background-color: $gray-200;
  padding: 1.125rem 1rem;
  height: auto;
  border-radius: 0;
  .wrapper--1 &,
  .wrapper--2 & {
    background-color: #fff;
  }
}
.custom-file-control:focus,
.form-control:focus {
  background-color: $gray-100;
  box-shadow: none;
}

// wrapper
.form-groups-wrapper {
  margin-bottom: 3rem;
  @include media-breakpoint-up(xl) {
    padding-right: 15rem;
  }
}

// search
.form-group--search {
  position: relative;
  input {
    padding-right: 4rem;
  }
}
.btn-search {
  border: 0;
  position: absolute;
  right: 0;
  bottom: 1px;
  line-height: 51px;
  padding: 0 1rem;
  background-color: transparent;
  i {
    vertical-align: middle;
  }
}

// select
.custom-select {
  background-color: #fff;
  border-width: 0 0 1px 0;
  border-radius: 0;
  padding: 0.9rem 1rem;
  height: auto;
}

// textarea
textarea.form-control {
  height: 10rem;
}

// checkbox
.checkbox label {
  color: #000;
}

// custom forms
.custom-control-label:before {
  top: 0;
  background-color: #eaeaea;
  box-shadow: none;
  .wrapper--1 &,
  .wrapper--2 & {
    background-color: #fff;
  }
}
.custom-control-label:after {
  top: 0;
}

// Toggle switch
.c-toggle {
  margin-top: 0.25rem;

  label {
    // margin: 0 .5em 0;
    cursor: pointer;
  }

  input[type="radio"] {
    display: inline-block;
    margin-right: -2px;
    width: 50%;
    height: 100%;
    opacity: 0;
    position: relative;
    z-index: 1;
    cursor: pointer;

    &:focus ~ .c-toggle__switcher {
      border: 2px solid #fff;
      // top: -1px;
    }
  }
}

.c-toggle__wrapper {
  // font-size: var(--baseSize);
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
  width: 4em;
  height: 2em;
  border-radius: 2em; /* = height */
  // border: 1px solid #bdbdbd;
  position: relative; // create positioning context for ::after
  margin: 0 0.5rem;
}
.c-toggle__switcher {
  // the toggle
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 100%;
  width: 50%;
  height: 100%;
  border-radius: 50%;
  background-color: theme-color("primary");
  transition: all 0.1s ease-out;
  z-index: 2;
}

.c-toggle__background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 3.5em;
  background-color: #fff;
  transition: all 0.1s ease-out;
}

#inactive:checked ~ .c-toggle__switcher {
  right: 0;
  left: 50%;
}

#inactive:checked ~ .c-toggle__background {
  background-color: #737373;
}

// a {
// 	display: block;
// 	margin: 2em 0;
// 	padding: .5em;

// 	&:focus {
// 		outline: 3px solid;
// 	}
// }

/* support Windows High Contrast Mode. Credit: Adrian Roselli https://twitter.com/aardrian/status/1021372139990134785 */
@media screen and (-ms-high-contrast: active) {
  .c-toggle__switcher {
    background-color: windowText;
  }
}

// editform
.editform {
  h2 {
    display: inline-block;
    vertical-align: middle;
    float: left;
    width: calc(100% - 50px);
    & + a {
      display: inline-block;
      vertical-align: middle;
      padding: 0.25rem;
      color: #000;
      float: right;
    }
  }
}

.form-control-color {
  padding: 10px;
  height: 52px;
}
.deleteFile {
  margin-top: 10px;
  span {
    vertical-align: text-bottom;
    i {
      vertical-align: bottom;
    }
  }
}

// select2
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 0;
}
.select2-container--default .select2-selection--multiple {
  border-radius: 0;
  border: 0;
}
.select2-container .select2-selection--multiple {
  min-height: 52px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0 12px;
  margin-top: 9px;
}
.select2-dropdown {
  border: 0;
  border-radius: 0;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: theme-color("primary");
}

// treure el border vermell
.custom-file-control:invalid,
.form-control:invalid {
  box-shadow: none;
  background-image: linear-gradient(
      to top,
      #007fc0 2px,
      rgba(0, 127, 192, 0) 2px
    ),
    linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px);
}

// error
.form-text.error {
  color: #d50000;
}
#pexels {
	.fileThumbnail img {
		max-width: 150px;
	}
}
#pexels_search {
	//display: none;
	padding: 20px;
	border: 1px solid #ccc;
}
