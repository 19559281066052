body.login {
    background: url(./img/logo-fons.png) 50% 7em no-repeat #F8F8F8;
    background-size: auto auto;
    background-size: 60%;

    @include media-breakpoint-up(md) {
      background-size: 20%;
    }

    .card {
        margin-top: 190px;
    }

    @include media-breakpoint-up(sm) {
      .card {
        margin-top: 260px;
      }
    }
    @include media-breakpoint-up(lg) {
      .card {
        margin-top: 300px;
      }
    }


    .formlogin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: 0 auto;
    }

	.container-404 {
	    margin-top: 12em;

	    @include media-breakpoint-up(sm) {
	      margin-top: 15em;
	    }

	    @include media-breakpoint-up(lg) {
	      margin-top: 20em;
	    }
	}
}

picture,
.login-banner {
    background: #025a96;
    text-align: center;
    width: 100%;
    margin-bottom: 4em;
    img {
        max-width: 100%;
        height: auto;
    }
}
