/*edge + ie*/
_:-ms-lang(x),
body.vex-open  {
  overflow: auto;
}

.vex-overlay {
  background: rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
}
.vex.vex-theme-plain .vex-content {
  font-family: lato, sans-serif;
  color: #000;
  border-radius: 6px;
}
.vex.vex-theme-plain .vex-dialog-button {
  border-radius: 7px;
  letter-spacing: 0;
  font-size: 0.875rem;
  padding: 1rem 1.5rem;
  font-family: lato, sans-serif;
  font-weight: 900;
}
.vex.vex-theme-plain .vex-dialog-button.vex-dialog-button-primary {
  background: #007fc0;
}
