.steps {
  list-style: none;
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 2rem;
  padding-left: 0;
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }
  li {
    display: inline-block;
    &.current {
      font-weight: bold;
    }
  }
  i {
    vertical-align: middle;
    color: $gray-600;
  }
  a {
    color: #000;
  }
}
