.table {
  // llistat documents
  &--docs {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 1.5rem;
    .home & {
      border-top: 2px solid $black;
    }
    th,
    td {
      vertical-align: middle;
    }
    thead th {
      font-weight: 700;
      color: #000;
      font-size: 1rem;
    }

    // llistat de documents dins de tabs
    .tab-content & {
      td,
      th {
        padding-left: 0;
        padding-right: 0;
        border-top: 0;
        border-bottom: 1px solid #afafaf;
      }
      .icons .icon {
        display: inline-block;
        vertical-align: middle;
        i {
          vertical-align: bottom;
        }
      }
    }

    .meta {
      font-size: 0.875rem;
      color: theme-color("primary");
      margin-bottom: 0.5rem;
      font-weight: 400;
    }
    .title {
      font-size: 1.0625rem;
      margin-bottom: 0;
      font-weight: bold;
      a {
        color: $black;
      }
    }
  }

  // icones
  // colocació d'icones i botons en una taula
  &--icons {
    .icons {
      text-align: right;
      white-space: nowrap;
      @include media-breakpoint-up(md) {
        white-space: nowrap;
      }
      .icon {
        color: #000;
        display: inline-flex;
        padding: 0.5rem 0.75rem;
        align-items: center;
        @include media-breakpoint-up(md) {
          padding-left: 1rem;
          padding-right: 1rem;
        }
        &.removeFavorites {
          color: theme-color("secondary");
        }
        &:hover {
          color: theme-color("secondary");
          text-decoration: none;
        }
      }
      .btn {
        margin-bottom: 0;
      }
    }
  }
}
