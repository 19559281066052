#the-canvas {
  width: 90%;
  max-width: 900px;
  display: block;
  margin: 0 auto;
}

.pdf_navigation {
  text-align: center;
  padding: 20px;
  button {
    background-color: transparent !important;
  }
}
