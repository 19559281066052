@keyframes vex-pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent; }
  70% {
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25); }
  100% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent; } }

@-webkit-keyframes vex-pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent; }
  70% {
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25); }
  100% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent; } }

@-moz-keyframes vex-pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent; }
  70% {
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25); }
  100% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent; } }

@-ms-keyframes vex-pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent; }
  70% {
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25); }
  100% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent; } }

@-o-keyframes vex-pulse {
  0% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent; }
  70% {
    -moz-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    -webkit-box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25); }
  100% {
    -moz-box-shadow: inset 0 0 0 300px transparent;
    -webkit-box-shadow: inset 0 0 0 300px transparent;
    box-shadow: inset 0 0 0 300px transparent; } }

.vex.vex-theme-plain {
  padding-top: 160px;
  padding-bottom: 160px; }
  .vex.vex-theme-plain .vex-content {
    font-family: "Helvetica Neue", sans-serif;
    background: #fff;
    color: #444;
    padding: 1em;
    position: relative;
    margin: 0 auto;
    max-width: 100%;
    width: 450px;
    font-size: 1.1em;
    line-height: 1.5em; }
    .vex.vex-theme-plain .vex-content h1, .vex.vex-theme-plain .vex-content h2, .vex.vex-theme-plain .vex-content h3, .vex.vex-theme-plain .vex-content h4, .vex.vex-theme-plain .vex-content h5, .vex.vex-theme-plain .vex-content h6, .vex.vex-theme-plain .vex-content p, .vex.vex-theme-plain .vex-content ul, .vex.vex-theme-plain .vex-content li {
      color: inherit; }
  .vex.vex-theme-plain .vex-close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
    .vex.vex-theme-plain .vex-close:before {
      position: absolute;
      content: "\00D7";
      font-size: 26px;
      font-weight: normal;
      line-height: 31px;
      height: 30px;
      width: 30px;
      text-align: center;
      top: 3px;
      right: 3px;
      color: #bbb;
      background: transparent; }
    .vex.vex-theme-plain .vex-close:hover:before, .vex.vex-theme-plain .vex-close:active:before {
      color: #777;
      background: #e0e0e0; }
  .vex.vex-theme-plain .vex-dialog-form .vex-dialog-message {
    margin-bottom: .5em; }
  .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input {
    margin-bottom: 1em; }
    .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input textarea, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="date"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="datetime"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="datetime-local"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="email"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="month"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="number"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="password"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="search"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="tel"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="text"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="time"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="url"], .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="week"] {
      background: #f0f0f0;
      width: 100%;
      padding: .25em .67em;
      border: 0;
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      min-height: 2.5em;
      margin: 0 0 .25em; }
      .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input textarea:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="date"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="email"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="month"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="number"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="password"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="search"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="tel"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="text"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="time"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="url"]:focus, .vex.vex-theme-plain .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
        -moz-box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
        outline: none; }
  .vex.vex-theme-plain .vex-dialog-form .vex-dialog-buttons {
    *zoom: 1; }
    .vex.vex-theme-plain .vex-dialog-form .vex-dialog-buttons:after {
      content: "";
      display: table;
      clear: both; }
  .vex.vex-theme-plain .vex-dialog-button {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0;
    float: right;
    margin: 0 0 0 .5em;
    font-family: inherit;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: .8em;
    line-height: 1em;
    padding: .75em 2em; }
    .vex.vex-theme-plain .vex-dialog-button.vex-last {
      margin-left: 0; }
    .vex.vex-theme-plain .vex-dialog-button:focus {
      animation: vex-pulse 1.1s infinite;
      -webkit-animation: vex-pulse 1.1s infinite;
      -moz-animation: vex-pulse 1.1s infinite;
      -ms-animation: vex-pulse 1.1s infinite;
      -o-animation: vex-pulse 1.1s infinite;
      -webkit-backface-visibility: hidden;
      outline: none; }
      @media (max-width: 568px) {
        .vex.vex-theme-plain .vex-dialog-button:focus {
          animation: none;
          -webkit-animation: none;
          -moz-animation: none;
          -ms-animation: none;
          -o-animation: none;
          -webkit-backface-visibility: hidden; } }
    .vex.vex-theme-plain .vex-dialog-button.vex-dialog-button-primary {
      background: #3288e6;
      color: #fff; }
    .vex.vex-theme-plain .vex-dialog-button.vex-dialog-button-secondary {
      background: #e0e0e0;
      color: #777; }

.vex-loading-spinner.vex-theme-plain {
  height: 2.5em;
  width: 2.5em; }
