.plantilla-select {
  text-align: center;
  padding: 2rem 0;
  margin: 0;
  @include media-breakpoint-up(md) {
    padding: 3rem 0;
  }
  &--selected {
    background-color: #a2e7fe;
  }
  h2 {
    font-weight: 400;
    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }
  }
  img {
    margin-bottom: 1rem;
    max-width: 145px;
    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }
  }
}
.actions {
  .icon {
    color: #000;
    vertical-align: middle;
    margin-left: 0.5rem;
    padding-top: 7px;
  }
}

.item--image {
  position: relative;
  & .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
  }

  img {
    width: 100%;
    height: 8.8125rem;
    object-fit: cover;
  }

  &:hover {
    & .overlay {
      display: block;
      background: rgba(0, 0, 0, 0.3);
    }

    & .item--image-actions {
      opacity: 1;
    }
  }

  &-actions {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    text-align: center;
    opacity: 0;
    transition: opacity 0.35s ease;
    transform: translateY(-50%);
  }

  .btn {
    background-color: #ffffff;
    color: #007fc0;

    &:hover {
      background-color: rgba(153, 153, 153, 0.9);
      border-color: rgba(153, 153, 153, 0.9);
    }
  }

  &--selected {
    & .overlay {
      display: block;
      background: rgba(0, 127, 192, 0.6);
    }
    & .item--image-actions {
      opacity: 1;
    }
  }
}

.text-normal {
    text-transform: none !important;
}
