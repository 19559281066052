.preview-template {
  width: 100%;
  margin-bottom: 1.5rem;
  height: 40rem;
  border: 0;
  @include media-breakpoint-up(md) {
    height: 45rem;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(md) {
    height: 50rem;
    margin-bottom: 0;
  }
}
