@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local('Lato Light Italic'), local('Lato-LightItalic'), url(../fonts/Lato/Lato-Light-Italic.ttf) format('truetype'), url(../fonts/Lato/Lato-Light-Italic.woff2) format('woff2'), url(../fonts/Lato/Lato-Light-Italic.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local('Lato Italic'), local('Lato-Italic'), url(../fonts/Lato/Lato-Italic.ttf) format('truetype'), url(../fonts/Lato/Lato-Italic.woff2) format('woff2'), url(../fonts/Lato/Lato-Italic.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url(../fonts/Lato/Lato-Bold-Italic.ttf) format('truetype'), url(../fonts/Lato/Lato-Bold-Italic.woff2) format('woff2'), url(../fonts/Lato/Lato-Bold-Italic.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(../fonts/Lato/Lato-Light.ttf) format('truetype'), url(../fonts/Lato/Lato-Light.woff2) format('woff2'), url(../fonts/Lato/Lato-Light.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Lato/Lato-Regular.eot);
  src: local('Lato Regular'), local('Lato-Regular'), local('Lato'), url(../fonts/Lato/Lato-Regular.ttf) format('truetype'), url(../fonts/Lato/Lato-Regular.svg#Lato) format('svg'), url(../fonts/Lato/Lato-Regular.eot?#iefix) format('embedded-opentype'), url(../fonts/Lato/Lato-Regular.woff2) format('woff2'), url(../fonts/Lato/Lato-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(../fonts/Lato/Lato-Bold.ttf) format('truetype'), url(../fonts/Lato/Lato-Bold.woff2) format('woff2'), url(../fonts/Lato/Lato-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Black'), local('Lato-Black'), url(../fonts/Lato/Lato-Black.ttf) format('truetype'), url(../fonts/Lato/Lato-Black.woff2) format('woff2'), url(../fonts/Lato/Lato-Black.woff) format('woff');
}

