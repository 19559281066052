.tooltip {
  font-weight: 700;
  text-transform: uppercase;
}
.tooltip.show {
  opacity: 1;
}
.tooltip-inner {
  color: #fff;
  background-color: theme-color("secondary");
  border-radius: 0;
  font-size: 11px;
}

.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: theme-color("secondary");
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: theme-color("secondary");
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: theme-color("secondary");
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: theme-color("secondary");
}
