.grid {
  margin-bottom: 2rem;
  &--simple {
    margin-bottom: 0;
  }
  &--border {
    @include media-breakpoint-up(md) {
      border-bottom: solid 1px #979797;
    }
  }
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  &__item {
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
  &--6 {
    .grid__item {
      @include media-breakpoint-up(md) {
        width: 33.33%;
      }
      @include media-breakpoint-up(xl) {
        width: 16.66%;
      }
    }
  }
  &--4 {
    .grid__item {
      @include media-breakpoint-up(md) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 25%;
      }
    }
  }
  &--3 {
    .grid__item {
      @include media-breakpoint-up(md) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 33.333%;
      }
    }
  }
  &--2 {
    .grid__item {
      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }
  }
}
