.alert.alert-flash {
  position: fixed;
  width: 60%;
  left: 50%;
  margin-left: -30%; /* Half of the width */
  text-align: center;
  top: 3rem;
  z-index: 10;
  opacity: 0.95;
  p {
    margin-bottom: 0;
  }
}
