.page-item:first-child .page-link,
.page-item:last-child .page-link {
  &:hover {
    background-color: transparent;
  }
}
.page-link {
  border-radius: 0 !important;
  border: 0;
}
