@charset "UTF-8";
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007fc0;
  --secondary: #00befc;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: lato;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007fc0;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #004c74;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 1200px) {
    .container {
      max-width: 1440px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000; }
  .table th,
  .table td {
    padding: 1rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8dbed; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abcde; }

.table-hover .table-primary:hover {
  background-color: #a4d1e8; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a4d1e8; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b8edfe; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #7addfd; }

.table-hover .table-secondary:hover {
  background-color: #9fe7fe; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #9fe7fe; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control, .custom-file-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control, .custom-file-control {
      transition: none; } }
  .form-control::-ms-expand, .custom-file-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus, .custom-file-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #41bfff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 127, 192, 0.25); }
  .form-control::placeholder, .custom-file-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .custom-file-control:disabled, .form-control[readonly], .custom-file-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value, select.custom-file-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.custom-file-control[size], select.form-control[multiple], select.custom-file-control[multiple] {
  height: auto; }

textarea.form-control, textarea.custom-file-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .was-validated .custom-file-control:valid, .form-control.is-valid, .is-valid.custom-file-control {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .was-validated .custom-file-control:valid:focus, .form-control.is-valid:focus, .is-valid.custom-file-control:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback, .was-validated .custom-file-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip,
  .was-validated .custom-file-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .is-valid.custom-file-control ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip,
  .is-valid.custom-file-control ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, .was-validated textarea.custom-file-control:valid, textarea.form-control.is-valid, textarea.is-valid.custom-file-control {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .was-validated .custom-file-control:invalid, .form-control.is-invalid, .is-invalid.custom-file-control {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .was-validated .custom-file-control:invalid:focus, .form-control.is-invalid:focus, .is-invalid.custom-file-control:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .custom-file-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip,
  .was-validated .custom-file-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .is-invalid.custom-file-control ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip,
  .is-invalid.custom-file-control ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, .was-validated textarea.custom-file-control:invalid, textarea.form-control.is-invalid, textarea.is-invalid.custom-file-control {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control, .form-inline .custom-file-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .custom-file-control::before {
  display: inline-block;
  font-weight: 900;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .custom-file-control::before {
      transition: none; } }
  .btn:hover, .custom-file-control:hover::before {
    color: #000;
    text-decoration: none; }
  .btn:focus, .custom-file-control:focus::before, .btn.focus, .focus.custom-file-control::before {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 127, 192, 0.25); }
  .btn.disabled, .disabled.custom-file-control::before, .btn:disabled, .custom-file-control:disabled::before {
    opacity: 0.65; }

a.btn.disabled, a.disabled.custom-file-control::before,
fieldset:disabled a.btn,
fieldset:disabled a.custom-file-control::before {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007fc0;
  border-color: #007fc0; }
  .btn-primary:hover {
    color: #fff;
    background-color: #00669a;
    border-color: #005d8d; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 146, 201, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007fc0;
    border-color: #007fc0; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #005d8d;
    border-color: #005580; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 146, 201, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #00befc;
  border-color: #00befc; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #00a1d6;
    border-color: #0098c9; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 200, 252, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #00befc;
    border-color: #00befc; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0098c9;
    border-color: #008ebc; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 200, 252, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007fc0;
  border-color: #007fc0; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007fc0;
    border-color: #007fc0; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 127, 192, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007fc0;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007fc0;
    border-color: #007fc0; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 127, 192, 0.5); }

.btn-outline-secondary {
  color: #00befc;
  border-color: #00befc; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #00befc;
    border-color: #00befc; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 190, 252, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #00befc;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #00befc;
    border-color: #00befc; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 190, 252, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007fc0;
  text-decoration: none; }
  .btn-link:hover {
    color: #004c74;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007fc0; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007fc0;
    background-color: #007fc0; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 127, 192, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #41bfff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #74d0ff;
    border-color: #74d0ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007fc0;
  background-color: #007fc0; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 127, 192, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 127, 192, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 127, 192, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 127, 192, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #41bfff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 127, 192, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #41bfff;
    box-shadow: 0 0 0 0.2rem rgba(0, 127, 192, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 127, 192, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 127, 192, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 127, 192, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007fc0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #74d0ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007fc0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #74d0ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007fc0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #74d0ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007fc0; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0 0;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.5rem;
        padding-left: 1.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 1.5rem;
      padding-left: 1.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: #fff; }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: transparent; }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: #fff; }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007fc0;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #004c74;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 127, 192, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007fc0;
  border-color: #007fc0; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004264;
  background-color: #cce5f2;
  border-color: #b8dbed; }
  .alert-primary hr {
    border-top-color: #a4d1e8; }
  .alert-primary .alert-link {
    color: #002031; }

.alert-secondary {
  color: #006383;
  background-color: #ccf2fe;
  border-color: #b8edfe; }
  .alert-secondary hr {
    border-top-color: #9fe7fe; }
  .alert-secondary .alert-link {
    color: #003c50; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: lato;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007fc0 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #005d8d !important; }

.bg-secondary {
  background-color: #00befc !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #0098c9 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007fc0 !important; }

.border-secondary {
  border-color: #00befc !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007fc0 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #004c74 !important; }

.text-secondary {
  color: #00befc !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #0084b0 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #000 !important; }

.text-muted, .bmd-help {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn):not(.custom-file-control::before) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

body {
  font-weight: 400; }

a:focus {
  outline: none; }

button:focus {
  outline: none; }

.bmd-layout-canvas {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.bmd-layout-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.bmd-layout-header {
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 100%;
  max-height: 1000px;
  transform: translateZ(0);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.bmd-layout-content {
  position: relative;
  z-index: 1;
  display: inline-block;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.bmd-layout-spacer {
  flex-grow: 1; }

.bmd-layout-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: transparent;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  @supports (pointer-events: auto) {
    .bmd-layout-backdrop {
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition-property: opacity;
      visibility: visible;
      pointer-events: none; } }

.btn, .custom-file-control::before {
  position: relative;
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow, transform;
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: #ccc; }
  .btn:hover, .custom-file-control:hover::before {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.2);
    border-color: rgba(153, 153, 153, 0.2); }
  .btn:focus, .custom-file-control:focus::before, .btn.focus, .focus.custom-file-control::before {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.2);
    border-color: rgba(153, 153, 153, 0.2); }
  .btn:active, .custom-file-control:active::before, .btn.active, .active.custom-file-control::before,
  .open > .btn.dropdown-toggle,
  .open > .dropdown-toggle.custom-file-control::before {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(153, 153, 153, 0.2);
    border-color: rgba(153, 153, 153, 0.2); }
    .btn:active:hover, .custom-file-control:active:hover::before, .btn:active:focus, .custom-file-control:active:focus::before, .btn:active.focus, .custom-file-control:active.focus::before, .btn.active:hover, .active.custom-file-control:hover::before, .btn.active:focus, .active.custom-file-control:focus::before, .btn.active.focus, .active.focus.custom-file-control::before,
    .open > .btn.dropdown-toggle:hover,
    .open > .dropdown-toggle.custom-file-control:hover::before,
    .open > .btn.dropdown-toggle:focus,
    .open > .dropdown-toggle.custom-file-control:focus::before,
    .open > .btn.dropdown-toggle.focus,
    .open > .dropdown-toggle.focus.custom-file-control::before {
      color: rgba(0, 0, 0, 0.87);
      background-color: rgba(153, 153, 153, 0.4);
      border-color: rgba(153, 153, 153, 0.4); }
  .open > .btn.dropdown-toggle.bmd-btn-icon, .open > .dropdown-toggle.bmd-btn-icon.custom-file-control::before {
    color: inherit;
    background-color: transparent; }
    .open > .btn.dropdown-toggle.bmd-btn-icon:hover, .open > .dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
      background-color: rgba(153, 153, 153, 0.2); }
  .btn.disabled:focus, .disabled.custom-file-control:focus::before, .btn.disabled.focus, .disabled.focus.custom-file-control::before, .btn:disabled:focus, .custom-file-control:disabled:focus::before, .btn:disabled.focus, .custom-file-control:disabled.focus::before {
    background-color: transparent;
    border-color: #ccc; }
  .btn.disabled:hover, .disabled.custom-file-control:hover::before, .btn:disabled:hover, .custom-file-control:disabled:hover::before {
    background-color: transparent;
    border-color: #ccc; }
  .bg-inverse .btn, .bg-inverse .custom-file-control::before {
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    border-color: #ccc; }
    .bg-inverse .btn:hover, .bg-inverse .custom-file-control:hover::before {
      color: rgba(0, 0, 0, 0.87);
      background-color: rgba(204, 204, 204, 0.15);
      border-color: rgba(204, 204, 204, 0.15); }
    .bg-inverse .btn:focus, .bg-inverse .custom-file-control:focus::before, .bg-inverse .btn.focus, .bg-inverse .focus.custom-file-control::before {
      color: rgba(0, 0, 0, 0.87);
      background-color: rgba(204, 204, 204, 0.15);
      border-color: rgba(204, 204, 204, 0.15); }
    .bg-inverse .btn:active, .bg-inverse .custom-file-control:active::before, .bg-inverse .btn.active, .bg-inverse .active.custom-file-control::before,
    .open > .bg-inverse .btn.dropdown-toggle,
    .open > .bg-inverse .dropdown-toggle.custom-file-control::before {
      color: rgba(0, 0, 0, 0.87);
      background-color: rgba(204, 204, 204, 0.15);
      border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn:active:hover, .bg-inverse .custom-file-control:active:hover::before, .bg-inverse .btn:active:focus, .bg-inverse .custom-file-control:active:focus::before, .bg-inverse .btn:active.focus, .bg-inverse .custom-file-control:active.focus::before, .bg-inverse .btn.active:hover, .bg-inverse .active.custom-file-control:hover::before, .bg-inverse .btn.active:focus, .bg-inverse .active.custom-file-control:focus::before, .bg-inverse .btn.active.focus, .bg-inverse .active.focus.custom-file-control::before,
      .open > .bg-inverse .btn.dropdown-toggle:hover,
      .open > .bg-inverse .dropdown-toggle.custom-file-control:hover::before,
      .open > .bg-inverse .btn.dropdown-toggle:focus,
      .open > .bg-inverse .dropdown-toggle.custom-file-control:focus::before,
      .open > .bg-inverse .btn.dropdown-toggle.focus,
      .open > .bg-inverse .dropdown-toggle.focus.custom-file-control::before {
        color: rgba(0, 0, 0, 0.87);
        background-color: rgba(204, 204, 204, 0.25);
        border-color: rgba(204, 204, 204, 0.25); }
    .open > .bg-inverse .btn.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .bg-inverse .btn.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(204, 204, 204, 0.15); }
    .bg-inverse .btn.disabled:focus, .bg-inverse .disabled.custom-file-control:focus::before, .bg-inverse .btn.disabled.focus, .bg-inverse .disabled.focus.custom-file-control::before, .bg-inverse .btn:disabled:focus, .bg-inverse .custom-file-control:disabled:focus::before, .bg-inverse .btn:disabled.focus, .bg-inverse .custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #ccc; }
    .bg-inverse .btn.disabled:hover, .bg-inverse .disabled.custom-file-control:hover::before, .bg-inverse .btn:disabled:hover, .bg-inverse .custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #ccc; }
  .btn.btn-link, .btn-link.custom-file-control::before {
    background-color: transparent; }
  .btn.btn-primary, .btn-primary.custom-file-control::before {
    color: #007fc0;
    background-color: transparent;
    border-color: #ccc; }
    .btn.btn-primary:hover, .btn-primary.custom-file-control:hover::before {
      color: #007fc0;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-primary:focus, .btn-primary.custom-file-control:focus::before, .btn.btn-primary.focus, .btn-primary.focus.custom-file-control::before {
      color: #007fc0;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-primary:active, .btn-primary.custom-file-control:active::before, .btn.btn-primary.active, .btn-primary.active.custom-file-control::before,
    .open > .btn.btn-primary.dropdown-toggle,
    .open > .btn-primary.dropdown-toggle.custom-file-control::before {
      color: #007fc0;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
      .btn.btn-primary:active:hover, .btn-primary.custom-file-control:active:hover::before, .btn.btn-primary:active:focus, .btn-primary.custom-file-control:active:focus::before, .btn.btn-primary:active.focus, .btn-primary.custom-file-control:active.focus::before, .btn.btn-primary.active:hover, .btn-primary.active.custom-file-control:hover::before, .btn.btn-primary.active:focus, .btn-primary.active.custom-file-control:focus::before, .btn.btn-primary.active.focus, .btn-primary.active.focus.custom-file-control::before,
      .open > .btn.btn-primary.dropdown-toggle:hover,
      .open > .btn-primary.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-primary.dropdown-toggle:focus,
      .open > .btn-primary.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-primary.dropdown-toggle.focus,
      .open > .btn-primary.dropdown-toggle.focus.custom-file-control::before {
        color: #007fc0;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: rgba(153, 153, 153, 0.4); }
    .open > .btn.btn-primary.dropdown-toggle.bmd-btn-icon, .open > .btn-primary.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-primary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-primary.disabled:focus, .btn-primary.disabled.custom-file-control:focus::before, .btn.btn-primary.disabled.focus, .btn-primary.disabled.focus.custom-file-control::before, .btn.btn-primary:disabled:focus, .btn-primary.custom-file-control:disabled:focus::before, .btn.btn-primary:disabled.focus, .btn-primary.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #ccc; }
    .btn.btn-primary.disabled:hover, .btn-primary.disabled.custom-file-control:hover::before, .btn.btn-primary:disabled:hover, .btn-primary.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #ccc; }
    .bg-inverse .btn.btn-primary, .bg-inverse .btn-primary.custom-file-control::before {
      color: #007fc0;
      background-color: transparent;
      border-color: #ccc; }
      .bg-inverse .btn.btn-primary:hover, .bg-inverse .btn-primary.custom-file-control:hover::before {
        color: #007fc0;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-primary:focus, .bg-inverse .btn-primary.custom-file-control:focus::before, .bg-inverse .btn.btn-primary.focus, .bg-inverse .btn-primary.focus.custom-file-control::before {
        color: #007fc0;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-primary:active, .bg-inverse .btn-primary.custom-file-control:active::before, .bg-inverse .btn.btn-primary.active, .bg-inverse .btn-primary.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-primary.dropdown-toggle,
      .open > .bg-inverse .btn-primary.dropdown-toggle.custom-file-control::before {
        color: #007fc0;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-primary:active:hover, .bg-inverse .btn-primary.custom-file-control:active:hover::before, .bg-inverse .btn.btn-primary:active:focus, .bg-inverse .btn-primary.custom-file-control:active:focus::before, .bg-inverse .btn.btn-primary:active.focus, .bg-inverse .btn-primary.custom-file-control:active.focus::before, .bg-inverse .btn.btn-primary.active:hover, .bg-inverse .btn-primary.active.custom-file-control:hover::before, .bg-inverse .btn.btn-primary.active:focus, .bg-inverse .btn-primary.active.custom-file-control:focus::before, .bg-inverse .btn.btn-primary.active.focus, .bg-inverse .btn-primary.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-primary.dropdown-toggle:hover,
        .open > .bg-inverse .btn-primary.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-primary.dropdown-toggle:focus,
        .open > .bg-inverse .btn-primary.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-primary.dropdown-toggle.focus,
        .open > .bg-inverse .btn-primary.dropdown-toggle.focus.custom-file-control::before {
          color: #007fc0;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-primary.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-primary.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-primary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-primary.disabled:focus, .bg-inverse .btn-primary.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-primary.disabled.focus, .bg-inverse .btn-primary.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-primary:disabled:focus, .bg-inverse .btn-primary.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-primary:disabled.focus, .bg-inverse .btn-primary.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #ccc; }
      .bg-inverse .btn.btn-primary.disabled:hover, .bg-inverse .btn-primary.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-primary:disabled:hover, .bg-inverse .btn-primary.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #ccc; }
    .btn.btn-primary.btn-link, .btn-primary.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-secondary, .btn-secondary.custom-file-control::before {
    color: #00befc;
    background-color: transparent;
    border-color: #ccc; }
    .btn.btn-secondary:hover, .btn-secondary.custom-file-control:hover::before {
      color: #00befc;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-secondary:focus, .btn-secondary.custom-file-control:focus::before, .btn.btn-secondary.focus, .btn-secondary.focus.custom-file-control::before {
      color: #00befc;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-secondary:active, .btn-secondary.custom-file-control:active::before, .btn.btn-secondary.active, .btn-secondary.active.custom-file-control::before,
    .open > .btn.btn-secondary.dropdown-toggle,
    .open > .btn-secondary.dropdown-toggle.custom-file-control::before {
      color: #00befc;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
      .btn.btn-secondary:active:hover, .btn-secondary.custom-file-control:active:hover::before, .btn.btn-secondary:active:focus, .btn-secondary.custom-file-control:active:focus::before, .btn.btn-secondary:active.focus, .btn-secondary.custom-file-control:active.focus::before, .btn.btn-secondary.active:hover, .btn-secondary.active.custom-file-control:hover::before, .btn.btn-secondary.active:focus, .btn-secondary.active.custom-file-control:focus::before, .btn.btn-secondary.active.focus, .btn-secondary.active.focus.custom-file-control::before,
      .open > .btn.btn-secondary.dropdown-toggle:hover,
      .open > .btn-secondary.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-secondary.dropdown-toggle:focus,
      .open > .btn-secondary.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-secondary.dropdown-toggle.focus,
      .open > .btn-secondary.dropdown-toggle.focus.custom-file-control::before {
        color: #00befc;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: rgba(153, 153, 153, 0.4); }
    .open > .btn.btn-secondary.dropdown-toggle.bmd-btn-icon, .open > .btn-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-secondary.disabled:focus, .btn-secondary.disabled.custom-file-control:focus::before, .btn.btn-secondary.disabled.focus, .btn-secondary.disabled.focus.custom-file-control::before, .btn.btn-secondary:disabled:focus, .btn-secondary.custom-file-control:disabled:focus::before, .btn.btn-secondary:disabled.focus, .btn-secondary.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #ccc; }
    .btn.btn-secondary.disabled:hover, .btn-secondary.disabled.custom-file-control:hover::before, .btn.btn-secondary:disabled:hover, .btn-secondary.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #ccc; }
    .bg-inverse .btn.btn-secondary, .bg-inverse .btn-secondary.custom-file-control::before {
      color: #00befc;
      background-color: transparent;
      border-color: #ccc; }
      .bg-inverse .btn.btn-secondary:hover, .bg-inverse .btn-secondary.custom-file-control:hover::before {
        color: #00befc;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-secondary:focus, .bg-inverse .btn-secondary.custom-file-control:focus::before, .bg-inverse .btn.btn-secondary.focus, .bg-inverse .btn-secondary.focus.custom-file-control::before {
        color: #00befc;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-secondary:active, .bg-inverse .btn-secondary.custom-file-control:active::before, .bg-inverse .btn.btn-secondary.active, .bg-inverse .btn-secondary.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-secondary.dropdown-toggle,
      .open > .bg-inverse .btn-secondary.dropdown-toggle.custom-file-control::before {
        color: #00befc;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-secondary:active:hover, .bg-inverse .btn-secondary.custom-file-control:active:hover::before, .bg-inverse .btn.btn-secondary:active:focus, .bg-inverse .btn-secondary.custom-file-control:active:focus::before, .bg-inverse .btn.btn-secondary:active.focus, .bg-inverse .btn-secondary.custom-file-control:active.focus::before, .bg-inverse .btn.btn-secondary.active:hover, .bg-inverse .btn-secondary.active.custom-file-control:hover::before, .bg-inverse .btn.btn-secondary.active:focus, .bg-inverse .btn-secondary.active.custom-file-control:focus::before, .bg-inverse .btn.btn-secondary.active.focus, .bg-inverse .btn-secondary.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-secondary.dropdown-toggle:hover,
        .open > .bg-inverse .btn-secondary.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-secondary.dropdown-toggle:focus,
        .open > .bg-inverse .btn-secondary.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-secondary.dropdown-toggle.focus,
        .open > .bg-inverse .btn-secondary.dropdown-toggle.focus.custom-file-control::before {
          color: #00befc;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-secondary.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-secondary.disabled:focus, .bg-inverse .btn-secondary.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-secondary.disabled.focus, .bg-inverse .btn-secondary.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-secondary:disabled:focus, .bg-inverse .btn-secondary.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-secondary:disabled.focus, .bg-inverse .btn-secondary.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #ccc; }
      .bg-inverse .btn.btn-secondary.disabled:hover, .bg-inverse .btn-secondary.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-secondary:disabled:hover, .bg-inverse .btn-secondary.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #ccc; }
    .btn.btn-secondary.btn-link, .btn-secondary.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-success, .btn-success.custom-file-control::before {
    color: #28a745;
    background-color: transparent;
    border-color: #ccc; }
    .btn.btn-success:hover, .btn-success.custom-file-control:hover::before {
      color: #28a745;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-success:focus, .btn-success.custom-file-control:focus::before, .btn.btn-success.focus, .btn-success.focus.custom-file-control::before {
      color: #28a745;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-success:active, .btn-success.custom-file-control:active::before, .btn.btn-success.active, .btn-success.active.custom-file-control::before,
    .open > .btn.btn-success.dropdown-toggle,
    .open > .btn-success.dropdown-toggle.custom-file-control::before {
      color: #28a745;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
      .btn.btn-success:active:hover, .btn-success.custom-file-control:active:hover::before, .btn.btn-success:active:focus, .btn-success.custom-file-control:active:focus::before, .btn.btn-success:active.focus, .btn-success.custom-file-control:active.focus::before, .btn.btn-success.active:hover, .btn-success.active.custom-file-control:hover::before, .btn.btn-success.active:focus, .btn-success.active.custom-file-control:focus::before, .btn.btn-success.active.focus, .btn-success.active.focus.custom-file-control::before,
      .open > .btn.btn-success.dropdown-toggle:hover,
      .open > .btn-success.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-success.dropdown-toggle:focus,
      .open > .btn-success.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-success.dropdown-toggle.focus,
      .open > .btn-success.dropdown-toggle.focus.custom-file-control::before {
        color: #28a745;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: rgba(153, 153, 153, 0.4); }
    .open > .btn.btn-success.dropdown-toggle.bmd-btn-icon, .open > .btn-success.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-success.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-success.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-success.disabled:focus, .btn-success.disabled.custom-file-control:focus::before, .btn.btn-success.disabled.focus, .btn-success.disabled.focus.custom-file-control::before, .btn.btn-success:disabled:focus, .btn-success.custom-file-control:disabled:focus::before, .btn.btn-success:disabled.focus, .btn-success.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #ccc; }
    .btn.btn-success.disabled:hover, .btn-success.disabled.custom-file-control:hover::before, .btn.btn-success:disabled:hover, .btn-success.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #ccc; }
    .bg-inverse .btn.btn-success, .bg-inverse .btn-success.custom-file-control::before {
      color: #28a745;
      background-color: transparent;
      border-color: #ccc; }
      .bg-inverse .btn.btn-success:hover, .bg-inverse .btn-success.custom-file-control:hover::before {
        color: #28a745;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-success:focus, .bg-inverse .btn-success.custom-file-control:focus::before, .bg-inverse .btn.btn-success.focus, .bg-inverse .btn-success.focus.custom-file-control::before {
        color: #28a745;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-success:active, .bg-inverse .btn-success.custom-file-control:active::before, .bg-inverse .btn.btn-success.active, .bg-inverse .btn-success.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-success.dropdown-toggle,
      .open > .bg-inverse .btn-success.dropdown-toggle.custom-file-control::before {
        color: #28a745;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-success:active:hover, .bg-inverse .btn-success.custom-file-control:active:hover::before, .bg-inverse .btn.btn-success:active:focus, .bg-inverse .btn-success.custom-file-control:active:focus::before, .bg-inverse .btn.btn-success:active.focus, .bg-inverse .btn-success.custom-file-control:active.focus::before, .bg-inverse .btn.btn-success.active:hover, .bg-inverse .btn-success.active.custom-file-control:hover::before, .bg-inverse .btn.btn-success.active:focus, .bg-inverse .btn-success.active.custom-file-control:focus::before, .bg-inverse .btn.btn-success.active.focus, .bg-inverse .btn-success.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-success.dropdown-toggle:hover,
        .open > .bg-inverse .btn-success.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-success.dropdown-toggle:focus,
        .open > .bg-inverse .btn-success.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-success.dropdown-toggle.focus,
        .open > .bg-inverse .btn-success.dropdown-toggle.focus.custom-file-control::before {
          color: #28a745;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-success.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-success.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-success.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-success.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-success.disabled:focus, .bg-inverse .btn-success.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-success.disabled.focus, .bg-inverse .btn-success.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-success:disabled:focus, .bg-inverse .btn-success.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-success:disabled.focus, .bg-inverse .btn-success.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #ccc; }
      .bg-inverse .btn.btn-success.disabled:hover, .bg-inverse .btn-success.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-success:disabled:hover, .bg-inverse .btn-success.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #ccc; }
    .btn.btn-success.btn-link, .btn-success.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-info, .btn-info.custom-file-control::before {
    color: #17a2b8;
    background-color: transparent;
    border-color: #ccc; }
    .btn.btn-info:hover, .btn-info.custom-file-control:hover::before {
      color: #17a2b8;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-info:focus, .btn-info.custom-file-control:focus::before, .btn.btn-info.focus, .btn-info.focus.custom-file-control::before {
      color: #17a2b8;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-info:active, .btn-info.custom-file-control:active::before, .btn.btn-info.active, .btn-info.active.custom-file-control::before,
    .open > .btn.btn-info.dropdown-toggle,
    .open > .btn-info.dropdown-toggle.custom-file-control::before {
      color: #17a2b8;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
      .btn.btn-info:active:hover, .btn-info.custom-file-control:active:hover::before, .btn.btn-info:active:focus, .btn-info.custom-file-control:active:focus::before, .btn.btn-info:active.focus, .btn-info.custom-file-control:active.focus::before, .btn.btn-info.active:hover, .btn-info.active.custom-file-control:hover::before, .btn.btn-info.active:focus, .btn-info.active.custom-file-control:focus::before, .btn.btn-info.active.focus, .btn-info.active.focus.custom-file-control::before,
      .open > .btn.btn-info.dropdown-toggle:hover,
      .open > .btn-info.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-info.dropdown-toggle:focus,
      .open > .btn-info.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-info.dropdown-toggle.focus,
      .open > .btn-info.dropdown-toggle.focus.custom-file-control::before {
        color: #17a2b8;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: rgba(153, 153, 153, 0.4); }
    .open > .btn.btn-info.dropdown-toggle.bmd-btn-icon, .open > .btn-info.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-info.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-info.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-info.disabled:focus, .btn-info.disabled.custom-file-control:focus::before, .btn.btn-info.disabled.focus, .btn-info.disabled.focus.custom-file-control::before, .btn.btn-info:disabled:focus, .btn-info.custom-file-control:disabled:focus::before, .btn.btn-info:disabled.focus, .btn-info.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #ccc; }
    .btn.btn-info.disabled:hover, .btn-info.disabled.custom-file-control:hover::before, .btn.btn-info:disabled:hover, .btn-info.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #ccc; }
    .bg-inverse .btn.btn-info, .bg-inverse .btn-info.custom-file-control::before {
      color: #17a2b8;
      background-color: transparent;
      border-color: #ccc; }
      .bg-inverse .btn.btn-info:hover, .bg-inverse .btn-info.custom-file-control:hover::before {
        color: #17a2b8;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-info:focus, .bg-inverse .btn-info.custom-file-control:focus::before, .bg-inverse .btn.btn-info.focus, .bg-inverse .btn-info.focus.custom-file-control::before {
        color: #17a2b8;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-info:active, .bg-inverse .btn-info.custom-file-control:active::before, .bg-inverse .btn.btn-info.active, .bg-inverse .btn-info.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-info.dropdown-toggle,
      .open > .bg-inverse .btn-info.dropdown-toggle.custom-file-control::before {
        color: #17a2b8;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-info:active:hover, .bg-inverse .btn-info.custom-file-control:active:hover::before, .bg-inverse .btn.btn-info:active:focus, .bg-inverse .btn-info.custom-file-control:active:focus::before, .bg-inverse .btn.btn-info:active.focus, .bg-inverse .btn-info.custom-file-control:active.focus::before, .bg-inverse .btn.btn-info.active:hover, .bg-inverse .btn-info.active.custom-file-control:hover::before, .bg-inverse .btn.btn-info.active:focus, .bg-inverse .btn-info.active.custom-file-control:focus::before, .bg-inverse .btn.btn-info.active.focus, .bg-inverse .btn-info.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-info.dropdown-toggle:hover,
        .open > .bg-inverse .btn-info.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-info.dropdown-toggle:focus,
        .open > .bg-inverse .btn-info.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-info.dropdown-toggle.focus,
        .open > .bg-inverse .btn-info.dropdown-toggle.focus.custom-file-control::before {
          color: #17a2b8;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-info.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-info.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-info.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-info.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-info.disabled:focus, .bg-inverse .btn-info.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-info.disabled.focus, .bg-inverse .btn-info.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-info:disabled:focus, .bg-inverse .btn-info.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-info:disabled.focus, .bg-inverse .btn-info.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #ccc; }
      .bg-inverse .btn.btn-info.disabled:hover, .bg-inverse .btn-info.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-info:disabled:hover, .bg-inverse .btn-info.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #ccc; }
    .btn.btn-info.btn-link, .btn-info.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-warning, .btn-warning.custom-file-control::before {
    color: #ffc107;
    background-color: transparent;
    border-color: #ccc; }
    .btn.btn-warning:hover, .btn-warning.custom-file-control:hover::before {
      color: #ffc107;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-warning:focus, .btn-warning.custom-file-control:focus::before, .btn.btn-warning.focus, .btn-warning.focus.custom-file-control::before {
      color: #ffc107;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-warning:active, .btn-warning.custom-file-control:active::before, .btn.btn-warning.active, .btn-warning.active.custom-file-control::before,
    .open > .btn.btn-warning.dropdown-toggle,
    .open > .btn-warning.dropdown-toggle.custom-file-control::before {
      color: #ffc107;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
      .btn.btn-warning:active:hover, .btn-warning.custom-file-control:active:hover::before, .btn.btn-warning:active:focus, .btn-warning.custom-file-control:active:focus::before, .btn.btn-warning:active.focus, .btn-warning.custom-file-control:active.focus::before, .btn.btn-warning.active:hover, .btn-warning.active.custom-file-control:hover::before, .btn.btn-warning.active:focus, .btn-warning.active.custom-file-control:focus::before, .btn.btn-warning.active.focus, .btn-warning.active.focus.custom-file-control::before,
      .open > .btn.btn-warning.dropdown-toggle:hover,
      .open > .btn-warning.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-warning.dropdown-toggle:focus,
      .open > .btn-warning.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-warning.dropdown-toggle.focus,
      .open > .btn-warning.dropdown-toggle.focus.custom-file-control::before {
        color: #ffc107;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: rgba(153, 153, 153, 0.4); }
    .open > .btn.btn-warning.dropdown-toggle.bmd-btn-icon, .open > .btn-warning.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-warning.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-warning.disabled:focus, .btn-warning.disabled.custom-file-control:focus::before, .btn.btn-warning.disabled.focus, .btn-warning.disabled.focus.custom-file-control::before, .btn.btn-warning:disabled:focus, .btn-warning.custom-file-control:disabled:focus::before, .btn.btn-warning:disabled.focus, .btn-warning.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #ccc; }
    .btn.btn-warning.disabled:hover, .btn-warning.disabled.custom-file-control:hover::before, .btn.btn-warning:disabled:hover, .btn-warning.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #ccc; }
    .bg-inverse .btn.btn-warning, .bg-inverse .btn-warning.custom-file-control::before {
      color: #ffc107;
      background-color: transparent;
      border-color: #ccc; }
      .bg-inverse .btn.btn-warning:hover, .bg-inverse .btn-warning.custom-file-control:hover::before {
        color: #ffc107;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-warning:focus, .bg-inverse .btn-warning.custom-file-control:focus::before, .bg-inverse .btn.btn-warning.focus, .bg-inverse .btn-warning.focus.custom-file-control::before {
        color: #ffc107;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-warning:active, .bg-inverse .btn-warning.custom-file-control:active::before, .bg-inverse .btn.btn-warning.active, .bg-inverse .btn-warning.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-warning.dropdown-toggle,
      .open > .bg-inverse .btn-warning.dropdown-toggle.custom-file-control::before {
        color: #ffc107;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-warning:active:hover, .bg-inverse .btn-warning.custom-file-control:active:hover::before, .bg-inverse .btn.btn-warning:active:focus, .bg-inverse .btn-warning.custom-file-control:active:focus::before, .bg-inverse .btn.btn-warning:active.focus, .bg-inverse .btn-warning.custom-file-control:active.focus::before, .bg-inverse .btn.btn-warning.active:hover, .bg-inverse .btn-warning.active.custom-file-control:hover::before, .bg-inverse .btn.btn-warning.active:focus, .bg-inverse .btn-warning.active.custom-file-control:focus::before, .bg-inverse .btn.btn-warning.active.focus, .bg-inverse .btn-warning.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-warning.dropdown-toggle:hover,
        .open > .bg-inverse .btn-warning.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-warning.dropdown-toggle:focus,
        .open > .bg-inverse .btn-warning.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-warning.dropdown-toggle.focus,
        .open > .bg-inverse .btn-warning.dropdown-toggle.focus.custom-file-control::before {
          color: #ffc107;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-warning.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-warning.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-warning.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-warning.disabled:focus, .bg-inverse .btn-warning.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-warning.disabled.focus, .bg-inverse .btn-warning.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-warning:disabled:focus, .bg-inverse .btn-warning.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-warning:disabled.focus, .bg-inverse .btn-warning.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #ccc; }
      .bg-inverse .btn.btn-warning.disabled:hover, .bg-inverse .btn-warning.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-warning:disabled:hover, .bg-inverse .btn-warning.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #ccc; }
    .btn.btn-warning.btn-link, .btn-warning.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-danger, .btn-danger.custom-file-control::before {
    color: #dc3545;
    background-color: transparent;
    border-color: #ccc; }
    .btn.btn-danger:hover, .btn-danger.custom-file-control:hover::before {
      color: #dc3545;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-danger:focus, .btn-danger.custom-file-control:focus::before, .btn.btn-danger.focus, .btn-danger.focus.custom-file-control::before {
      color: #dc3545;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-danger:active, .btn-danger.custom-file-control:active::before, .btn.btn-danger.active, .btn-danger.active.custom-file-control::before,
    .open > .btn.btn-danger.dropdown-toggle,
    .open > .btn-danger.dropdown-toggle.custom-file-control::before {
      color: #dc3545;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
      .btn.btn-danger:active:hover, .btn-danger.custom-file-control:active:hover::before, .btn.btn-danger:active:focus, .btn-danger.custom-file-control:active:focus::before, .btn.btn-danger:active.focus, .btn-danger.custom-file-control:active.focus::before, .btn.btn-danger.active:hover, .btn-danger.active.custom-file-control:hover::before, .btn.btn-danger.active:focus, .btn-danger.active.custom-file-control:focus::before, .btn.btn-danger.active.focus, .btn-danger.active.focus.custom-file-control::before,
      .open > .btn.btn-danger.dropdown-toggle:hover,
      .open > .btn-danger.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-danger.dropdown-toggle:focus,
      .open > .btn-danger.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-danger.dropdown-toggle.focus,
      .open > .btn-danger.dropdown-toggle.focus.custom-file-control::before {
        color: #dc3545;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: rgba(153, 153, 153, 0.4); }
    .open > .btn.btn-danger.dropdown-toggle.bmd-btn-icon, .open > .btn-danger.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-danger.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-danger.disabled:focus, .btn-danger.disabled.custom-file-control:focus::before, .btn.btn-danger.disabled.focus, .btn-danger.disabled.focus.custom-file-control::before, .btn.btn-danger:disabled:focus, .btn-danger.custom-file-control:disabled:focus::before, .btn.btn-danger:disabled.focus, .btn-danger.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #ccc; }
    .btn.btn-danger.disabled:hover, .btn-danger.disabled.custom-file-control:hover::before, .btn.btn-danger:disabled:hover, .btn-danger.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #ccc; }
    .bg-inverse .btn.btn-danger, .bg-inverse .btn-danger.custom-file-control::before {
      color: #dc3545;
      background-color: transparent;
      border-color: #ccc; }
      .bg-inverse .btn.btn-danger:hover, .bg-inverse .btn-danger.custom-file-control:hover::before {
        color: #dc3545;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-danger:focus, .bg-inverse .btn-danger.custom-file-control:focus::before, .bg-inverse .btn.btn-danger.focus, .bg-inverse .btn-danger.focus.custom-file-control::before {
        color: #dc3545;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-danger:active, .bg-inverse .btn-danger.custom-file-control:active::before, .bg-inverse .btn.btn-danger.active, .bg-inverse .btn-danger.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-danger.dropdown-toggle,
      .open > .bg-inverse .btn-danger.dropdown-toggle.custom-file-control::before {
        color: #dc3545;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-danger:active:hover, .bg-inverse .btn-danger.custom-file-control:active:hover::before, .bg-inverse .btn.btn-danger:active:focus, .bg-inverse .btn-danger.custom-file-control:active:focus::before, .bg-inverse .btn.btn-danger:active.focus, .bg-inverse .btn-danger.custom-file-control:active.focus::before, .bg-inverse .btn.btn-danger.active:hover, .bg-inverse .btn-danger.active.custom-file-control:hover::before, .bg-inverse .btn.btn-danger.active:focus, .bg-inverse .btn-danger.active.custom-file-control:focus::before, .bg-inverse .btn.btn-danger.active.focus, .bg-inverse .btn-danger.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-danger.dropdown-toggle:hover,
        .open > .bg-inverse .btn-danger.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-danger.dropdown-toggle:focus,
        .open > .bg-inverse .btn-danger.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-danger.dropdown-toggle.focus,
        .open > .bg-inverse .btn-danger.dropdown-toggle.focus.custom-file-control::before {
          color: #dc3545;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-danger.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-danger.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-danger.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-danger.disabled:focus, .bg-inverse .btn-danger.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-danger.disabled.focus, .bg-inverse .btn-danger.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-danger:disabled:focus, .bg-inverse .btn-danger.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-danger:disabled.focus, .bg-inverse .btn-danger.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #ccc; }
      .bg-inverse .btn.btn-danger.disabled:hover, .bg-inverse .btn-danger.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-danger:disabled:hover, .bg-inverse .btn-danger.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #ccc; }
    .btn.btn-danger.btn-link, .btn-danger.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-light, .btn-light.custom-file-control::before {
    color: #f8f9fa;
    background-color: transparent;
    border-color: #ccc; }
    .btn.btn-light:hover, .btn-light.custom-file-control:hover::before {
      color: #f8f9fa;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-light:focus, .btn-light.custom-file-control:focus::before, .btn.btn-light.focus, .btn-light.focus.custom-file-control::before {
      color: #f8f9fa;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-light:active, .btn-light.custom-file-control:active::before, .btn.btn-light.active, .btn-light.active.custom-file-control::before,
    .open > .btn.btn-light.dropdown-toggle,
    .open > .btn-light.dropdown-toggle.custom-file-control::before {
      color: #f8f9fa;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
      .btn.btn-light:active:hover, .btn-light.custom-file-control:active:hover::before, .btn.btn-light:active:focus, .btn-light.custom-file-control:active:focus::before, .btn.btn-light:active.focus, .btn-light.custom-file-control:active.focus::before, .btn.btn-light.active:hover, .btn-light.active.custom-file-control:hover::before, .btn.btn-light.active:focus, .btn-light.active.custom-file-control:focus::before, .btn.btn-light.active.focus, .btn-light.active.focus.custom-file-control::before,
      .open > .btn.btn-light.dropdown-toggle:hover,
      .open > .btn-light.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-light.dropdown-toggle:focus,
      .open > .btn-light.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-light.dropdown-toggle.focus,
      .open > .btn-light.dropdown-toggle.focus.custom-file-control::before {
        color: #f8f9fa;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: rgba(153, 153, 153, 0.4); }
    .open > .btn.btn-light.dropdown-toggle.bmd-btn-icon, .open > .btn-light.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-light.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-light.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-light.disabled:focus, .btn-light.disabled.custom-file-control:focus::before, .btn.btn-light.disabled.focus, .btn-light.disabled.focus.custom-file-control::before, .btn.btn-light:disabled:focus, .btn-light.custom-file-control:disabled:focus::before, .btn.btn-light:disabled.focus, .btn-light.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #ccc; }
    .btn.btn-light.disabled:hover, .btn-light.disabled.custom-file-control:hover::before, .btn.btn-light:disabled:hover, .btn-light.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #ccc; }
    .bg-inverse .btn.btn-light, .bg-inverse .btn-light.custom-file-control::before {
      color: #f8f9fa;
      background-color: transparent;
      border-color: #ccc; }
      .bg-inverse .btn.btn-light:hover, .bg-inverse .btn-light.custom-file-control:hover::before {
        color: #f8f9fa;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-light:focus, .bg-inverse .btn-light.custom-file-control:focus::before, .bg-inverse .btn.btn-light.focus, .bg-inverse .btn-light.focus.custom-file-control::before {
        color: #f8f9fa;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-light:active, .bg-inverse .btn-light.custom-file-control:active::before, .bg-inverse .btn.btn-light.active, .bg-inverse .btn-light.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-light.dropdown-toggle,
      .open > .bg-inverse .btn-light.dropdown-toggle.custom-file-control::before {
        color: #f8f9fa;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-light:active:hover, .bg-inverse .btn-light.custom-file-control:active:hover::before, .bg-inverse .btn.btn-light:active:focus, .bg-inverse .btn-light.custom-file-control:active:focus::before, .bg-inverse .btn.btn-light:active.focus, .bg-inverse .btn-light.custom-file-control:active.focus::before, .bg-inverse .btn.btn-light.active:hover, .bg-inverse .btn-light.active.custom-file-control:hover::before, .bg-inverse .btn.btn-light.active:focus, .bg-inverse .btn-light.active.custom-file-control:focus::before, .bg-inverse .btn.btn-light.active.focus, .bg-inverse .btn-light.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-light.dropdown-toggle:hover,
        .open > .bg-inverse .btn-light.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-light.dropdown-toggle:focus,
        .open > .bg-inverse .btn-light.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-light.dropdown-toggle.focus,
        .open > .bg-inverse .btn-light.dropdown-toggle.focus.custom-file-control::before {
          color: #f8f9fa;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-light.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-light.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-light.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-light.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-light.disabled:focus, .bg-inverse .btn-light.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-light.disabled.focus, .bg-inverse .btn-light.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-light:disabled:focus, .bg-inverse .btn-light.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-light:disabled.focus, .bg-inverse .btn-light.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #ccc; }
      .bg-inverse .btn.btn-light.disabled:hover, .bg-inverse .btn-light.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-light:disabled:hover, .bg-inverse .btn-light.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #ccc; }
    .btn.btn-light.btn-link, .btn-light.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-dark, .btn-dark.custom-file-control::before {
    color: #343a40;
    background-color: transparent;
    border-color: #ccc; }
    .btn.btn-dark:hover, .btn-dark.custom-file-control:hover::before {
      color: #343a40;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-dark:focus, .btn-dark.custom-file-control:focus::before, .btn.btn-dark.focus, .btn-dark.focus.custom-file-control::before {
      color: #343a40;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-dark:active, .btn-dark.custom-file-control:active::before, .btn.btn-dark.active, .btn-dark.active.custom-file-control::before,
    .open > .btn.btn-dark.dropdown-toggle,
    .open > .btn-dark.dropdown-toggle.custom-file-control::before {
      color: #343a40;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: rgba(153, 153, 153, 0.2); }
      .btn.btn-dark:active:hover, .btn-dark.custom-file-control:active:hover::before, .btn.btn-dark:active:focus, .btn-dark.custom-file-control:active:focus::before, .btn.btn-dark:active.focus, .btn-dark.custom-file-control:active.focus::before, .btn.btn-dark.active:hover, .btn-dark.active.custom-file-control:hover::before, .btn.btn-dark.active:focus, .btn-dark.active.custom-file-control:focus::before, .btn.btn-dark.active.focus, .btn-dark.active.focus.custom-file-control::before,
      .open > .btn.btn-dark.dropdown-toggle:hover,
      .open > .btn-dark.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-dark.dropdown-toggle:focus,
      .open > .btn-dark.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-dark.dropdown-toggle.focus,
      .open > .btn-dark.dropdown-toggle.focus.custom-file-control::before {
        color: #343a40;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: rgba(153, 153, 153, 0.4); }
    .open > .btn.btn-dark.dropdown-toggle.bmd-btn-icon, .open > .btn-dark.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-dark.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-dark.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-dark.disabled:focus, .btn-dark.disabled.custom-file-control:focus::before, .btn.btn-dark.disabled.focus, .btn-dark.disabled.focus.custom-file-control::before, .btn.btn-dark:disabled:focus, .btn-dark.custom-file-control:disabled:focus::before, .btn.btn-dark:disabled.focus, .btn-dark.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #ccc; }
    .btn.btn-dark.disabled:hover, .btn-dark.disabled.custom-file-control:hover::before, .btn.btn-dark:disabled:hover, .btn-dark.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #ccc; }
    .bg-inverse .btn.btn-dark, .bg-inverse .btn-dark.custom-file-control::before {
      color: #343a40;
      background-color: transparent;
      border-color: #ccc; }
      .bg-inverse .btn.btn-dark:hover, .bg-inverse .btn-dark.custom-file-control:hover::before {
        color: #343a40;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-dark:focus, .bg-inverse .btn-dark.custom-file-control:focus::before, .bg-inverse .btn.btn-dark.focus, .bg-inverse .btn-dark.focus.custom-file-control::before {
        color: #343a40;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-dark:active, .bg-inverse .btn-dark.custom-file-control:active::before, .bg-inverse .btn.btn-dark.active, .bg-inverse .btn-dark.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-dark.dropdown-toggle,
      .open > .bg-inverse .btn-dark.dropdown-toggle.custom-file-control::before {
        color: #343a40;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-dark:active:hover, .bg-inverse .btn-dark.custom-file-control:active:hover::before, .bg-inverse .btn.btn-dark:active:focus, .bg-inverse .btn-dark.custom-file-control:active:focus::before, .bg-inverse .btn.btn-dark:active.focus, .bg-inverse .btn-dark.custom-file-control:active.focus::before, .bg-inverse .btn.btn-dark.active:hover, .bg-inverse .btn-dark.active.custom-file-control:hover::before, .bg-inverse .btn.btn-dark.active:focus, .bg-inverse .btn-dark.active.custom-file-control:focus::before, .bg-inverse .btn.btn-dark.active.focus, .bg-inverse .btn-dark.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-dark.dropdown-toggle:hover,
        .open > .bg-inverse .btn-dark.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-dark.dropdown-toggle:focus,
        .open > .bg-inverse .btn-dark.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-dark.dropdown-toggle.focus,
        .open > .bg-inverse .btn-dark.dropdown-toggle.focus.custom-file-control::before {
          color: #343a40;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-dark.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-dark.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-dark.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-dark.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-dark.disabled:focus, .bg-inverse .btn-dark.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-dark.disabled.focus, .bg-inverse .btn-dark.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-dark:disabled:focus, .bg-inverse .btn-dark.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-dark:disabled.focus, .bg-inverse .btn-dark.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #ccc; }
      .bg-inverse .btn.btn-dark.disabled:hover, .bg-inverse .btn-dark.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-dark:disabled:hover, .bg-inverse .btn-dark.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #ccc; }
    .btn.btn-dark.btn-link, .btn-dark.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.bmd-btn-fab.btn-primary, .bmd-btn-fab.btn-primary.custom-file-control::before, .btn.btn-raised.btn-primary, .btn-raised.btn-primary.custom-file-control::before,
  .btn-group-raised .btn.btn-primary,
  .btn-group-raised .btn-primary.custom-file-control::before {
    color: #fff;
    background-color: #007fc0;
    border-color: #007fc0; }
    .btn.bmd-btn-fab.btn-primary:hover, .bmd-btn-fab.btn-primary.custom-file-control:hover::before, .btn.btn-raised.btn-primary:hover, .btn-raised.btn-primary.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-primary:hover,
    .btn-group-raised .btn-primary.custom-file-control:hover::before {
      color: #fff;
      background-color: #008cd4;
      border-color: #005783; }
    .btn.bmd-btn-fab.btn-primary:focus, .bmd-btn-fab.btn-primary.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-primary.focus, .bmd-btn-fab.btn-primary.focus.custom-file-control::before, .btn.btn-raised.btn-primary:focus, .btn-raised.btn-primary.custom-file-control:focus::before, .btn.btn-raised.btn-primary.focus, .btn-raised.btn-primary.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-primary:focus,
    .btn-group-raised .btn-primary.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-primary.focus,
    .btn-group-raised .btn-primary.focus.custom-file-control::before {
      color: #fff;
      background-color: #008cd4;
      border-color: #005783; }
    .btn.bmd-btn-fab.btn-primary:active, .bmd-btn-fab.btn-primary.custom-file-control:active::before, .btn.bmd-btn-fab.btn-primary.active, .bmd-btn-fab.btn-primary.active.custom-file-control::before,
    .open > .btn.bmd-btn-fab.btn-primary.dropdown-toggle,
    .open > .bmd-btn-fab.btn-primary.dropdown-toggle.custom-file-control::before, .btn.btn-raised.btn-primary:active, .btn-raised.btn-primary.custom-file-control:active::before, .btn.btn-raised.btn-primary.active, .btn-raised.btn-primary.active.custom-file-control::before,
    .open > .btn.btn-raised.btn-primary.dropdown-toggle,
    .open > .btn-raised.btn-primary.dropdown-toggle.custom-file-control::before,
    .btn-group-raised .btn.btn-primary:active,
    .btn-group-raised .btn-primary.custom-file-control:active::before,
    .btn-group-raised .btn.btn-primary.active,
    .btn-group-raised .btn-primary.active.custom-file-control::before,
    .open > .btn-group-raised .btn.btn-primary.dropdown-toggle,
    .open > .btn-group-raised .btn-primary.dropdown-toggle.custom-file-control::before {
      color: #fff;
      background-color: #008cd4;
      border-color: #005783; }
      .btn.bmd-btn-fab.btn-primary:active:hover, .bmd-btn-fab.btn-primary.custom-file-control:active:hover::before, .btn.bmd-btn-fab.btn-primary:active:focus, .bmd-btn-fab.btn-primary.custom-file-control:active:focus::before, .btn.bmd-btn-fab.btn-primary:active.focus, .bmd-btn-fab.btn-primary.custom-file-control:active.focus::before, .btn.bmd-btn-fab.btn-primary.active:hover, .bmd-btn-fab.btn-primary.active.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-primary.active:focus, .bmd-btn-fab.btn-primary.active.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-primary.active.focus, .bmd-btn-fab.btn-primary.active.focus.custom-file-control::before,
      .open > .btn.bmd-btn-fab.btn-primary.dropdown-toggle:hover,
      .open > .bmd-btn-fab.btn-primary.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.bmd-btn-fab.btn-primary.dropdown-toggle:focus,
      .open > .bmd-btn-fab.btn-primary.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.bmd-btn-fab.btn-primary.dropdown-toggle.focus,
      .open > .bmd-btn-fab.btn-primary.dropdown-toggle.focus.custom-file-control::before, .btn.btn-raised.btn-primary:active:hover, .btn-raised.btn-primary.custom-file-control:active:hover::before, .btn.btn-raised.btn-primary:active:focus, .btn-raised.btn-primary.custom-file-control:active:focus::before, .btn.btn-raised.btn-primary:active.focus, .btn-raised.btn-primary.custom-file-control:active.focus::before, .btn.btn-raised.btn-primary.active:hover, .btn-raised.btn-primary.active.custom-file-control:hover::before, .btn.btn-raised.btn-primary.active:focus, .btn-raised.btn-primary.active.custom-file-control:focus::before, .btn.btn-raised.btn-primary.active.focus, .btn-raised.btn-primary.active.focus.custom-file-control::before,
      .open > .btn.btn-raised.btn-primary.dropdown-toggle:hover,
      .open > .btn-raised.btn-primary.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-raised.btn-primary.dropdown-toggle:focus,
      .open > .btn-raised.btn-primary.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-raised.btn-primary.dropdown-toggle.focus,
      .open > .btn-raised.btn-primary.dropdown-toggle.focus.custom-file-control::before,
      .btn-group-raised .btn.btn-primary:active:hover,
      .btn-group-raised .btn-primary.custom-file-control:active:hover::before,
      .btn-group-raised .btn.btn-primary:active:focus,
      .btn-group-raised .btn-primary.custom-file-control:active:focus::before,
      .btn-group-raised .btn.btn-primary:active.focus,
      .btn-group-raised .btn-primary.custom-file-control:active.focus::before,
      .btn-group-raised .btn.btn-primary.active:hover,
      .btn-group-raised .btn-primary.active.custom-file-control:hover::before,
      .btn-group-raised .btn.btn-primary.active:focus,
      .btn-group-raised .btn-primary.active.custom-file-control:focus::before,
      .btn-group-raised .btn.btn-primary.active.focus,
      .btn-group-raised .btn-primary.active.focus.custom-file-control::before,
      .open > .btn-group-raised .btn.btn-primary.dropdown-toggle:hover,
      .open > .btn-group-raised .btn-primary.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn-group-raised .btn.btn-primary.dropdown-toggle:focus,
      .open > .btn-group-raised .btn-primary.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn-group-raised .btn.btn-primary.dropdown-toggle.focus,
      .open > .btn-group-raised .btn-primary.dropdown-toggle.focus.custom-file-control::before {
        color: #fff;
        background-color: #008cd4;
        border-color: #002b41; }
    .open > .btn.bmd-btn-fab.btn-primary.dropdown-toggle.bmd-btn-icon, .open > .bmd-btn-fab.btn-primary.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn.btn-raised.btn-primary.dropdown-toggle.bmd-btn-icon, .open > .btn-raised.btn-primary.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn-group-raised .btn.btn-primary.dropdown-toggle.bmd-btn-icon, .open > .btn-group-raised .btn-primary.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: #007fc0; }
      .open > .btn.bmd-btn-fab.btn-primary.dropdown-toggle.bmd-btn-icon:hover, .open > .bmd-btn-fab.btn-primary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn.btn-raised.btn-primary.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-raised.btn-primary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn-group-raised .btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-group-raised .btn-primary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: #008cd4; }
    .btn.bmd-btn-fab.btn-primary.disabled:focus, .bmd-btn-fab.btn-primary.disabled.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-primary.disabled.focus, .bmd-btn-fab.btn-primary.disabled.focus.custom-file-control::before, .btn.bmd-btn-fab.btn-primary:disabled:focus, .bmd-btn-fab.btn-primary.custom-file-control:disabled:focus::before, .btn.bmd-btn-fab.btn-primary:disabled.focus, .bmd-btn-fab.btn-primary.custom-file-control:disabled.focus::before, .btn.btn-raised.btn-primary.disabled:focus, .btn-raised.btn-primary.disabled.custom-file-control:focus::before, .btn.btn-raised.btn-primary.disabled.focus, .btn-raised.btn-primary.disabled.focus.custom-file-control::before, .btn.btn-raised.btn-primary:disabled:focus, .btn-raised.btn-primary.custom-file-control:disabled:focus::before, .btn.btn-raised.btn-primary:disabled.focus, .btn-raised.btn-primary.custom-file-control:disabled.focus::before,
    .btn-group-raised .btn.btn-primary.disabled:focus,
    .btn-group-raised .btn-primary.disabled.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-primary.disabled.focus,
    .btn-group-raised .btn-primary.disabled.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-primary:disabled:focus,
    .btn-group-raised .btn-primary.custom-file-control:disabled:focus::before,
    .btn-group-raised .btn.btn-primary:disabled.focus,
    .btn-group-raised .btn-primary.custom-file-control:disabled.focus::before {
      background-color: #007fc0;
      border-color: #007fc0; }
    .btn.bmd-btn-fab.btn-primary.disabled:hover, .bmd-btn-fab.btn-primary.disabled.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-primary:disabled:hover, .bmd-btn-fab.btn-primary.custom-file-control:disabled:hover::before, .btn.btn-raised.btn-primary.disabled:hover, .btn-raised.btn-primary.disabled.custom-file-control:hover::before, .btn.btn-raised.btn-primary:disabled:hover, .btn-raised.btn-primary.custom-file-control:disabled:hover::before,
    .btn-group-raised .btn.btn-primary.disabled:hover,
    .btn-group-raised .btn-primary.disabled.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-primary:disabled:hover,
    .btn-group-raised .btn-primary.custom-file-control:disabled:hover::before {
      background-color: #007fc0;
      border-color: #007fc0; }
  .btn.bmd-btn-fab.btn-secondary, .bmd-btn-fab.btn-secondary.custom-file-control::before, .btn.btn-raised.btn-secondary, .btn-raised.btn-secondary.custom-file-control::before,
  .btn-group-raised .btn.btn-secondary,
  .btn-group-raised .btn-secondary.custom-file-control::before {
    color: #fff;
    background-color: #00befc;
    border-color: #00befc; }
    .btn.bmd-btn-fab.btn-secondary:hover, .bmd-btn-fab.btn-secondary.custom-file-control:hover::before, .btn.btn-raised.btn-secondary:hover, .btn-raised.btn-secondary.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-secondary:hover,
    .btn-group-raised .btn-secondary.custom-file-control:hover::before {
      color: #fff;
      background-color: #00afe8;
      border-color: #0090bf; }
    .btn.bmd-btn-fab.btn-secondary:focus, .bmd-btn-fab.btn-secondary.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-secondary.focus, .bmd-btn-fab.btn-secondary.focus.custom-file-control::before, .btn.btn-raised.btn-secondary:focus, .btn-raised.btn-secondary.custom-file-control:focus::before, .btn.btn-raised.btn-secondary.focus, .btn-raised.btn-secondary.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-secondary:focus,
    .btn-group-raised .btn-secondary.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-secondary.focus,
    .btn-group-raised .btn-secondary.focus.custom-file-control::before {
      color: #fff;
      background-color: #00afe8;
      border-color: #0090bf; }
    .btn.bmd-btn-fab.btn-secondary:active, .bmd-btn-fab.btn-secondary.custom-file-control:active::before, .btn.bmd-btn-fab.btn-secondary.active, .bmd-btn-fab.btn-secondary.active.custom-file-control::before,
    .open > .btn.bmd-btn-fab.btn-secondary.dropdown-toggle,
    .open > .bmd-btn-fab.btn-secondary.dropdown-toggle.custom-file-control::before, .btn.btn-raised.btn-secondary:active, .btn-raised.btn-secondary.custom-file-control:active::before, .btn.btn-raised.btn-secondary.active, .btn-raised.btn-secondary.active.custom-file-control::before,
    .open > .btn.btn-raised.btn-secondary.dropdown-toggle,
    .open > .btn-raised.btn-secondary.dropdown-toggle.custom-file-control::before,
    .btn-group-raised .btn.btn-secondary:active,
    .btn-group-raised .btn-secondary.custom-file-control:active::before,
    .btn-group-raised .btn.btn-secondary.active,
    .btn-group-raised .btn-secondary.active.custom-file-control::before,
    .open > .btn-group-raised .btn.btn-secondary.dropdown-toggle,
    .open > .btn-group-raised .btn-secondary.dropdown-toggle.custom-file-control::before {
      color: #fff;
      background-color: #00afe8;
      border-color: #0090bf; }
      .btn.bmd-btn-fab.btn-secondary:active:hover, .bmd-btn-fab.btn-secondary.custom-file-control:active:hover::before, .btn.bmd-btn-fab.btn-secondary:active:focus, .bmd-btn-fab.btn-secondary.custom-file-control:active:focus::before, .btn.bmd-btn-fab.btn-secondary:active.focus, .bmd-btn-fab.btn-secondary.custom-file-control:active.focus::before, .btn.bmd-btn-fab.btn-secondary.active:hover, .bmd-btn-fab.btn-secondary.active.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-secondary.active:focus, .bmd-btn-fab.btn-secondary.active.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-secondary.active.focus, .bmd-btn-fab.btn-secondary.active.focus.custom-file-control::before,
      .open > .btn.bmd-btn-fab.btn-secondary.dropdown-toggle:hover,
      .open > .bmd-btn-fab.btn-secondary.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.bmd-btn-fab.btn-secondary.dropdown-toggle:focus,
      .open > .bmd-btn-fab.btn-secondary.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.bmd-btn-fab.btn-secondary.dropdown-toggle.focus,
      .open > .bmd-btn-fab.btn-secondary.dropdown-toggle.focus.custom-file-control::before, .btn.btn-raised.btn-secondary:active:hover, .btn-raised.btn-secondary.custom-file-control:active:hover::before, .btn.btn-raised.btn-secondary:active:focus, .btn-raised.btn-secondary.custom-file-control:active:focus::before, .btn.btn-raised.btn-secondary:active.focus, .btn-raised.btn-secondary.custom-file-control:active.focus::before, .btn.btn-raised.btn-secondary.active:hover, .btn-raised.btn-secondary.active.custom-file-control:hover::before, .btn.btn-raised.btn-secondary.active:focus, .btn-raised.btn-secondary.active.custom-file-control:focus::before, .btn.btn-raised.btn-secondary.active.focus, .btn-raised.btn-secondary.active.focus.custom-file-control::before,
      .open > .btn.btn-raised.btn-secondary.dropdown-toggle:hover,
      .open > .btn-raised.btn-secondary.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-raised.btn-secondary.dropdown-toggle:focus,
      .open > .btn-raised.btn-secondary.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-raised.btn-secondary.dropdown-toggle.focus,
      .open > .btn-raised.btn-secondary.dropdown-toggle.focus.custom-file-control::before,
      .btn-group-raised .btn.btn-secondary:active:hover,
      .btn-group-raised .btn-secondary.custom-file-control:active:hover::before,
      .btn-group-raised .btn.btn-secondary:active:focus,
      .btn-group-raised .btn-secondary.custom-file-control:active:focus::before,
      .btn-group-raised .btn.btn-secondary:active.focus,
      .btn-group-raised .btn-secondary.custom-file-control:active.focus::before,
      .btn-group-raised .btn.btn-secondary.active:hover,
      .btn-group-raised .btn-secondary.active.custom-file-control:hover::before,
      .btn-group-raised .btn.btn-secondary.active:focus,
      .btn-group-raised .btn-secondary.active.custom-file-control:focus::before,
      .btn-group-raised .btn.btn-secondary.active.focus,
      .btn-group-raised .btn-secondary.active.focus.custom-file-control::before,
      .open > .btn-group-raised .btn.btn-secondary.dropdown-toggle:hover,
      .open > .btn-group-raised .btn-secondary.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn-group-raised .btn.btn-secondary.dropdown-toggle:focus,
      .open > .btn-group-raised .btn-secondary.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn-group-raised .btn.btn-secondary.dropdown-toggle.focus,
      .open > .btn-group-raised .btn-secondary.dropdown-toggle.focus.custom-file-control::before {
        color: #fff;
        background-color: #00afe8;
        border-color: #005e7d; }
    .open > .btn.bmd-btn-fab.btn-secondary.dropdown-toggle.bmd-btn-icon, .open > .bmd-btn-fab.btn-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn.btn-raised.btn-secondary.dropdown-toggle.bmd-btn-icon, .open > .btn-raised.btn-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn-group-raised .btn.btn-secondary.dropdown-toggle.bmd-btn-icon, .open > .btn-group-raised .btn-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: #00befc; }
      .open > .btn.bmd-btn-fab.btn-secondary.dropdown-toggle.bmd-btn-icon:hover, .open > .bmd-btn-fab.btn-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn.btn-raised.btn-secondary.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-raised.btn-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn-group-raised .btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-group-raised .btn-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: #00afe8; }
    .btn.bmd-btn-fab.btn-secondary.disabled:focus, .bmd-btn-fab.btn-secondary.disabled.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-secondary.disabled.focus, .bmd-btn-fab.btn-secondary.disabled.focus.custom-file-control::before, .btn.bmd-btn-fab.btn-secondary:disabled:focus, .bmd-btn-fab.btn-secondary.custom-file-control:disabled:focus::before, .btn.bmd-btn-fab.btn-secondary:disabled.focus, .bmd-btn-fab.btn-secondary.custom-file-control:disabled.focus::before, .btn.btn-raised.btn-secondary.disabled:focus, .btn-raised.btn-secondary.disabled.custom-file-control:focus::before, .btn.btn-raised.btn-secondary.disabled.focus, .btn-raised.btn-secondary.disabled.focus.custom-file-control::before, .btn.btn-raised.btn-secondary:disabled:focus, .btn-raised.btn-secondary.custom-file-control:disabled:focus::before, .btn.btn-raised.btn-secondary:disabled.focus, .btn-raised.btn-secondary.custom-file-control:disabled.focus::before,
    .btn-group-raised .btn.btn-secondary.disabled:focus,
    .btn-group-raised .btn-secondary.disabled.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-secondary.disabled.focus,
    .btn-group-raised .btn-secondary.disabled.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-secondary:disabled:focus,
    .btn-group-raised .btn-secondary.custom-file-control:disabled:focus::before,
    .btn-group-raised .btn.btn-secondary:disabled.focus,
    .btn-group-raised .btn-secondary.custom-file-control:disabled.focus::before {
      background-color: #00befc;
      border-color: #00befc; }
    .btn.bmd-btn-fab.btn-secondary.disabled:hover, .bmd-btn-fab.btn-secondary.disabled.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-secondary:disabled:hover, .bmd-btn-fab.btn-secondary.custom-file-control:disabled:hover::before, .btn.btn-raised.btn-secondary.disabled:hover, .btn-raised.btn-secondary.disabled.custom-file-control:hover::before, .btn.btn-raised.btn-secondary:disabled:hover, .btn-raised.btn-secondary.custom-file-control:disabled:hover::before,
    .btn-group-raised .btn.btn-secondary.disabled:hover,
    .btn-group-raised .btn-secondary.disabled.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-secondary:disabled:hover,
    .btn-group-raised .btn-secondary.custom-file-control:disabled:hover::before {
      background-color: #00befc;
      border-color: #00befc; }
  .btn.bmd-btn-fab.btn-success, .bmd-btn-fab.btn-success.custom-file-control::before, .btn.btn-raised.btn-success, .btn-raised.btn-success.custom-file-control::before,
  .btn-group-raised .btn.btn-success,
  .btn-group-raised .btn-success.custom-file-control::before {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn.bmd-btn-fab.btn-success:hover, .bmd-btn-fab.btn-success.custom-file-control:hover::before, .btn.btn-raised.btn-success:hover, .btn-raised.btn-success.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-success:hover,
    .btn-group-raised .btn-success.custom-file-control:hover::before {
      color: #fff;
      background-color: #24973e;
      border-color: #1c7631; }
    .btn.bmd-btn-fab.btn-success:focus, .bmd-btn-fab.btn-success.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-success.focus, .bmd-btn-fab.btn-success.focus.custom-file-control::before, .btn.btn-raised.btn-success:focus, .btn-raised.btn-success.custom-file-control:focus::before, .btn.btn-raised.btn-success.focus, .btn-raised.btn-success.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-success:focus,
    .btn-group-raised .btn-success.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-success.focus,
    .btn-group-raised .btn-success.focus.custom-file-control::before {
      color: #fff;
      background-color: #24973e;
      border-color: #1c7631; }
    .btn.bmd-btn-fab.btn-success:active, .bmd-btn-fab.btn-success.custom-file-control:active::before, .btn.bmd-btn-fab.btn-success.active, .bmd-btn-fab.btn-success.active.custom-file-control::before,
    .open > .btn.bmd-btn-fab.btn-success.dropdown-toggle,
    .open > .bmd-btn-fab.btn-success.dropdown-toggle.custom-file-control::before, .btn.btn-raised.btn-success:active, .btn-raised.btn-success.custom-file-control:active::before, .btn.btn-raised.btn-success.active, .btn-raised.btn-success.active.custom-file-control::before,
    .open > .btn.btn-raised.btn-success.dropdown-toggle,
    .open > .btn-raised.btn-success.dropdown-toggle.custom-file-control::before,
    .btn-group-raised .btn.btn-success:active,
    .btn-group-raised .btn-success.custom-file-control:active::before,
    .btn-group-raised .btn.btn-success.active,
    .btn-group-raised .btn-success.active.custom-file-control::before,
    .open > .btn-group-raised .btn.btn-success.dropdown-toggle,
    .open > .btn-group-raised .btn-success.dropdown-toggle.custom-file-control::before {
      color: #fff;
      background-color: #24973e;
      border-color: #1c7631; }
      .btn.bmd-btn-fab.btn-success:active:hover, .bmd-btn-fab.btn-success.custom-file-control:active:hover::before, .btn.bmd-btn-fab.btn-success:active:focus, .bmd-btn-fab.btn-success.custom-file-control:active:focus::before, .btn.bmd-btn-fab.btn-success:active.focus, .bmd-btn-fab.btn-success.custom-file-control:active.focus::before, .btn.bmd-btn-fab.btn-success.active:hover, .bmd-btn-fab.btn-success.active.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-success.active:focus, .bmd-btn-fab.btn-success.active.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-success.active.focus, .bmd-btn-fab.btn-success.active.focus.custom-file-control::before,
      .open > .btn.bmd-btn-fab.btn-success.dropdown-toggle:hover,
      .open > .bmd-btn-fab.btn-success.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.bmd-btn-fab.btn-success.dropdown-toggle:focus,
      .open > .bmd-btn-fab.btn-success.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.bmd-btn-fab.btn-success.dropdown-toggle.focus,
      .open > .bmd-btn-fab.btn-success.dropdown-toggle.focus.custom-file-control::before, .btn.btn-raised.btn-success:active:hover, .btn-raised.btn-success.custom-file-control:active:hover::before, .btn.btn-raised.btn-success:active:focus, .btn-raised.btn-success.custom-file-control:active:focus::before, .btn.btn-raised.btn-success:active.focus, .btn-raised.btn-success.custom-file-control:active.focus::before, .btn.btn-raised.btn-success.active:hover, .btn-raised.btn-success.active.custom-file-control:hover::before, .btn.btn-raised.btn-success.active:focus, .btn-raised.btn-success.active.custom-file-control:focus::before, .btn.btn-raised.btn-success.active.focus, .btn-raised.btn-success.active.focus.custom-file-control::before,
      .open > .btn.btn-raised.btn-success.dropdown-toggle:hover,
      .open > .btn-raised.btn-success.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-raised.btn-success.dropdown-toggle:focus,
      .open > .btn-raised.btn-success.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-raised.btn-success.dropdown-toggle.focus,
      .open > .btn-raised.btn-success.dropdown-toggle.focus.custom-file-control::before,
      .btn-group-raised .btn.btn-success:active:hover,
      .btn-group-raised .btn-success.custom-file-control:active:hover::before,
      .btn-group-raised .btn.btn-success:active:focus,
      .btn-group-raised .btn-success.custom-file-control:active:focus::before,
      .btn-group-raised .btn.btn-success:active.focus,
      .btn-group-raised .btn-success.custom-file-control:active.focus::before,
      .btn-group-raised .btn.btn-success.active:hover,
      .btn-group-raised .btn-success.active.custom-file-control:hover::before,
      .btn-group-raised .btn.btn-success.active:focus,
      .btn-group-raised .btn-success.active.custom-file-control:focus::before,
      .btn-group-raised .btn.btn-success.active.focus,
      .btn-group-raised .btn-success.active.focus.custom-file-control::before,
      .open > .btn-group-raised .btn.btn-success.dropdown-toggle:hover,
      .open > .btn-group-raised .btn-success.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn-group-raised .btn.btn-success.dropdown-toggle:focus,
      .open > .btn-group-raised .btn-success.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn-group-raised .btn.btn-success.dropdown-toggle.focus,
      .open > .btn-group-raised .btn-success.dropdown-toggle.focus.custom-file-control::before {
        color: #fff;
        background-color: #24973e;
        border-color: #0f401b; }
    .open > .btn.bmd-btn-fab.btn-success.dropdown-toggle.bmd-btn-icon, .open > .bmd-btn-fab.btn-success.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn.btn-raised.btn-success.dropdown-toggle.bmd-btn-icon, .open > .btn-raised.btn-success.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn-group-raised .btn.btn-success.dropdown-toggle.bmd-btn-icon, .open > .btn-group-raised .btn-success.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: #28a745; }
      .open > .btn.bmd-btn-fab.btn-success.dropdown-toggle.bmd-btn-icon:hover, .open > .bmd-btn-fab.btn-success.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn.btn-raised.btn-success.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-raised.btn-success.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn-group-raised .btn.btn-success.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-group-raised .btn-success.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: #24973e; }
    .btn.bmd-btn-fab.btn-success.disabled:focus, .bmd-btn-fab.btn-success.disabled.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-success.disabled.focus, .bmd-btn-fab.btn-success.disabled.focus.custom-file-control::before, .btn.bmd-btn-fab.btn-success:disabled:focus, .bmd-btn-fab.btn-success.custom-file-control:disabled:focus::before, .btn.bmd-btn-fab.btn-success:disabled.focus, .bmd-btn-fab.btn-success.custom-file-control:disabled.focus::before, .btn.btn-raised.btn-success.disabled:focus, .btn-raised.btn-success.disabled.custom-file-control:focus::before, .btn.btn-raised.btn-success.disabled.focus, .btn-raised.btn-success.disabled.focus.custom-file-control::before, .btn.btn-raised.btn-success:disabled:focus, .btn-raised.btn-success.custom-file-control:disabled:focus::before, .btn.btn-raised.btn-success:disabled.focus, .btn-raised.btn-success.custom-file-control:disabled.focus::before,
    .btn-group-raised .btn.btn-success.disabled:focus,
    .btn-group-raised .btn-success.disabled.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-success.disabled.focus,
    .btn-group-raised .btn-success.disabled.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-success:disabled:focus,
    .btn-group-raised .btn-success.custom-file-control:disabled:focus::before,
    .btn-group-raised .btn.btn-success:disabled.focus,
    .btn-group-raised .btn-success.custom-file-control:disabled.focus::before {
      background-color: #28a745;
      border-color: #28a745; }
    .btn.bmd-btn-fab.btn-success.disabled:hover, .bmd-btn-fab.btn-success.disabled.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-success:disabled:hover, .bmd-btn-fab.btn-success.custom-file-control:disabled:hover::before, .btn.btn-raised.btn-success.disabled:hover, .btn-raised.btn-success.disabled.custom-file-control:hover::before, .btn.btn-raised.btn-success:disabled:hover, .btn-raised.btn-success.custom-file-control:disabled:hover::before,
    .btn-group-raised .btn.btn-success.disabled:hover,
    .btn-group-raised .btn-success.disabled.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-success:disabled:hover,
    .btn-group-raised .btn-success.custom-file-control:disabled:hover::before {
      background-color: #28a745;
      border-color: #28a745; }
  .btn.bmd-btn-fab.btn-info, .bmd-btn-fab.btn-info.custom-file-control::before, .btn.btn-raised.btn-info, .btn-raised.btn-info.custom-file-control::before,
  .btn-group-raised .btn.btn-info,
  .btn-group-raised .btn-info.custom-file-control::before {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn.bmd-btn-fab.btn-info:hover, .bmd-btn-fab.btn-info.custom-file-control:hover::before, .btn.btn-raised.btn-info:hover, .btn-raised.btn-info.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-info:hover,
    .btn-group-raised .btn-info.custom-file-control:hover::before {
      color: #fff;
      background-color: #1592a6;
      border-color: #107282; }
    .btn.bmd-btn-fab.btn-info:focus, .bmd-btn-fab.btn-info.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-info.focus, .bmd-btn-fab.btn-info.focus.custom-file-control::before, .btn.btn-raised.btn-info:focus, .btn-raised.btn-info.custom-file-control:focus::before, .btn.btn-raised.btn-info.focus, .btn-raised.btn-info.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-info:focus,
    .btn-group-raised .btn-info.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-info.focus,
    .btn-group-raised .btn-info.focus.custom-file-control::before {
      color: #fff;
      background-color: #1592a6;
      border-color: #107282; }
    .btn.bmd-btn-fab.btn-info:active, .bmd-btn-fab.btn-info.custom-file-control:active::before, .btn.bmd-btn-fab.btn-info.active, .bmd-btn-fab.btn-info.active.custom-file-control::before,
    .open > .btn.bmd-btn-fab.btn-info.dropdown-toggle,
    .open > .bmd-btn-fab.btn-info.dropdown-toggle.custom-file-control::before, .btn.btn-raised.btn-info:active, .btn-raised.btn-info.custom-file-control:active::before, .btn.btn-raised.btn-info.active, .btn-raised.btn-info.active.custom-file-control::before,
    .open > .btn.btn-raised.btn-info.dropdown-toggle,
    .open > .btn-raised.btn-info.dropdown-toggle.custom-file-control::before,
    .btn-group-raised .btn.btn-info:active,
    .btn-group-raised .btn-info.custom-file-control:active::before,
    .btn-group-raised .btn.btn-info.active,
    .btn-group-raised .btn-info.active.custom-file-control::before,
    .open > .btn-group-raised .btn.btn-info.dropdown-toggle,
    .open > .btn-group-raised .btn-info.dropdown-toggle.custom-file-control::before {
      color: #fff;
      background-color: #1592a6;
      border-color: #107282; }
      .btn.bmd-btn-fab.btn-info:active:hover, .bmd-btn-fab.btn-info.custom-file-control:active:hover::before, .btn.bmd-btn-fab.btn-info:active:focus, .bmd-btn-fab.btn-info.custom-file-control:active:focus::before, .btn.bmd-btn-fab.btn-info:active.focus, .bmd-btn-fab.btn-info.custom-file-control:active.focus::before, .btn.bmd-btn-fab.btn-info.active:hover, .bmd-btn-fab.btn-info.active.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-info.active:focus, .bmd-btn-fab.btn-info.active.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-info.active.focus, .bmd-btn-fab.btn-info.active.focus.custom-file-control::before,
      .open > .btn.bmd-btn-fab.btn-info.dropdown-toggle:hover,
      .open > .bmd-btn-fab.btn-info.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.bmd-btn-fab.btn-info.dropdown-toggle:focus,
      .open > .bmd-btn-fab.btn-info.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.bmd-btn-fab.btn-info.dropdown-toggle.focus,
      .open > .bmd-btn-fab.btn-info.dropdown-toggle.focus.custom-file-control::before, .btn.btn-raised.btn-info:active:hover, .btn-raised.btn-info.custom-file-control:active:hover::before, .btn.btn-raised.btn-info:active:focus, .btn-raised.btn-info.custom-file-control:active:focus::before, .btn.btn-raised.btn-info:active.focus, .btn-raised.btn-info.custom-file-control:active.focus::before, .btn.btn-raised.btn-info.active:hover, .btn-raised.btn-info.active.custom-file-control:hover::before, .btn.btn-raised.btn-info.active:focus, .btn-raised.btn-info.active.custom-file-control:focus::before, .btn.btn-raised.btn-info.active.focus, .btn-raised.btn-info.active.focus.custom-file-control::before,
      .open > .btn.btn-raised.btn-info.dropdown-toggle:hover,
      .open > .btn-raised.btn-info.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-raised.btn-info.dropdown-toggle:focus,
      .open > .btn-raised.btn-info.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-raised.btn-info.dropdown-toggle.focus,
      .open > .btn-raised.btn-info.dropdown-toggle.focus.custom-file-control::before,
      .btn-group-raised .btn.btn-info:active:hover,
      .btn-group-raised .btn-info.custom-file-control:active:hover::before,
      .btn-group-raised .btn.btn-info:active:focus,
      .btn-group-raised .btn-info.custom-file-control:active:focus::before,
      .btn-group-raised .btn.btn-info:active.focus,
      .btn-group-raised .btn-info.custom-file-control:active.focus::before,
      .btn-group-raised .btn.btn-info.active:hover,
      .btn-group-raised .btn-info.active.custom-file-control:hover::before,
      .btn-group-raised .btn.btn-info.active:focus,
      .btn-group-raised .btn-info.active.custom-file-control:focus::before,
      .btn-group-raised .btn.btn-info.active.focus,
      .btn-group-raised .btn-info.active.focus.custom-file-control::before,
      .open > .btn-group-raised .btn.btn-info.dropdown-toggle:hover,
      .open > .btn-group-raised .btn-info.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn-group-raised .btn.btn-info.dropdown-toggle:focus,
      .open > .btn-group-raised .btn-info.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn-group-raised .btn.btn-info.dropdown-toggle.focus,
      .open > .btn-group-raised .btn-info.dropdown-toggle.focus.custom-file-control::before {
        color: #fff;
        background-color: #1592a6;
        border-color: #093e47; }
    .open > .btn.bmd-btn-fab.btn-info.dropdown-toggle.bmd-btn-icon, .open > .bmd-btn-fab.btn-info.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn.btn-raised.btn-info.dropdown-toggle.bmd-btn-icon, .open > .btn-raised.btn-info.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn-group-raised .btn.btn-info.dropdown-toggle.bmd-btn-icon, .open > .btn-group-raised .btn-info.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: #17a2b8; }
      .open > .btn.bmd-btn-fab.btn-info.dropdown-toggle.bmd-btn-icon:hover, .open > .bmd-btn-fab.btn-info.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn.btn-raised.btn-info.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-raised.btn-info.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn-group-raised .btn.btn-info.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-group-raised .btn-info.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: #1592a6; }
    .btn.bmd-btn-fab.btn-info.disabled:focus, .bmd-btn-fab.btn-info.disabled.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-info.disabled.focus, .bmd-btn-fab.btn-info.disabled.focus.custom-file-control::before, .btn.bmd-btn-fab.btn-info:disabled:focus, .bmd-btn-fab.btn-info.custom-file-control:disabled:focus::before, .btn.bmd-btn-fab.btn-info:disabled.focus, .bmd-btn-fab.btn-info.custom-file-control:disabled.focus::before, .btn.btn-raised.btn-info.disabled:focus, .btn-raised.btn-info.disabled.custom-file-control:focus::before, .btn.btn-raised.btn-info.disabled.focus, .btn-raised.btn-info.disabled.focus.custom-file-control::before, .btn.btn-raised.btn-info:disabled:focus, .btn-raised.btn-info.custom-file-control:disabled:focus::before, .btn.btn-raised.btn-info:disabled.focus, .btn-raised.btn-info.custom-file-control:disabled.focus::before,
    .btn-group-raised .btn.btn-info.disabled:focus,
    .btn-group-raised .btn-info.disabled.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-info.disabled.focus,
    .btn-group-raised .btn-info.disabled.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-info:disabled:focus,
    .btn-group-raised .btn-info.custom-file-control:disabled:focus::before,
    .btn-group-raised .btn.btn-info:disabled.focus,
    .btn-group-raised .btn-info.custom-file-control:disabled.focus::before {
      background-color: #17a2b8;
      border-color: #17a2b8; }
    .btn.bmd-btn-fab.btn-info.disabled:hover, .bmd-btn-fab.btn-info.disabled.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-info:disabled:hover, .bmd-btn-fab.btn-info.custom-file-control:disabled:hover::before, .btn.btn-raised.btn-info.disabled:hover, .btn-raised.btn-info.disabled.custom-file-control:hover::before, .btn.btn-raised.btn-info:disabled:hover, .btn-raised.btn-info.custom-file-control:disabled:hover::before,
    .btn-group-raised .btn.btn-info.disabled:hover,
    .btn-group-raised .btn-info.disabled.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-info:disabled:hover,
    .btn-group-raised .btn-info.custom-file-control:disabled:hover::before {
      background-color: #17a2b8;
      border-color: #17a2b8; }
  .btn.bmd-btn-fab.btn-warning, .bmd-btn-fab.btn-warning.custom-file-control::before, .btn.btn-raised.btn-warning, .btn-raised.btn-warning.custom-file-control::before,
  .btn-group-raised .btn.btn-warning,
  .btn-group-raised .btn-warning.custom-file-control::before {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn.bmd-btn-fab.btn-warning:hover, .bmd-btn-fab.btn-warning.custom-file-control:hover::before, .btn.btn-raised.btn-warning:hover, .btn-raised.btn-warning.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-warning:hover,
    .btn-group-raised .btn-warning.custom-file-control:hover::before {
      color: #fff;
      background-color: #f2b500;
      border-color: #c99700; }
    .btn.bmd-btn-fab.btn-warning:focus, .bmd-btn-fab.btn-warning.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-warning.focus, .bmd-btn-fab.btn-warning.focus.custom-file-control::before, .btn.btn-raised.btn-warning:focus, .btn-raised.btn-warning.custom-file-control:focus::before, .btn.btn-raised.btn-warning.focus, .btn-raised.btn-warning.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-warning:focus,
    .btn-group-raised .btn-warning.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-warning.focus,
    .btn-group-raised .btn-warning.focus.custom-file-control::before {
      color: #fff;
      background-color: #f2b500;
      border-color: #c99700; }
    .btn.bmd-btn-fab.btn-warning:active, .bmd-btn-fab.btn-warning.custom-file-control:active::before, .btn.bmd-btn-fab.btn-warning.active, .bmd-btn-fab.btn-warning.active.custom-file-control::before,
    .open > .btn.bmd-btn-fab.btn-warning.dropdown-toggle,
    .open > .bmd-btn-fab.btn-warning.dropdown-toggle.custom-file-control::before, .btn.btn-raised.btn-warning:active, .btn-raised.btn-warning.custom-file-control:active::before, .btn.btn-raised.btn-warning.active, .btn-raised.btn-warning.active.custom-file-control::before,
    .open > .btn.btn-raised.btn-warning.dropdown-toggle,
    .open > .btn-raised.btn-warning.dropdown-toggle.custom-file-control::before,
    .btn-group-raised .btn.btn-warning:active,
    .btn-group-raised .btn-warning.custom-file-control:active::before,
    .btn-group-raised .btn.btn-warning.active,
    .btn-group-raised .btn-warning.active.custom-file-control::before,
    .open > .btn-group-raised .btn.btn-warning.dropdown-toggle,
    .open > .btn-group-raised .btn-warning.dropdown-toggle.custom-file-control::before {
      color: #fff;
      background-color: #f2b500;
      border-color: #c99700; }
      .btn.bmd-btn-fab.btn-warning:active:hover, .bmd-btn-fab.btn-warning.custom-file-control:active:hover::before, .btn.bmd-btn-fab.btn-warning:active:focus, .bmd-btn-fab.btn-warning.custom-file-control:active:focus::before, .btn.bmd-btn-fab.btn-warning:active.focus, .bmd-btn-fab.btn-warning.custom-file-control:active.focus::before, .btn.bmd-btn-fab.btn-warning.active:hover, .bmd-btn-fab.btn-warning.active.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-warning.active:focus, .bmd-btn-fab.btn-warning.active.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-warning.active.focus, .bmd-btn-fab.btn-warning.active.focus.custom-file-control::before,
      .open > .btn.bmd-btn-fab.btn-warning.dropdown-toggle:hover,
      .open > .bmd-btn-fab.btn-warning.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.bmd-btn-fab.btn-warning.dropdown-toggle:focus,
      .open > .bmd-btn-fab.btn-warning.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.bmd-btn-fab.btn-warning.dropdown-toggle.focus,
      .open > .bmd-btn-fab.btn-warning.dropdown-toggle.focus.custom-file-control::before, .btn.btn-raised.btn-warning:active:hover, .btn-raised.btn-warning.custom-file-control:active:hover::before, .btn.btn-raised.btn-warning:active:focus, .btn-raised.btn-warning.custom-file-control:active:focus::before, .btn.btn-raised.btn-warning:active.focus, .btn-raised.btn-warning.custom-file-control:active.focus::before, .btn.btn-raised.btn-warning.active:hover, .btn-raised.btn-warning.active.custom-file-control:hover::before, .btn.btn-raised.btn-warning.active:focus, .btn-raised.btn-warning.active.custom-file-control:focus::before, .btn.btn-raised.btn-warning.active.focus, .btn-raised.btn-warning.active.focus.custom-file-control::before,
      .open > .btn.btn-raised.btn-warning.dropdown-toggle:hover,
      .open > .btn-raised.btn-warning.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-raised.btn-warning.dropdown-toggle:focus,
      .open > .btn-raised.btn-warning.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-raised.btn-warning.dropdown-toggle.focus,
      .open > .btn-raised.btn-warning.dropdown-toggle.focus.custom-file-control::before,
      .btn-group-raised .btn.btn-warning:active:hover,
      .btn-group-raised .btn-warning.custom-file-control:active:hover::before,
      .btn-group-raised .btn.btn-warning:active:focus,
      .btn-group-raised .btn-warning.custom-file-control:active:focus::before,
      .btn-group-raised .btn.btn-warning:active.focus,
      .btn-group-raised .btn-warning.custom-file-control:active.focus::before,
      .btn-group-raised .btn.btn-warning.active:hover,
      .btn-group-raised .btn-warning.active.custom-file-control:hover::before,
      .btn-group-raised .btn.btn-warning.active:focus,
      .btn-group-raised .btn-warning.active.custom-file-control:focus::before,
      .btn-group-raised .btn.btn-warning.active.focus,
      .btn-group-raised .btn-warning.active.focus.custom-file-control::before,
      .open > .btn-group-raised .btn.btn-warning.dropdown-toggle:hover,
      .open > .btn-group-raised .btn-warning.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn-group-raised .btn.btn-warning.dropdown-toggle:focus,
      .open > .btn-group-raised .btn-warning.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn-group-raised .btn.btn-warning.dropdown-toggle.focus,
      .open > .btn-group-raised .btn-warning.dropdown-toggle.focus.custom-file-control::before {
        color: #fff;
        background-color: #f2b500;
        border-color: #876500; }
    .open > .btn.bmd-btn-fab.btn-warning.dropdown-toggle.bmd-btn-icon, .open > .bmd-btn-fab.btn-warning.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn.btn-raised.btn-warning.dropdown-toggle.bmd-btn-icon, .open > .btn-raised.btn-warning.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn-group-raised .btn.btn-warning.dropdown-toggle.bmd-btn-icon, .open > .btn-group-raised .btn-warning.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: #ffc107; }
      .open > .btn.bmd-btn-fab.btn-warning.dropdown-toggle.bmd-btn-icon:hover, .open > .bmd-btn-fab.btn-warning.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn.btn-raised.btn-warning.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-raised.btn-warning.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn-group-raised .btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-group-raised .btn-warning.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: #f2b500; }
    .btn.bmd-btn-fab.btn-warning.disabled:focus, .bmd-btn-fab.btn-warning.disabled.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-warning.disabled.focus, .bmd-btn-fab.btn-warning.disabled.focus.custom-file-control::before, .btn.bmd-btn-fab.btn-warning:disabled:focus, .bmd-btn-fab.btn-warning.custom-file-control:disabled:focus::before, .btn.bmd-btn-fab.btn-warning:disabled.focus, .bmd-btn-fab.btn-warning.custom-file-control:disabled.focus::before, .btn.btn-raised.btn-warning.disabled:focus, .btn-raised.btn-warning.disabled.custom-file-control:focus::before, .btn.btn-raised.btn-warning.disabled.focus, .btn-raised.btn-warning.disabled.focus.custom-file-control::before, .btn.btn-raised.btn-warning:disabled:focus, .btn-raised.btn-warning.custom-file-control:disabled:focus::before, .btn.btn-raised.btn-warning:disabled.focus, .btn-raised.btn-warning.custom-file-control:disabled.focus::before,
    .btn-group-raised .btn.btn-warning.disabled:focus,
    .btn-group-raised .btn-warning.disabled.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-warning.disabled.focus,
    .btn-group-raised .btn-warning.disabled.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-warning:disabled:focus,
    .btn-group-raised .btn-warning.custom-file-control:disabled:focus::before,
    .btn-group-raised .btn.btn-warning:disabled.focus,
    .btn-group-raised .btn-warning.custom-file-control:disabled.focus::before {
      background-color: #ffc107;
      border-color: #ffc107; }
    .btn.bmd-btn-fab.btn-warning.disabled:hover, .bmd-btn-fab.btn-warning.disabled.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-warning:disabled:hover, .bmd-btn-fab.btn-warning.custom-file-control:disabled:hover::before, .btn.btn-raised.btn-warning.disabled:hover, .btn-raised.btn-warning.disabled.custom-file-control:hover::before, .btn.btn-raised.btn-warning:disabled:hover, .btn-raised.btn-warning.custom-file-control:disabled:hover::before,
    .btn-group-raised .btn.btn-warning.disabled:hover,
    .btn-group-raised .btn-warning.disabled.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-warning:disabled:hover,
    .btn-group-raised .btn-warning.custom-file-control:disabled:hover::before {
      background-color: #ffc107;
      border-color: #ffc107; }
  .btn.bmd-btn-fab.btn-danger, .bmd-btn-fab.btn-danger.custom-file-control::before, .btn.btn-raised.btn-danger, .btn-raised.btn-danger.custom-file-control::before,
  .btn-group-raised .btn.btn-danger,
  .btn-group-raised .btn-danger.custom-file-control::before {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn.bmd-btn-fab.btn-danger:hover, .bmd-btn-fab.btn-danger.custom-file-control:hover::before, .btn.btn-raised.btn-danger:hover, .btn-raised.btn-danger.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-danger:hover,
    .btn-group-raised .btn-danger.custom-file-control:hover::before {
      color: #fff;
      background-color: #df4655;
      border-color: #b51f2e; }
    .btn.bmd-btn-fab.btn-danger:focus, .bmd-btn-fab.btn-danger.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-danger.focus, .bmd-btn-fab.btn-danger.focus.custom-file-control::before, .btn.btn-raised.btn-danger:focus, .btn-raised.btn-danger.custom-file-control:focus::before, .btn.btn-raised.btn-danger.focus, .btn-raised.btn-danger.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-danger:focus,
    .btn-group-raised .btn-danger.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-danger.focus,
    .btn-group-raised .btn-danger.focus.custom-file-control::before {
      color: #fff;
      background-color: #df4655;
      border-color: #b51f2e; }
    .btn.bmd-btn-fab.btn-danger:active, .bmd-btn-fab.btn-danger.custom-file-control:active::before, .btn.bmd-btn-fab.btn-danger.active, .bmd-btn-fab.btn-danger.active.custom-file-control::before,
    .open > .btn.bmd-btn-fab.btn-danger.dropdown-toggle,
    .open > .bmd-btn-fab.btn-danger.dropdown-toggle.custom-file-control::before, .btn.btn-raised.btn-danger:active, .btn-raised.btn-danger.custom-file-control:active::before, .btn.btn-raised.btn-danger.active, .btn-raised.btn-danger.active.custom-file-control::before,
    .open > .btn.btn-raised.btn-danger.dropdown-toggle,
    .open > .btn-raised.btn-danger.dropdown-toggle.custom-file-control::before,
    .btn-group-raised .btn.btn-danger:active,
    .btn-group-raised .btn-danger.custom-file-control:active::before,
    .btn-group-raised .btn.btn-danger.active,
    .btn-group-raised .btn-danger.active.custom-file-control::before,
    .open > .btn-group-raised .btn.btn-danger.dropdown-toggle,
    .open > .btn-group-raised .btn-danger.dropdown-toggle.custom-file-control::before {
      color: #fff;
      background-color: #df4655;
      border-color: #b51f2e; }
      .btn.bmd-btn-fab.btn-danger:active:hover, .bmd-btn-fab.btn-danger.custom-file-control:active:hover::before, .btn.bmd-btn-fab.btn-danger:active:focus, .bmd-btn-fab.btn-danger.custom-file-control:active:focus::before, .btn.bmd-btn-fab.btn-danger:active.focus, .bmd-btn-fab.btn-danger.custom-file-control:active.focus::before, .btn.bmd-btn-fab.btn-danger.active:hover, .bmd-btn-fab.btn-danger.active.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-danger.active:focus, .bmd-btn-fab.btn-danger.active.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-danger.active.focus, .bmd-btn-fab.btn-danger.active.focus.custom-file-control::before,
      .open > .btn.bmd-btn-fab.btn-danger.dropdown-toggle:hover,
      .open > .bmd-btn-fab.btn-danger.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.bmd-btn-fab.btn-danger.dropdown-toggle:focus,
      .open > .bmd-btn-fab.btn-danger.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.bmd-btn-fab.btn-danger.dropdown-toggle.focus,
      .open > .bmd-btn-fab.btn-danger.dropdown-toggle.focus.custom-file-control::before, .btn.btn-raised.btn-danger:active:hover, .btn-raised.btn-danger.custom-file-control:active:hover::before, .btn.btn-raised.btn-danger:active:focus, .btn-raised.btn-danger.custom-file-control:active:focus::before, .btn.btn-raised.btn-danger:active.focus, .btn-raised.btn-danger.custom-file-control:active.focus::before, .btn.btn-raised.btn-danger.active:hover, .btn-raised.btn-danger.active.custom-file-control:hover::before, .btn.btn-raised.btn-danger.active:focus, .btn-raised.btn-danger.active.custom-file-control:focus::before, .btn.btn-raised.btn-danger.active.focus, .btn-raised.btn-danger.active.focus.custom-file-control::before,
      .open > .btn.btn-raised.btn-danger.dropdown-toggle:hover,
      .open > .btn-raised.btn-danger.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-raised.btn-danger.dropdown-toggle:focus,
      .open > .btn-raised.btn-danger.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-raised.btn-danger.dropdown-toggle.focus,
      .open > .btn-raised.btn-danger.dropdown-toggle.focus.custom-file-control::before,
      .btn-group-raised .btn.btn-danger:active:hover,
      .btn-group-raised .btn-danger.custom-file-control:active:hover::before,
      .btn-group-raised .btn.btn-danger:active:focus,
      .btn-group-raised .btn-danger.custom-file-control:active:focus::before,
      .btn-group-raised .btn.btn-danger:active.focus,
      .btn-group-raised .btn-danger.custom-file-control:active.focus::before,
      .btn-group-raised .btn.btn-danger.active:hover,
      .btn-group-raised .btn-danger.active.custom-file-control:hover::before,
      .btn-group-raised .btn.btn-danger.active:focus,
      .btn-group-raised .btn-danger.active.custom-file-control:focus::before,
      .btn-group-raised .btn.btn-danger.active.focus,
      .btn-group-raised .btn-danger.active.focus.custom-file-control::before,
      .open > .btn-group-raised .btn.btn-danger.dropdown-toggle:hover,
      .open > .btn-group-raised .btn-danger.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn-group-raised .btn.btn-danger.dropdown-toggle:focus,
      .open > .btn-group-raised .btn-danger.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn-group-raised .btn.btn-danger.dropdown-toggle.focus,
      .open > .btn-group-raised .btn-danger.dropdown-toggle.focus.custom-file-control::before {
        color: #fff;
        background-color: #df4655;
        border-color: #7c151f; }
    .open > .btn.bmd-btn-fab.btn-danger.dropdown-toggle.bmd-btn-icon, .open > .bmd-btn-fab.btn-danger.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn.btn-raised.btn-danger.dropdown-toggle.bmd-btn-icon, .open > .btn-raised.btn-danger.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn-group-raised .btn.btn-danger.dropdown-toggle.bmd-btn-icon, .open > .btn-group-raised .btn-danger.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: #dc3545; }
      .open > .btn.bmd-btn-fab.btn-danger.dropdown-toggle.bmd-btn-icon:hover, .open > .bmd-btn-fab.btn-danger.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn.btn-raised.btn-danger.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-raised.btn-danger.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn-group-raised .btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-group-raised .btn-danger.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: #df4655; }
    .btn.bmd-btn-fab.btn-danger.disabled:focus, .bmd-btn-fab.btn-danger.disabled.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-danger.disabled.focus, .bmd-btn-fab.btn-danger.disabled.focus.custom-file-control::before, .btn.bmd-btn-fab.btn-danger:disabled:focus, .bmd-btn-fab.btn-danger.custom-file-control:disabled:focus::before, .btn.bmd-btn-fab.btn-danger:disabled.focus, .bmd-btn-fab.btn-danger.custom-file-control:disabled.focus::before, .btn.btn-raised.btn-danger.disabled:focus, .btn-raised.btn-danger.disabled.custom-file-control:focus::before, .btn.btn-raised.btn-danger.disabled.focus, .btn-raised.btn-danger.disabled.focus.custom-file-control::before, .btn.btn-raised.btn-danger:disabled:focus, .btn-raised.btn-danger.custom-file-control:disabled:focus::before, .btn.btn-raised.btn-danger:disabled.focus, .btn-raised.btn-danger.custom-file-control:disabled.focus::before,
    .btn-group-raised .btn.btn-danger.disabled:focus,
    .btn-group-raised .btn-danger.disabled.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-danger.disabled.focus,
    .btn-group-raised .btn-danger.disabled.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-danger:disabled:focus,
    .btn-group-raised .btn-danger.custom-file-control:disabled:focus::before,
    .btn-group-raised .btn.btn-danger:disabled.focus,
    .btn-group-raised .btn-danger.custom-file-control:disabled.focus::before {
      background-color: #dc3545;
      border-color: #dc3545; }
    .btn.bmd-btn-fab.btn-danger.disabled:hover, .bmd-btn-fab.btn-danger.disabled.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-danger:disabled:hover, .bmd-btn-fab.btn-danger.custom-file-control:disabled:hover::before, .btn.btn-raised.btn-danger.disabled:hover, .btn-raised.btn-danger.disabled.custom-file-control:hover::before, .btn.btn-raised.btn-danger:disabled:hover, .btn-raised.btn-danger.custom-file-control:disabled:hover::before,
    .btn-group-raised .btn.btn-danger.disabled:hover,
    .btn-group-raised .btn-danger.disabled.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-danger:disabled:hover,
    .btn-group-raised .btn-danger.custom-file-control:disabled:hover::before {
      background-color: #dc3545;
      border-color: #dc3545; }
  .btn.bmd-btn-fab.btn-light, .bmd-btn-fab.btn-light.custom-file-control::before, .btn.btn-raised.btn-light, .btn-raised.btn-light.custom-file-control::before,
  .btn-group-raised .btn.btn-light,
  .btn-group-raised .btn-light.custom-file-control::before {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn.bmd-btn-fab.btn-light:hover, .bmd-btn-fab.btn-light.custom-file-control:hover::before, .btn.btn-raised.btn-light:hover, .btn-raised.btn-light.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-light:hover,
    .btn-group-raised .btn-light.custom-file-control:hover::before {
      color: #fff;
      background-color: #eceff2;
      border-color: #d4dae1; }
    .btn.bmd-btn-fab.btn-light:focus, .bmd-btn-fab.btn-light.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-light.focus, .bmd-btn-fab.btn-light.focus.custom-file-control::before, .btn.btn-raised.btn-light:focus, .btn-raised.btn-light.custom-file-control:focus::before, .btn.btn-raised.btn-light.focus, .btn-raised.btn-light.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-light:focus,
    .btn-group-raised .btn-light.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-light.focus,
    .btn-group-raised .btn-light.focus.custom-file-control::before {
      color: #fff;
      background-color: #eceff2;
      border-color: #d4dae1; }
    .btn.bmd-btn-fab.btn-light:active, .bmd-btn-fab.btn-light.custom-file-control:active::before, .btn.bmd-btn-fab.btn-light.active, .bmd-btn-fab.btn-light.active.custom-file-control::before,
    .open > .btn.bmd-btn-fab.btn-light.dropdown-toggle,
    .open > .bmd-btn-fab.btn-light.dropdown-toggle.custom-file-control::before, .btn.btn-raised.btn-light:active, .btn-raised.btn-light.custom-file-control:active::before, .btn.btn-raised.btn-light.active, .btn-raised.btn-light.active.custom-file-control::before,
    .open > .btn.btn-raised.btn-light.dropdown-toggle,
    .open > .btn-raised.btn-light.dropdown-toggle.custom-file-control::before,
    .btn-group-raised .btn.btn-light:active,
    .btn-group-raised .btn-light.custom-file-control:active::before,
    .btn-group-raised .btn.btn-light.active,
    .btn-group-raised .btn-light.active.custom-file-control::before,
    .open > .btn-group-raised .btn.btn-light.dropdown-toggle,
    .open > .btn-group-raised .btn-light.dropdown-toggle.custom-file-control::before {
      color: #fff;
      background-color: #eceff2;
      border-color: #d4dae1; }
      .btn.bmd-btn-fab.btn-light:active:hover, .bmd-btn-fab.btn-light.custom-file-control:active:hover::before, .btn.bmd-btn-fab.btn-light:active:focus, .bmd-btn-fab.btn-light.custom-file-control:active:focus::before, .btn.bmd-btn-fab.btn-light:active.focus, .bmd-btn-fab.btn-light.custom-file-control:active.focus::before, .btn.bmd-btn-fab.btn-light.active:hover, .bmd-btn-fab.btn-light.active.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-light.active:focus, .bmd-btn-fab.btn-light.active.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-light.active.focus, .bmd-btn-fab.btn-light.active.focus.custom-file-control::before,
      .open > .btn.bmd-btn-fab.btn-light.dropdown-toggle:hover,
      .open > .bmd-btn-fab.btn-light.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.bmd-btn-fab.btn-light.dropdown-toggle:focus,
      .open > .bmd-btn-fab.btn-light.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.bmd-btn-fab.btn-light.dropdown-toggle.focus,
      .open > .bmd-btn-fab.btn-light.dropdown-toggle.focus.custom-file-control::before, .btn.btn-raised.btn-light:active:hover, .btn-raised.btn-light.custom-file-control:active:hover::before, .btn.btn-raised.btn-light:active:focus, .btn-raised.btn-light.custom-file-control:active:focus::before, .btn.btn-raised.btn-light:active.focus, .btn-raised.btn-light.custom-file-control:active.focus::before, .btn.btn-raised.btn-light.active:hover, .btn-raised.btn-light.active.custom-file-control:hover::before, .btn.btn-raised.btn-light.active:focus, .btn-raised.btn-light.active.custom-file-control:focus::before, .btn.btn-raised.btn-light.active.focus, .btn-raised.btn-light.active.focus.custom-file-control::before,
      .open > .btn.btn-raised.btn-light.dropdown-toggle:hover,
      .open > .btn-raised.btn-light.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-raised.btn-light.dropdown-toggle:focus,
      .open > .btn-raised.btn-light.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-raised.btn-light.dropdown-toggle.focus,
      .open > .btn-raised.btn-light.dropdown-toggle.focus.custom-file-control::before,
      .btn-group-raised .btn.btn-light:active:hover,
      .btn-group-raised .btn-light.custom-file-control:active:hover::before,
      .btn-group-raised .btn.btn-light:active:focus,
      .btn-group-raised .btn-light.custom-file-control:active:focus::before,
      .btn-group-raised .btn.btn-light:active.focus,
      .btn-group-raised .btn-light.custom-file-control:active.focus::before,
      .btn-group-raised .btn.btn-light.active:hover,
      .btn-group-raised .btn-light.active.custom-file-control:hover::before,
      .btn-group-raised .btn.btn-light.active:focus,
      .btn-group-raised .btn-light.active.custom-file-control:focus::before,
      .btn-group-raised .btn.btn-light.active.focus,
      .btn-group-raised .btn-light.active.focus.custom-file-control::before,
      .open > .btn-group-raised .btn.btn-light.dropdown-toggle:hover,
      .open > .btn-group-raised .btn-light.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn-group-raised .btn.btn-light.dropdown-toggle:focus,
      .open > .btn-group-raised .btn-light.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn-group-raised .btn.btn-light.dropdown-toggle.focus,
      .open > .btn-group-raised .btn-light.dropdown-toggle.focus.custom-file-control::before {
        color: #fff;
        background-color: #eceff2;
        border-color: #aeb9c5; }
    .open > .btn.bmd-btn-fab.btn-light.dropdown-toggle.bmd-btn-icon, .open > .bmd-btn-fab.btn-light.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn.btn-raised.btn-light.dropdown-toggle.bmd-btn-icon, .open > .btn-raised.btn-light.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn-group-raised .btn.btn-light.dropdown-toggle.bmd-btn-icon, .open > .btn-group-raised .btn-light.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: #f8f9fa; }
      .open > .btn.bmd-btn-fab.btn-light.dropdown-toggle.bmd-btn-icon:hover, .open > .bmd-btn-fab.btn-light.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn.btn-raised.btn-light.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-raised.btn-light.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn-group-raised .btn.btn-light.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-group-raised .btn-light.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: #eceff2; }
    .btn.bmd-btn-fab.btn-light.disabled:focus, .bmd-btn-fab.btn-light.disabled.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-light.disabled.focus, .bmd-btn-fab.btn-light.disabled.focus.custom-file-control::before, .btn.bmd-btn-fab.btn-light:disabled:focus, .bmd-btn-fab.btn-light.custom-file-control:disabled:focus::before, .btn.bmd-btn-fab.btn-light:disabled.focus, .bmd-btn-fab.btn-light.custom-file-control:disabled.focus::before, .btn.btn-raised.btn-light.disabled:focus, .btn-raised.btn-light.disabled.custom-file-control:focus::before, .btn.btn-raised.btn-light.disabled.focus, .btn-raised.btn-light.disabled.focus.custom-file-control::before, .btn.btn-raised.btn-light:disabled:focus, .btn-raised.btn-light.custom-file-control:disabled:focus::before, .btn.btn-raised.btn-light:disabled.focus, .btn-raised.btn-light.custom-file-control:disabled.focus::before,
    .btn-group-raised .btn.btn-light.disabled:focus,
    .btn-group-raised .btn-light.disabled.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-light.disabled.focus,
    .btn-group-raised .btn-light.disabled.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-light:disabled:focus,
    .btn-group-raised .btn-light.custom-file-control:disabled:focus::before,
    .btn-group-raised .btn.btn-light:disabled.focus,
    .btn-group-raised .btn-light.custom-file-control:disabled.focus::before {
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
    .btn.bmd-btn-fab.btn-light.disabled:hover, .bmd-btn-fab.btn-light.disabled.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-light:disabled:hover, .bmd-btn-fab.btn-light.custom-file-control:disabled:hover::before, .btn.btn-raised.btn-light.disabled:hover, .btn-raised.btn-light.disabled.custom-file-control:hover::before, .btn.btn-raised.btn-light:disabled:hover, .btn-raised.btn-light.custom-file-control:disabled:hover::before,
    .btn-group-raised .btn.btn-light.disabled:hover,
    .btn-group-raised .btn-light.disabled.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-light:disabled:hover,
    .btn-group-raised .btn-light.custom-file-control:disabled:hover::before {
      background-color: #f8f9fa;
      border-color: #f8f9fa; }
  .btn.bmd-btn-fab.btn-dark, .bmd-btn-fab.btn-dark.custom-file-control::before, .btn.btn-raised.btn-dark, .btn-raised.btn-dark.custom-file-control::before,
  .btn-group-raised .btn.btn-dark,
  .btn-group-raised .btn-dark.custom-file-control::before {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn.bmd-btn-fab.btn-dark:hover, .bmd-btn-fab.btn-dark.custom-file-control:hover::before, .btn.btn-raised.btn-dark:hover, .btn-raised.btn-dark.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-dark:hover,
    .btn-group-raised .btn-dark.custom-file-control:hover::before {
      color: #fff;
      background-color: #2b3035;
      border-color: #191b1e; }
    .btn.bmd-btn-fab.btn-dark:focus, .bmd-btn-fab.btn-dark.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-dark.focus, .bmd-btn-fab.btn-dark.focus.custom-file-control::before, .btn.btn-raised.btn-dark:focus, .btn-raised.btn-dark.custom-file-control:focus::before, .btn.btn-raised.btn-dark.focus, .btn-raised.btn-dark.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-dark:focus,
    .btn-group-raised .btn-dark.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-dark.focus,
    .btn-group-raised .btn-dark.focus.custom-file-control::before {
      color: #fff;
      background-color: #2b3035;
      border-color: #191b1e; }
    .btn.bmd-btn-fab.btn-dark:active, .bmd-btn-fab.btn-dark.custom-file-control:active::before, .btn.bmd-btn-fab.btn-dark.active, .bmd-btn-fab.btn-dark.active.custom-file-control::before,
    .open > .btn.bmd-btn-fab.btn-dark.dropdown-toggle,
    .open > .bmd-btn-fab.btn-dark.dropdown-toggle.custom-file-control::before, .btn.btn-raised.btn-dark:active, .btn-raised.btn-dark.custom-file-control:active::before, .btn.btn-raised.btn-dark.active, .btn-raised.btn-dark.active.custom-file-control::before,
    .open > .btn.btn-raised.btn-dark.dropdown-toggle,
    .open > .btn-raised.btn-dark.dropdown-toggle.custom-file-control::before,
    .btn-group-raised .btn.btn-dark:active,
    .btn-group-raised .btn-dark.custom-file-control:active::before,
    .btn-group-raised .btn.btn-dark.active,
    .btn-group-raised .btn-dark.active.custom-file-control::before,
    .open > .btn-group-raised .btn.btn-dark.dropdown-toggle,
    .open > .btn-group-raised .btn-dark.dropdown-toggle.custom-file-control::before {
      color: #fff;
      background-color: #2b3035;
      border-color: #191b1e; }
      .btn.bmd-btn-fab.btn-dark:active:hover, .bmd-btn-fab.btn-dark.custom-file-control:active:hover::before, .btn.bmd-btn-fab.btn-dark:active:focus, .bmd-btn-fab.btn-dark.custom-file-control:active:focus::before, .btn.bmd-btn-fab.btn-dark:active.focus, .bmd-btn-fab.btn-dark.custom-file-control:active.focus::before, .btn.bmd-btn-fab.btn-dark.active:hover, .bmd-btn-fab.btn-dark.active.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-dark.active:focus, .bmd-btn-fab.btn-dark.active.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-dark.active.focus, .bmd-btn-fab.btn-dark.active.focus.custom-file-control::before,
      .open > .btn.bmd-btn-fab.btn-dark.dropdown-toggle:hover,
      .open > .bmd-btn-fab.btn-dark.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.bmd-btn-fab.btn-dark.dropdown-toggle:focus,
      .open > .bmd-btn-fab.btn-dark.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.bmd-btn-fab.btn-dark.dropdown-toggle.focus,
      .open > .bmd-btn-fab.btn-dark.dropdown-toggle.focus.custom-file-control::before, .btn.btn-raised.btn-dark:active:hover, .btn-raised.btn-dark.custom-file-control:active:hover::before, .btn.btn-raised.btn-dark:active:focus, .btn-raised.btn-dark.custom-file-control:active:focus::before, .btn.btn-raised.btn-dark:active.focus, .btn-raised.btn-dark.custom-file-control:active.focus::before, .btn.btn-raised.btn-dark.active:hover, .btn-raised.btn-dark.active.custom-file-control:hover::before, .btn.btn-raised.btn-dark.active:focus, .btn-raised.btn-dark.active.custom-file-control:focus::before, .btn.btn-raised.btn-dark.active.focus, .btn-raised.btn-dark.active.focus.custom-file-control::before,
      .open > .btn.btn-raised.btn-dark.dropdown-toggle:hover,
      .open > .btn-raised.btn-dark.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-raised.btn-dark.dropdown-toggle:focus,
      .open > .btn-raised.btn-dark.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-raised.btn-dark.dropdown-toggle.focus,
      .open > .btn-raised.btn-dark.dropdown-toggle.focus.custom-file-control::before,
      .btn-group-raised .btn.btn-dark:active:hover,
      .btn-group-raised .btn-dark.custom-file-control:active:hover::before,
      .btn-group-raised .btn.btn-dark:active:focus,
      .btn-group-raised .btn-dark.custom-file-control:active:focus::before,
      .btn-group-raised .btn.btn-dark:active.focus,
      .btn-group-raised .btn-dark.custom-file-control:active.focus::before,
      .btn-group-raised .btn.btn-dark.active:hover,
      .btn-group-raised .btn-dark.active.custom-file-control:hover::before,
      .btn-group-raised .btn.btn-dark.active:focus,
      .btn-group-raised .btn-dark.active.custom-file-control:focus::before,
      .btn-group-raised .btn.btn-dark.active.focus,
      .btn-group-raised .btn-dark.active.focus.custom-file-control::before,
      .open > .btn-group-raised .btn.btn-dark.dropdown-toggle:hover,
      .open > .btn-group-raised .btn-dark.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn-group-raised .btn.btn-dark.dropdown-toggle:focus,
      .open > .btn-group-raised .btn-dark.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn-group-raised .btn.btn-dark.dropdown-toggle.focus,
      .open > .btn-group-raised .btn-dark.dropdown-toggle.focus.custom-file-control::before {
        color: #fff;
        background-color: #2b3035;
        border-color: black; }
    .open > .btn.bmd-btn-fab.btn-dark.dropdown-toggle.bmd-btn-icon, .open > .bmd-btn-fab.btn-dark.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn.btn-raised.btn-dark.dropdown-toggle.bmd-btn-icon, .open > .btn-raised.btn-dark.dropdown-toggle.bmd-btn-icon.custom-file-control::before, .open > .btn-group-raised .btn.btn-dark.dropdown-toggle.bmd-btn-icon, .open > .btn-group-raised .btn-dark.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: #343a40; }
      .open > .btn.bmd-btn-fab.btn-dark.dropdown-toggle.bmd-btn-icon:hover, .open > .bmd-btn-fab.btn-dark.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn.btn-raised.btn-dark.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-raised.btn-dark.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before, .open > .btn-group-raised .btn.btn-dark.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-group-raised .btn-dark.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: #2b3035; }
    .btn.bmd-btn-fab.btn-dark.disabled:focus, .bmd-btn-fab.btn-dark.disabled.custom-file-control:focus::before, .btn.bmd-btn-fab.btn-dark.disabled.focus, .bmd-btn-fab.btn-dark.disabled.focus.custom-file-control::before, .btn.bmd-btn-fab.btn-dark:disabled:focus, .bmd-btn-fab.btn-dark.custom-file-control:disabled:focus::before, .btn.bmd-btn-fab.btn-dark:disabled.focus, .bmd-btn-fab.btn-dark.custom-file-control:disabled.focus::before, .btn.btn-raised.btn-dark.disabled:focus, .btn-raised.btn-dark.disabled.custom-file-control:focus::before, .btn.btn-raised.btn-dark.disabled.focus, .btn-raised.btn-dark.disabled.focus.custom-file-control::before, .btn.btn-raised.btn-dark:disabled:focus, .btn-raised.btn-dark.custom-file-control:disabled:focus::before, .btn.btn-raised.btn-dark:disabled.focus, .btn-raised.btn-dark.custom-file-control:disabled.focus::before,
    .btn-group-raised .btn.btn-dark.disabled:focus,
    .btn-group-raised .btn-dark.disabled.custom-file-control:focus::before,
    .btn-group-raised .btn.btn-dark.disabled.focus,
    .btn-group-raised .btn-dark.disabled.focus.custom-file-control::before,
    .btn-group-raised .btn.btn-dark:disabled:focus,
    .btn-group-raised .btn-dark.custom-file-control:disabled:focus::before,
    .btn-group-raised .btn.btn-dark:disabled.focus,
    .btn-group-raised .btn-dark.custom-file-control:disabled.focus::before {
      background-color: #343a40;
      border-color: #343a40; }
    .btn.bmd-btn-fab.btn-dark.disabled:hover, .bmd-btn-fab.btn-dark.disabled.custom-file-control:hover::before, .btn.bmd-btn-fab.btn-dark:disabled:hover, .bmd-btn-fab.btn-dark.custom-file-control:disabled:hover::before, .btn.btn-raised.btn-dark.disabled:hover, .btn-raised.btn-dark.disabled.custom-file-control:hover::before, .btn.btn-raised.btn-dark:disabled:hover, .btn-raised.btn-dark.custom-file-control:disabled:hover::before,
    .btn-group-raised .btn.btn-dark.disabled:hover,
    .btn-group-raised .btn-dark.disabled.custom-file-control:hover::before,
    .btn-group-raised .btn.btn-dark:disabled:hover,
    .btn-group-raised .btn-dark.custom-file-control:disabled:hover::before {
      background-color: #343a40;
      border-color: #343a40; }
  .btn.bmd-btn-fab:hover, .bmd-btn-fab.custom-file-control:hover::before, .btn.bmd-btn-fab:focus, .bmd-btn-fab.custom-file-control:focus::before, .btn.btn-raised:hover, .btn-raised.custom-file-control:hover::before, .btn.btn-raised:focus, .btn-raised.custom-file-control:focus::before,
  .btn-group-raised .btn:hover,
  .btn-group-raised .custom-file-control:hover::before,
  .btn-group-raised .btn:focus,
  .btn-group-raised .custom-file-control:focus::before {
    z-index: 1;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2); }
  .btn.bmd-btn-fab.active, .bmd-btn-fab.active.custom-file-control::before, .btn.bmd-btn-fab:active, .bmd-btn-fab.custom-file-control:active::before, .btn.btn-raised.active, .btn-raised.active.custom-file-control::before, .btn.btn-raised:active, .btn-raised.custom-file-control:active::before,
  .btn-group-raised .btn.active,
  .btn-group-raised .active.custom-file-control::before,
  .btn-group-raised .btn:active,
  .btn-group-raised .custom-file-control:active::before {
    z-index: 1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36); }
  .btn.bmd-btn-fab.focus, .bmd-btn-fab.focus.custom-file-control::before, .btn.bmd-btn-fab:focus, .bmd-btn-fab.custom-file-control:focus::before, .btn.btn-raised.focus, .btn-raised.focus.custom-file-control::before, .btn.btn-raised:focus, .btn-raised.custom-file-control:focus::before,
  .btn-group-raised .btn.focus,
  .btn-group-raised .focus.custom-file-control::before,
  .btn-group-raised .btn:focus,
  .btn-group-raised .custom-file-control:focus::before {
    outline: 0; }
  .btn.btn-raised, .btn-raised.custom-file-control::before,
  .btn-group-raised .btn,
  .btn-group-raised .custom-file-control::before {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
    .btn.btn-raised.btn-link, .btn-raised.btn-link.custom-file-control::before,
    .btn-group-raised .btn.btn-link,
    .btn-group-raised .btn-link.custom-file-control::before {
      box-shadow: none; }
      .btn.btn-raised.btn-link.active, .btn-raised.btn-link.active.custom-file-control::before,
      .btn-group-raised .btn.btn-link.active,
      .btn-group-raised .btn-link.active.custom-file-control::before {
        box-shadow: none; }
      .btn.btn-raised.btn-link:hover, .btn-raised.btn-link.custom-file-control:hover::before, .btn.btn-raised.btn-link:focus, .btn-raised.btn-link.custom-file-control:focus::before, .btn.btn-raised.btn-link:active, .btn-raised.btn-link.custom-file-control:active::before,
      .btn-group-raised .btn.btn-link:hover,
      .btn-group-raised .btn-link.custom-file-control:hover::before,
      .btn-group-raised .btn.btn-link:focus,
      .btn-group-raised .btn-link.custom-file-control:focus::before,
      .btn-group-raised .btn.btn-link:active,
      .btn-group-raised .btn-link.custom-file-control:active::before {
        box-shadow: none; }
    fieldset[disabled][disabled] .btn.btn-raised, fieldset[disabled][disabled] .btn-raised.custom-file-control::before, .btn.btn-raised.disabled, .btn-raised.disabled.custom-file-control::before, .btn.btn-raised:disabled, .btn-raised.custom-file-control:disabled::before, .btn.btn-raised[disabled], .btn-raised.custom-file-control[disabled]::before, fieldset[disabled][disabled] .btn-group-raised .btn, fieldset[disabled][disabled] .btn-group-raised .custom-file-control::before,
    .btn-group-raised .btn.disabled,
    .btn-group-raised .disabled.custom-file-control::before,
    .btn-group-raised .btn:disabled,
    .btn-group-raised .custom-file-control:disabled::before,
    .btn-group-raised .btn[disabled],
    .btn-group-raised .custom-file-control[disabled]::before {
      box-shadow: none; }
  .btn.btn-outline, .btn-outline.custom-file-control::before {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px; }
  .btn.btn-outline-primary, .btn-outline-primary.custom-file-control::before {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    color: #007fc0;
    background-color: transparent;
    border-color: #007fc0; }
    .btn.btn-outline-primary:hover, .btn-outline-primary.custom-file-control:hover::before {
      color: #007fc0;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #007fc0; }
    .btn.btn-outline-primary:focus, .btn-outline-primary.custom-file-control:focus::before, .btn.btn-outline-primary.focus, .btn-outline-primary.focus.custom-file-control::before {
      color: #007fc0;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #007fc0; }
    .btn.btn-outline-primary:active, .btn-outline-primary.custom-file-control:active::before, .btn.btn-outline-primary.active, .btn-outline-primary.active.custom-file-control::before,
    .open > .btn.btn-outline-primary.dropdown-toggle,
    .open > .btn-outline-primary.dropdown-toggle.custom-file-control::before {
      color: #007fc0;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #007fc0; }
      .btn.btn-outline-primary:active:hover, .btn-outline-primary.custom-file-control:active:hover::before, .btn.btn-outline-primary:active:focus, .btn-outline-primary.custom-file-control:active:focus::before, .btn.btn-outline-primary:active.focus, .btn-outline-primary.custom-file-control:active.focus::before, .btn.btn-outline-primary.active:hover, .btn-outline-primary.active.custom-file-control:hover::before, .btn.btn-outline-primary.active:focus, .btn-outline-primary.active.custom-file-control:focus::before, .btn.btn-outline-primary.active.focus, .btn-outline-primary.active.focus.custom-file-control::before,
      .open > .btn.btn-outline-primary.dropdown-toggle:hover,
      .open > .btn-outline-primary.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-outline-primary.dropdown-toggle:focus,
      .open > .btn-outline-primary.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-outline-primary.dropdown-toggle.focus,
      .open > .btn-outline-primary.dropdown-toggle.focus.custom-file-control::before {
        color: #007fc0;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: #007fc0; }
    .open > .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon, .open > .btn-outline-primary.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-outline-primary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-outline-primary.disabled:focus, .btn-outline-primary.disabled.custom-file-control:focus::before, .btn.btn-outline-primary.disabled.focus, .btn-outline-primary.disabled.focus.custom-file-control::before, .btn.btn-outline-primary:disabled:focus, .btn-outline-primary.custom-file-control:disabled:focus::before, .btn.btn-outline-primary:disabled.focus, .btn-outline-primary.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #007fc0; }
    .btn.btn-outline-primary.disabled:hover, .btn-outline-primary.disabled.custom-file-control:hover::before, .btn.btn-outline-primary:disabled:hover, .btn-outline-primary.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #007fc0; }
    .bg-inverse .btn.btn-outline-primary, .bg-inverse .btn-outline-primary.custom-file-control::before {
      color: #007fc0;
      background-color: transparent;
      border-color: #007fc0; }
      .bg-inverse .btn.btn-outline-primary:hover, .bg-inverse .btn-outline-primary.custom-file-control:hover::before {
        color: #007fc0;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-primary:focus, .bg-inverse .btn-outline-primary.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-primary.focus, .bg-inverse .btn-outline-primary.focus.custom-file-control::before {
        color: #007fc0;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-primary:active, .bg-inverse .btn-outline-primary.custom-file-control:active::before, .bg-inverse .btn.btn-outline-primary.active, .bg-inverse .btn-outline-primary.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle,
      .open > .bg-inverse .btn-outline-primary.dropdown-toggle.custom-file-control::before {
        color: #007fc0;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-outline-primary:active:hover, .bg-inverse .btn-outline-primary.custom-file-control:active:hover::before, .bg-inverse .btn.btn-outline-primary:active:focus, .bg-inverse .btn-outline-primary.custom-file-control:active:focus::before, .bg-inverse .btn.btn-outline-primary:active.focus, .bg-inverse .btn-outline-primary.custom-file-control:active.focus::before, .bg-inverse .btn.btn-outline-primary.active:hover, .bg-inverse .btn-outline-primary.active.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-primary.active:focus, .bg-inverse .btn-outline-primary.active.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-primary.active.focus, .bg-inverse .btn-outline-primary.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
        .open > .bg-inverse .btn-outline-primary.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
        .open > .bg-inverse .btn-outline-primary.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus,
        .open > .bg-inverse .btn-outline-primary.dropdown-toggle.focus.custom-file-control::before {
          color: #007fc0;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-outline-primary.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-outline-primary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-primary.disabled:focus, .bg-inverse .btn-outline-primary.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-primary.disabled.focus, .bg-inverse .btn-outline-primary.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-outline-primary:disabled:focus, .bg-inverse .btn-outline-primary.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-outline-primary:disabled.focus, .bg-inverse .btn-outline-primary.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #007fc0; }
      .bg-inverse .btn.btn-outline-primary.disabled:hover, .bg-inverse .btn-outline-primary.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-primary:disabled:hover, .bg-inverse .btn-outline-primary.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #007fc0; }
    .btn.btn-outline-primary.btn-link, .btn-outline-primary.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-outline-secondary, .btn-outline-secondary.custom-file-control::before {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    color: #00befc;
    background-color: transparent;
    border-color: #00befc; }
    .btn.btn-outline-secondary:hover, .btn-outline-secondary.custom-file-control:hover::before {
      color: #00befc;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #00befc; }
    .btn.btn-outline-secondary:focus, .btn-outline-secondary.custom-file-control:focus::before, .btn.btn-outline-secondary.focus, .btn-outline-secondary.focus.custom-file-control::before {
      color: #00befc;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #00befc; }
    .btn.btn-outline-secondary:active, .btn-outline-secondary.custom-file-control:active::before, .btn.btn-outline-secondary.active, .btn-outline-secondary.active.custom-file-control::before,
    .open > .btn.btn-outline-secondary.dropdown-toggle,
    .open > .btn-outline-secondary.dropdown-toggle.custom-file-control::before {
      color: #00befc;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #00befc; }
      .btn.btn-outline-secondary:active:hover, .btn-outline-secondary.custom-file-control:active:hover::before, .btn.btn-outline-secondary:active:focus, .btn-outline-secondary.custom-file-control:active:focus::before, .btn.btn-outline-secondary:active.focus, .btn-outline-secondary.custom-file-control:active.focus::before, .btn.btn-outline-secondary.active:hover, .btn-outline-secondary.active.custom-file-control:hover::before, .btn.btn-outline-secondary.active:focus, .btn-outline-secondary.active.custom-file-control:focus::before, .btn.btn-outline-secondary.active.focus, .btn-outline-secondary.active.focus.custom-file-control::before,
      .open > .btn.btn-outline-secondary.dropdown-toggle:hover,
      .open > .btn-outline-secondary.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-outline-secondary.dropdown-toggle:focus,
      .open > .btn-outline-secondary.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-outline-secondary.dropdown-toggle.focus,
      .open > .btn-outline-secondary.dropdown-toggle.focus.custom-file-control::before {
        color: #00befc;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: #00befc; }
    .open > .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon, .open > .btn-outline-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-outline-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-outline-secondary.disabled:focus, .btn-outline-secondary.disabled.custom-file-control:focus::before, .btn.btn-outline-secondary.disabled.focus, .btn-outline-secondary.disabled.focus.custom-file-control::before, .btn.btn-outline-secondary:disabled:focus, .btn-outline-secondary.custom-file-control:disabled:focus::before, .btn.btn-outline-secondary:disabled.focus, .btn-outline-secondary.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #00befc; }
    .btn.btn-outline-secondary.disabled:hover, .btn-outline-secondary.disabled.custom-file-control:hover::before, .btn.btn-outline-secondary:disabled:hover, .btn-outline-secondary.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #00befc; }
    .bg-inverse .btn.btn-outline-secondary, .bg-inverse .btn-outline-secondary.custom-file-control::before {
      color: #00befc;
      background-color: transparent;
      border-color: #00befc; }
      .bg-inverse .btn.btn-outline-secondary:hover, .bg-inverse .btn-outline-secondary.custom-file-control:hover::before {
        color: #00befc;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-secondary:focus, .bg-inverse .btn-outline-secondary.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-secondary.focus, .bg-inverse .btn-outline-secondary.focus.custom-file-control::before {
        color: #00befc;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-secondary:active, .bg-inverse .btn-outline-secondary.custom-file-control:active::before, .bg-inverse .btn.btn-outline-secondary.active, .bg-inverse .btn-outline-secondary.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle,
      .open > .bg-inverse .btn-outline-secondary.dropdown-toggle.custom-file-control::before {
        color: #00befc;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-outline-secondary:active:hover, .bg-inverse .btn-outline-secondary.custom-file-control:active:hover::before, .bg-inverse .btn.btn-outline-secondary:active:focus, .bg-inverse .btn-outline-secondary.custom-file-control:active:focus::before, .bg-inverse .btn.btn-outline-secondary:active.focus, .bg-inverse .btn-outline-secondary.custom-file-control:active.focus::before, .bg-inverse .btn.btn-outline-secondary.active:hover, .bg-inverse .btn-outline-secondary.active.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-secondary.active:focus, .bg-inverse .btn-outline-secondary.active.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-secondary.active.focus, .bg-inverse .btn-outline-secondary.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
        .open > .bg-inverse .btn-outline-secondary.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
        .open > .bg-inverse .btn-outline-secondary.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus,
        .open > .bg-inverse .btn-outline-secondary.dropdown-toggle.focus.custom-file-control::before {
          color: #00befc;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-outline-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-outline-secondary.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-secondary.disabled:focus, .bg-inverse .btn-outline-secondary.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-secondary.disabled.focus, .bg-inverse .btn-outline-secondary.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-outline-secondary:disabled:focus, .bg-inverse .btn-outline-secondary.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-outline-secondary:disabled.focus, .bg-inverse .btn-outline-secondary.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #00befc; }
      .bg-inverse .btn.btn-outline-secondary.disabled:hover, .bg-inverse .btn-outline-secondary.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-secondary:disabled:hover, .bg-inverse .btn-outline-secondary.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #00befc; }
    .btn.btn-outline-secondary.btn-link, .btn-outline-secondary.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-outline-success, .btn-outline-success.custom-file-control::before {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    color: #28a745;
    background-color: transparent;
    border-color: #28a745; }
    .btn.btn-outline-success:hover, .btn-outline-success.custom-file-control:hover::before {
      color: #28a745;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #28a745; }
    .btn.btn-outline-success:focus, .btn-outline-success.custom-file-control:focus::before, .btn.btn-outline-success.focus, .btn-outline-success.focus.custom-file-control::before {
      color: #28a745;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #28a745; }
    .btn.btn-outline-success:active, .btn-outline-success.custom-file-control:active::before, .btn.btn-outline-success.active, .btn-outline-success.active.custom-file-control::before,
    .open > .btn.btn-outline-success.dropdown-toggle,
    .open > .btn-outline-success.dropdown-toggle.custom-file-control::before {
      color: #28a745;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #28a745; }
      .btn.btn-outline-success:active:hover, .btn-outline-success.custom-file-control:active:hover::before, .btn.btn-outline-success:active:focus, .btn-outline-success.custom-file-control:active:focus::before, .btn.btn-outline-success:active.focus, .btn-outline-success.custom-file-control:active.focus::before, .btn.btn-outline-success.active:hover, .btn-outline-success.active.custom-file-control:hover::before, .btn.btn-outline-success.active:focus, .btn-outline-success.active.custom-file-control:focus::before, .btn.btn-outline-success.active.focus, .btn-outline-success.active.focus.custom-file-control::before,
      .open > .btn.btn-outline-success.dropdown-toggle:hover,
      .open > .btn-outline-success.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-outline-success.dropdown-toggle:focus,
      .open > .btn-outline-success.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-outline-success.dropdown-toggle.focus,
      .open > .btn-outline-success.dropdown-toggle.focus.custom-file-control::before {
        color: #28a745;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: #28a745; }
    .open > .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon, .open > .btn-outline-success.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-outline-success.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-outline-success.disabled:focus, .btn-outline-success.disabled.custom-file-control:focus::before, .btn.btn-outline-success.disabled.focus, .btn-outline-success.disabled.focus.custom-file-control::before, .btn.btn-outline-success:disabled:focus, .btn-outline-success.custom-file-control:disabled:focus::before, .btn.btn-outline-success:disabled.focus, .btn-outline-success.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #28a745; }
    .btn.btn-outline-success.disabled:hover, .btn-outline-success.disabled.custom-file-control:hover::before, .btn.btn-outline-success:disabled:hover, .btn-outline-success.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #28a745; }
    .bg-inverse .btn.btn-outline-success, .bg-inverse .btn-outline-success.custom-file-control::before {
      color: #28a745;
      background-color: transparent;
      border-color: #28a745; }
      .bg-inverse .btn.btn-outline-success:hover, .bg-inverse .btn-outline-success.custom-file-control:hover::before {
        color: #28a745;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-success:focus, .bg-inverse .btn-outline-success.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-success.focus, .bg-inverse .btn-outline-success.focus.custom-file-control::before {
        color: #28a745;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-success:active, .bg-inverse .btn-outline-success.custom-file-control:active::before, .bg-inverse .btn.btn-outline-success.active, .bg-inverse .btn-outline-success.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-outline-success.dropdown-toggle,
      .open > .bg-inverse .btn-outline-success.dropdown-toggle.custom-file-control::before {
        color: #28a745;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-outline-success:active:hover, .bg-inverse .btn-outline-success.custom-file-control:active:hover::before, .bg-inverse .btn.btn-outline-success:active:focus, .bg-inverse .btn-outline-success.custom-file-control:active:focus::before, .bg-inverse .btn.btn-outline-success:active.focus, .bg-inverse .btn-outline-success.custom-file-control:active.focus::before, .bg-inverse .btn.btn-outline-success.active:hover, .bg-inverse .btn-outline-success.active.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-success.active:focus, .bg-inverse .btn-outline-success.active.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-success.active.focus, .bg-inverse .btn-outline-success.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
        .open > .bg-inverse .btn-outline-success.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
        .open > .bg-inverse .btn-outline-success.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.focus,
        .open > .bg-inverse .btn-outline-success.dropdown-toggle.focus.custom-file-control::before {
          color: #28a745;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-outline-success.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-outline-success.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-success.disabled:focus, .bg-inverse .btn-outline-success.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-success.disabled.focus, .bg-inverse .btn-outline-success.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-outline-success:disabled:focus, .bg-inverse .btn-outline-success.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-outline-success:disabled.focus, .bg-inverse .btn-outline-success.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #28a745; }
      .bg-inverse .btn.btn-outline-success.disabled:hover, .bg-inverse .btn-outline-success.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-success:disabled:hover, .bg-inverse .btn-outline-success.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #28a745; }
    .btn.btn-outline-success.btn-link, .btn-outline-success.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-outline-info, .btn-outline-info.custom-file-control::before {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    color: #17a2b8;
    background-color: transparent;
    border-color: #17a2b8; }
    .btn.btn-outline-info:hover, .btn-outline-info.custom-file-control:hover::before {
      color: #17a2b8;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #17a2b8; }
    .btn.btn-outline-info:focus, .btn-outline-info.custom-file-control:focus::before, .btn.btn-outline-info.focus, .btn-outline-info.focus.custom-file-control::before {
      color: #17a2b8;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #17a2b8; }
    .btn.btn-outline-info:active, .btn-outline-info.custom-file-control:active::before, .btn.btn-outline-info.active, .btn-outline-info.active.custom-file-control::before,
    .open > .btn.btn-outline-info.dropdown-toggle,
    .open > .btn-outline-info.dropdown-toggle.custom-file-control::before {
      color: #17a2b8;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #17a2b8; }
      .btn.btn-outline-info:active:hover, .btn-outline-info.custom-file-control:active:hover::before, .btn.btn-outline-info:active:focus, .btn-outline-info.custom-file-control:active:focus::before, .btn.btn-outline-info:active.focus, .btn-outline-info.custom-file-control:active.focus::before, .btn.btn-outline-info.active:hover, .btn-outline-info.active.custom-file-control:hover::before, .btn.btn-outline-info.active:focus, .btn-outline-info.active.custom-file-control:focus::before, .btn.btn-outline-info.active.focus, .btn-outline-info.active.focus.custom-file-control::before,
      .open > .btn.btn-outline-info.dropdown-toggle:hover,
      .open > .btn-outline-info.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-outline-info.dropdown-toggle:focus,
      .open > .btn-outline-info.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-outline-info.dropdown-toggle.focus,
      .open > .btn-outline-info.dropdown-toggle.focus.custom-file-control::before {
        color: #17a2b8;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: #17a2b8; }
    .open > .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon, .open > .btn-outline-info.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-outline-info.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-outline-info.disabled:focus, .btn-outline-info.disabled.custom-file-control:focus::before, .btn.btn-outline-info.disabled.focus, .btn-outline-info.disabled.focus.custom-file-control::before, .btn.btn-outline-info:disabled:focus, .btn-outline-info.custom-file-control:disabled:focus::before, .btn.btn-outline-info:disabled.focus, .btn-outline-info.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #17a2b8; }
    .btn.btn-outline-info.disabled:hover, .btn-outline-info.disabled.custom-file-control:hover::before, .btn.btn-outline-info:disabled:hover, .btn-outline-info.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #17a2b8; }
    .bg-inverse .btn.btn-outline-info, .bg-inverse .btn-outline-info.custom-file-control::before {
      color: #17a2b8;
      background-color: transparent;
      border-color: #17a2b8; }
      .bg-inverse .btn.btn-outline-info:hover, .bg-inverse .btn-outline-info.custom-file-control:hover::before {
        color: #17a2b8;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-info:focus, .bg-inverse .btn-outline-info.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-info.focus, .bg-inverse .btn-outline-info.focus.custom-file-control::before {
        color: #17a2b8;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-info:active, .bg-inverse .btn-outline-info.custom-file-control:active::before, .bg-inverse .btn.btn-outline-info.active, .bg-inverse .btn-outline-info.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-outline-info.dropdown-toggle,
      .open > .bg-inverse .btn-outline-info.dropdown-toggle.custom-file-control::before {
        color: #17a2b8;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-outline-info:active:hover, .bg-inverse .btn-outline-info.custom-file-control:active:hover::before, .bg-inverse .btn.btn-outline-info:active:focus, .bg-inverse .btn-outline-info.custom-file-control:active:focus::before, .bg-inverse .btn.btn-outline-info:active.focus, .bg-inverse .btn-outline-info.custom-file-control:active.focus::before, .bg-inverse .btn.btn-outline-info.active:hover, .bg-inverse .btn-outline-info.active.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-info.active:focus, .bg-inverse .btn-outline-info.active.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-info.active.focus, .bg-inverse .btn-outline-info.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
        .open > .bg-inverse .btn-outline-info.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
        .open > .bg-inverse .btn-outline-info.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.focus,
        .open > .bg-inverse .btn-outline-info.dropdown-toggle.focus.custom-file-control::before {
          color: #17a2b8;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-outline-info.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-outline-info.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-info.disabled:focus, .bg-inverse .btn-outline-info.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-info.disabled.focus, .bg-inverse .btn-outline-info.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-outline-info:disabled:focus, .bg-inverse .btn-outline-info.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-outline-info:disabled.focus, .bg-inverse .btn-outline-info.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #17a2b8; }
      .bg-inverse .btn.btn-outline-info.disabled:hover, .bg-inverse .btn-outline-info.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-info:disabled:hover, .bg-inverse .btn-outline-info.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #17a2b8; }
    .btn.btn-outline-info.btn-link, .btn-outline-info.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-outline-warning, .btn-outline-warning.custom-file-control::before {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    color: #ffc107;
    background-color: transparent;
    border-color: #ffc107; }
    .btn.btn-outline-warning:hover, .btn-outline-warning.custom-file-control:hover::before {
      color: #ffc107;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #ffc107; }
    .btn.btn-outline-warning:focus, .btn-outline-warning.custom-file-control:focus::before, .btn.btn-outline-warning.focus, .btn-outline-warning.focus.custom-file-control::before {
      color: #ffc107;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #ffc107; }
    .btn.btn-outline-warning:active, .btn-outline-warning.custom-file-control:active::before, .btn.btn-outline-warning.active, .btn-outline-warning.active.custom-file-control::before,
    .open > .btn.btn-outline-warning.dropdown-toggle,
    .open > .btn-outline-warning.dropdown-toggle.custom-file-control::before {
      color: #ffc107;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #ffc107; }
      .btn.btn-outline-warning:active:hover, .btn-outline-warning.custom-file-control:active:hover::before, .btn.btn-outline-warning:active:focus, .btn-outline-warning.custom-file-control:active:focus::before, .btn.btn-outline-warning:active.focus, .btn-outline-warning.custom-file-control:active.focus::before, .btn.btn-outline-warning.active:hover, .btn-outline-warning.active.custom-file-control:hover::before, .btn.btn-outline-warning.active:focus, .btn-outline-warning.active.custom-file-control:focus::before, .btn.btn-outline-warning.active.focus, .btn-outline-warning.active.focus.custom-file-control::before,
      .open > .btn.btn-outline-warning.dropdown-toggle:hover,
      .open > .btn-outline-warning.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-outline-warning.dropdown-toggle:focus,
      .open > .btn-outline-warning.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-outline-warning.dropdown-toggle.focus,
      .open > .btn-outline-warning.dropdown-toggle.focus.custom-file-control::before {
        color: #ffc107;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: #ffc107; }
    .open > .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon, .open > .btn-outline-warning.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-outline-warning.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-outline-warning.disabled:focus, .btn-outline-warning.disabled.custom-file-control:focus::before, .btn.btn-outline-warning.disabled.focus, .btn-outline-warning.disabled.focus.custom-file-control::before, .btn.btn-outline-warning:disabled:focus, .btn-outline-warning.custom-file-control:disabled:focus::before, .btn.btn-outline-warning:disabled.focus, .btn-outline-warning.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #ffc107; }
    .btn.btn-outline-warning.disabled:hover, .btn-outline-warning.disabled.custom-file-control:hover::before, .btn.btn-outline-warning:disabled:hover, .btn-outline-warning.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #ffc107; }
    .bg-inverse .btn.btn-outline-warning, .bg-inverse .btn-outline-warning.custom-file-control::before {
      color: #ffc107;
      background-color: transparent;
      border-color: #ffc107; }
      .bg-inverse .btn.btn-outline-warning:hover, .bg-inverse .btn-outline-warning.custom-file-control:hover::before {
        color: #ffc107;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-warning:focus, .bg-inverse .btn-outline-warning.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-warning.focus, .bg-inverse .btn-outline-warning.focus.custom-file-control::before {
        color: #ffc107;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-warning:active, .bg-inverse .btn-outline-warning.custom-file-control:active::before, .bg-inverse .btn.btn-outline-warning.active, .bg-inverse .btn-outline-warning.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle,
      .open > .bg-inverse .btn-outline-warning.dropdown-toggle.custom-file-control::before {
        color: #ffc107;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-outline-warning:active:hover, .bg-inverse .btn-outline-warning.custom-file-control:active:hover::before, .bg-inverse .btn.btn-outline-warning:active:focus, .bg-inverse .btn-outline-warning.custom-file-control:active:focus::before, .bg-inverse .btn.btn-outline-warning:active.focus, .bg-inverse .btn-outline-warning.custom-file-control:active.focus::before, .bg-inverse .btn.btn-outline-warning.active:hover, .bg-inverse .btn-outline-warning.active.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-warning.active:focus, .bg-inverse .btn-outline-warning.active.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-warning.active.focus, .bg-inverse .btn-outline-warning.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
        .open > .bg-inverse .btn-outline-warning.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
        .open > .bg-inverse .btn-outline-warning.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus,
        .open > .bg-inverse .btn-outline-warning.dropdown-toggle.focus.custom-file-control::before {
          color: #ffc107;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-outline-warning.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-outline-warning.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-warning.disabled:focus, .bg-inverse .btn-outline-warning.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-warning.disabled.focus, .bg-inverse .btn-outline-warning.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-outline-warning:disabled:focus, .bg-inverse .btn-outline-warning.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-outline-warning:disabled.focus, .bg-inverse .btn-outline-warning.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #ffc107; }
      .bg-inverse .btn.btn-outline-warning.disabled:hover, .bg-inverse .btn-outline-warning.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-warning:disabled:hover, .bg-inverse .btn-outline-warning.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #ffc107; }
    .btn.btn-outline-warning.btn-link, .btn-outline-warning.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-outline-danger, .btn-outline-danger.custom-file-control::before {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    color: #dc3545;
    background-color: transparent;
    border-color: #dc3545; }
    .btn.btn-outline-danger:hover, .btn-outline-danger.custom-file-control:hover::before {
      color: #dc3545;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #dc3545; }
    .btn.btn-outline-danger:focus, .btn-outline-danger.custom-file-control:focus::before, .btn.btn-outline-danger.focus, .btn-outline-danger.focus.custom-file-control::before {
      color: #dc3545;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #dc3545; }
    .btn.btn-outline-danger:active, .btn-outline-danger.custom-file-control:active::before, .btn.btn-outline-danger.active, .btn-outline-danger.active.custom-file-control::before,
    .open > .btn.btn-outline-danger.dropdown-toggle,
    .open > .btn-outline-danger.dropdown-toggle.custom-file-control::before {
      color: #dc3545;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #dc3545; }
      .btn.btn-outline-danger:active:hover, .btn-outline-danger.custom-file-control:active:hover::before, .btn.btn-outline-danger:active:focus, .btn-outline-danger.custom-file-control:active:focus::before, .btn.btn-outline-danger:active.focus, .btn-outline-danger.custom-file-control:active.focus::before, .btn.btn-outline-danger.active:hover, .btn-outline-danger.active.custom-file-control:hover::before, .btn.btn-outline-danger.active:focus, .btn-outline-danger.active.custom-file-control:focus::before, .btn.btn-outline-danger.active.focus, .btn-outline-danger.active.focus.custom-file-control::before,
      .open > .btn.btn-outline-danger.dropdown-toggle:hover,
      .open > .btn-outline-danger.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-outline-danger.dropdown-toggle:focus,
      .open > .btn-outline-danger.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-outline-danger.dropdown-toggle.focus,
      .open > .btn-outline-danger.dropdown-toggle.focus.custom-file-control::before {
        color: #dc3545;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: #dc3545; }
    .open > .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon, .open > .btn-outline-danger.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-outline-danger.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-outline-danger.disabled:focus, .btn-outline-danger.disabled.custom-file-control:focus::before, .btn.btn-outline-danger.disabled.focus, .btn-outline-danger.disabled.focus.custom-file-control::before, .btn.btn-outline-danger:disabled:focus, .btn-outline-danger.custom-file-control:disabled:focus::before, .btn.btn-outline-danger:disabled.focus, .btn-outline-danger.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #dc3545; }
    .btn.btn-outline-danger.disabled:hover, .btn-outline-danger.disabled.custom-file-control:hover::before, .btn.btn-outline-danger:disabled:hover, .btn-outline-danger.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #dc3545; }
    .bg-inverse .btn.btn-outline-danger, .bg-inverse .btn-outline-danger.custom-file-control::before {
      color: #dc3545;
      background-color: transparent;
      border-color: #dc3545; }
      .bg-inverse .btn.btn-outline-danger:hover, .bg-inverse .btn-outline-danger.custom-file-control:hover::before {
        color: #dc3545;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-danger:focus, .bg-inverse .btn-outline-danger.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-danger.focus, .bg-inverse .btn-outline-danger.focus.custom-file-control::before {
        color: #dc3545;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-danger:active, .bg-inverse .btn-outline-danger.custom-file-control:active::before, .bg-inverse .btn.btn-outline-danger.active, .bg-inverse .btn-outline-danger.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle,
      .open > .bg-inverse .btn-outline-danger.dropdown-toggle.custom-file-control::before {
        color: #dc3545;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-outline-danger:active:hover, .bg-inverse .btn-outline-danger.custom-file-control:active:hover::before, .bg-inverse .btn.btn-outline-danger:active:focus, .bg-inverse .btn-outline-danger.custom-file-control:active:focus::before, .bg-inverse .btn.btn-outline-danger:active.focus, .bg-inverse .btn-outline-danger.custom-file-control:active.focus::before, .bg-inverse .btn.btn-outline-danger.active:hover, .bg-inverse .btn-outline-danger.active.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-danger.active:focus, .bg-inverse .btn-outline-danger.active.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-danger.active.focus, .bg-inverse .btn-outline-danger.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
        .open > .bg-inverse .btn-outline-danger.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
        .open > .bg-inverse .btn-outline-danger.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus,
        .open > .bg-inverse .btn-outline-danger.dropdown-toggle.focus.custom-file-control::before {
          color: #dc3545;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-outline-danger.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-outline-danger.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-danger.disabled:focus, .bg-inverse .btn-outline-danger.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-danger.disabled.focus, .bg-inverse .btn-outline-danger.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-outline-danger:disabled:focus, .bg-inverse .btn-outline-danger.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-outline-danger:disabled.focus, .bg-inverse .btn-outline-danger.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #dc3545; }
      .bg-inverse .btn.btn-outline-danger.disabled:hover, .bg-inverse .btn-outline-danger.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-danger:disabled:hover, .bg-inverse .btn-outline-danger.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #dc3545; }
    .btn.btn-outline-danger.btn-link, .btn-outline-danger.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-outline-light, .btn-outline-light.custom-file-control::before {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    color: #f8f9fa;
    background-color: transparent;
    border-color: #f8f9fa; }
    .btn.btn-outline-light:hover, .btn-outline-light.custom-file-control:hover::before {
      color: #f8f9fa;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #f8f9fa; }
    .btn.btn-outline-light:focus, .btn-outline-light.custom-file-control:focus::before, .btn.btn-outline-light.focus, .btn-outline-light.focus.custom-file-control::before {
      color: #f8f9fa;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #f8f9fa; }
    .btn.btn-outline-light:active, .btn-outline-light.custom-file-control:active::before, .btn.btn-outline-light.active, .btn-outline-light.active.custom-file-control::before,
    .open > .btn.btn-outline-light.dropdown-toggle,
    .open > .btn-outline-light.dropdown-toggle.custom-file-control::before {
      color: #f8f9fa;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #f8f9fa; }
      .btn.btn-outline-light:active:hover, .btn-outline-light.custom-file-control:active:hover::before, .btn.btn-outline-light:active:focus, .btn-outline-light.custom-file-control:active:focus::before, .btn.btn-outline-light:active.focus, .btn-outline-light.custom-file-control:active.focus::before, .btn.btn-outline-light.active:hover, .btn-outline-light.active.custom-file-control:hover::before, .btn.btn-outline-light.active:focus, .btn-outline-light.active.custom-file-control:focus::before, .btn.btn-outline-light.active.focus, .btn-outline-light.active.focus.custom-file-control::before,
      .open > .btn.btn-outline-light.dropdown-toggle:hover,
      .open > .btn-outline-light.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-outline-light.dropdown-toggle:focus,
      .open > .btn-outline-light.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-outline-light.dropdown-toggle.focus,
      .open > .btn-outline-light.dropdown-toggle.focus.custom-file-control::before {
        color: #f8f9fa;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: #f8f9fa; }
    .open > .btn.btn-outline-light.dropdown-toggle.bmd-btn-icon, .open > .btn-outline-light.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-outline-light.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-outline-light.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-outline-light.disabled:focus, .btn-outline-light.disabled.custom-file-control:focus::before, .btn.btn-outline-light.disabled.focus, .btn-outline-light.disabled.focus.custom-file-control::before, .btn.btn-outline-light:disabled:focus, .btn-outline-light.custom-file-control:disabled:focus::before, .btn.btn-outline-light:disabled.focus, .btn-outline-light.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #f8f9fa; }
    .btn.btn-outline-light.disabled:hover, .btn-outline-light.disabled.custom-file-control:hover::before, .btn.btn-outline-light:disabled:hover, .btn-outline-light.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #f8f9fa; }
    .bg-inverse .btn.btn-outline-light, .bg-inverse .btn-outline-light.custom-file-control::before {
      color: #f8f9fa;
      background-color: transparent;
      border-color: #f8f9fa; }
      .bg-inverse .btn.btn-outline-light:hover, .bg-inverse .btn-outline-light.custom-file-control:hover::before {
        color: #f8f9fa;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-light:focus, .bg-inverse .btn-outline-light.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-light.focus, .bg-inverse .btn-outline-light.focus.custom-file-control::before {
        color: #f8f9fa;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-light:active, .bg-inverse .btn-outline-light.custom-file-control:active::before, .bg-inverse .btn.btn-outline-light.active, .bg-inverse .btn-outline-light.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-outline-light.dropdown-toggle,
      .open > .bg-inverse .btn-outline-light.dropdown-toggle.custom-file-control::before {
        color: #f8f9fa;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-outline-light:active:hover, .bg-inverse .btn-outline-light.custom-file-control:active:hover::before, .bg-inverse .btn.btn-outline-light:active:focus, .bg-inverse .btn-outline-light.custom-file-control:active:focus::before, .bg-inverse .btn.btn-outline-light:active.focus, .bg-inverse .btn-outline-light.custom-file-control:active.focus::before, .bg-inverse .btn.btn-outline-light.active:hover, .bg-inverse .btn-outline-light.active.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-light.active:focus, .bg-inverse .btn-outline-light.active.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-light.active.focus, .bg-inverse .btn-outline-light.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-outline-light.dropdown-toggle:hover,
        .open > .bg-inverse .btn-outline-light.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-outline-light.dropdown-toggle:focus,
        .open > .bg-inverse .btn-outline-light.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-outline-light.dropdown-toggle.focus,
        .open > .bg-inverse .btn-outline-light.dropdown-toggle.focus.custom-file-control::before {
          color: #f8f9fa;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-outline-light.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-outline-light.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-outline-light.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-outline-light.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-light.disabled:focus, .bg-inverse .btn-outline-light.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-light.disabled.focus, .bg-inverse .btn-outline-light.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-outline-light:disabled:focus, .bg-inverse .btn-outline-light.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-outline-light:disabled.focus, .bg-inverse .btn-outline-light.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #f8f9fa; }
      .bg-inverse .btn.btn-outline-light.disabled:hover, .bg-inverse .btn-outline-light.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-light:disabled:hover, .bg-inverse .btn-outline-light.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #f8f9fa; }
    .btn.btn-outline-light.btn-link, .btn-outline-light.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.btn-outline-dark, .btn-outline-dark.custom-file-control::before {
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    color: #343a40;
    background-color: transparent;
    border-color: #343a40; }
    .btn.btn-outline-dark:hover, .btn-outline-dark.custom-file-control:hover::before {
      color: #343a40;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #343a40; }
    .btn.btn-outline-dark:focus, .btn-outline-dark.custom-file-control:focus::before, .btn.btn-outline-dark.focus, .btn-outline-dark.focus.custom-file-control::before {
      color: #343a40;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #343a40; }
    .btn.btn-outline-dark:active, .btn-outline-dark.custom-file-control:active::before, .btn.btn-outline-dark.active, .btn-outline-dark.active.custom-file-control::before,
    .open > .btn.btn-outline-dark.dropdown-toggle,
    .open > .btn-outline-dark.dropdown-toggle.custom-file-control::before {
      color: #343a40;
      background-color: rgba(153, 153, 153, 0.2);
      border-color: #343a40; }
      .btn.btn-outline-dark:active:hover, .btn-outline-dark.custom-file-control:active:hover::before, .btn.btn-outline-dark:active:focus, .btn-outline-dark.custom-file-control:active:focus::before, .btn.btn-outline-dark:active.focus, .btn-outline-dark.custom-file-control:active.focus::before, .btn.btn-outline-dark.active:hover, .btn-outline-dark.active.custom-file-control:hover::before, .btn.btn-outline-dark.active:focus, .btn-outline-dark.active.custom-file-control:focus::before, .btn.btn-outline-dark.active.focus, .btn-outline-dark.active.focus.custom-file-control::before,
      .open > .btn.btn-outline-dark.dropdown-toggle:hover,
      .open > .btn-outline-dark.dropdown-toggle.custom-file-control:hover::before,
      .open > .btn.btn-outline-dark.dropdown-toggle:focus,
      .open > .btn-outline-dark.dropdown-toggle.custom-file-control:focus::before,
      .open > .btn.btn-outline-dark.dropdown-toggle.focus,
      .open > .btn-outline-dark.dropdown-toggle.focus.custom-file-control::before {
        color: #343a40;
        background-color: rgba(153, 153, 153, 0.4);
        border-color: #343a40; }
    .open > .btn.btn-outline-dark.dropdown-toggle.bmd-btn-icon, .open > .btn-outline-dark.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
      color: inherit;
      background-color: transparent; }
      .open > .btn.btn-outline-dark.dropdown-toggle.bmd-btn-icon:hover, .open > .btn-outline-dark.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
        background-color: rgba(153, 153, 153, 0.2); }
    .btn.btn-outline-dark.disabled:focus, .btn-outline-dark.disabled.custom-file-control:focus::before, .btn.btn-outline-dark.disabled.focus, .btn-outline-dark.disabled.focus.custom-file-control::before, .btn.btn-outline-dark:disabled:focus, .btn-outline-dark.custom-file-control:disabled:focus::before, .btn.btn-outline-dark:disabled.focus, .btn-outline-dark.custom-file-control:disabled.focus::before {
      background-color: transparent;
      border-color: #343a40; }
    .btn.btn-outline-dark.disabled:hover, .btn-outline-dark.disabled.custom-file-control:hover::before, .btn.btn-outline-dark:disabled:hover, .btn-outline-dark.custom-file-control:disabled:hover::before {
      background-color: transparent;
      border-color: #343a40; }
    .bg-inverse .btn.btn-outline-dark, .bg-inverse .btn-outline-dark.custom-file-control::before {
      color: #343a40;
      background-color: transparent;
      border-color: #343a40; }
      .bg-inverse .btn.btn-outline-dark:hover, .bg-inverse .btn-outline-dark.custom-file-control:hover::before {
        color: #343a40;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-dark:focus, .bg-inverse .btn-outline-dark.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-dark.focus, .bg-inverse .btn-outline-dark.focus.custom-file-control::before {
        color: #343a40;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-dark:active, .bg-inverse .btn-outline-dark.custom-file-control:active::before, .bg-inverse .btn.btn-outline-dark.active, .bg-inverse .btn-outline-dark.active.custom-file-control::before,
      .open > .bg-inverse .btn.btn-outline-dark.dropdown-toggle,
      .open > .bg-inverse .btn-outline-dark.dropdown-toggle.custom-file-control::before {
        color: #343a40;
        background-color: rgba(204, 204, 204, 0.15);
        border-color: rgba(204, 204, 204, 0.15); }
        .bg-inverse .btn.btn-outline-dark:active:hover, .bg-inverse .btn-outline-dark.custom-file-control:active:hover::before, .bg-inverse .btn.btn-outline-dark:active:focus, .bg-inverse .btn-outline-dark.custom-file-control:active:focus::before, .bg-inverse .btn.btn-outline-dark:active.focus, .bg-inverse .btn-outline-dark.custom-file-control:active.focus::before, .bg-inverse .btn.btn-outline-dark.active:hover, .bg-inverse .btn-outline-dark.active.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-dark.active:focus, .bg-inverse .btn-outline-dark.active.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-dark.active.focus, .bg-inverse .btn-outline-dark.active.focus.custom-file-control::before,
        .open > .bg-inverse .btn.btn-outline-dark.dropdown-toggle:hover,
        .open > .bg-inverse .btn-outline-dark.dropdown-toggle.custom-file-control:hover::before,
        .open > .bg-inverse .btn.btn-outline-dark.dropdown-toggle:focus,
        .open > .bg-inverse .btn-outline-dark.dropdown-toggle.custom-file-control:focus::before,
        .open > .bg-inverse .btn.btn-outline-dark.dropdown-toggle.focus,
        .open > .bg-inverse .btn-outline-dark.dropdown-toggle.focus.custom-file-control::before {
          color: #343a40;
          background-color: rgba(204, 204, 204, 0.25);
          border-color: rgba(204, 204, 204, 0.25); }
      .open > .bg-inverse .btn.btn-outline-dark.dropdown-toggle.bmd-btn-icon, .open > .bg-inverse .btn-outline-dark.dropdown-toggle.bmd-btn-icon.custom-file-control::before {
        color: inherit;
        background-color: transparent; }
        .open > .bg-inverse .btn.btn-outline-dark.dropdown-toggle.bmd-btn-icon:hover, .open > .bg-inverse .btn-outline-dark.dropdown-toggle.bmd-btn-icon.custom-file-control:hover::before {
          background-color: rgba(204, 204, 204, 0.15); }
      .bg-inverse .btn.btn-outline-dark.disabled:focus, .bg-inverse .btn-outline-dark.disabled.custom-file-control:focus::before, .bg-inverse .btn.btn-outline-dark.disabled.focus, .bg-inverse .btn-outline-dark.disabled.focus.custom-file-control::before, .bg-inverse .btn.btn-outline-dark:disabled:focus, .bg-inverse .btn-outline-dark.custom-file-control:disabled:focus::before, .bg-inverse .btn.btn-outline-dark:disabled.focus, .bg-inverse .btn-outline-dark.custom-file-control:disabled.focus::before {
        background-color: transparent;
        border-color: #343a40; }
      .bg-inverse .btn.btn-outline-dark.disabled:hover, .bg-inverse .btn-outline-dark.disabled.custom-file-control:hover::before, .bg-inverse .btn.btn-outline-dark:disabled:hover, .bg-inverse .btn-outline-dark.custom-file-control:disabled:hover::before {
        background-color: transparent;
        border-color: #343a40; }
    .btn.btn-outline-dark.btn-link, .btn-outline-dark.btn-link.custom-file-control::before {
      background-color: transparent; }
  .btn.bmd-btn-fab, .bmd-btn-fab.custom-file-control::before, .btn.bmd-btn-icon, .bmd-btn-icon.custom-file-control::before {
    overflow: hidden;
    font-size: 1.5rem;
    line-height: 0; }
    .btn-group-lg .btn.bmd-btn-fab, .btn-group-lg .bmd-btn-fab.custom-file-control::before,
    .btn-group-sm .btn.bmd-btn-fab,
    .btn-group-sm .bmd-btn-fab.custom-file-control::before, .btn.bmd-btn-fab, .bmd-btn-fab.custom-file-control::before, .btn-group-lg .btn.bmd-btn-icon, .btn-group-lg .bmd-btn-icon.custom-file-control::before,
    .btn-group-sm .btn.bmd-btn-icon,
    .btn-group-sm .bmd-btn-icon.custom-file-control::before, .btn.bmd-btn-icon, .bmd-btn-icon.custom-file-control::before {
      padding: 0;
      border-radius: 50%;
      line-height: 0; }
    .btn.bmd-btn-fab .material-icons, .bmd-btn-fab.custom-file-control::before .material-icons, .btn.bmd-btn-icon .material-icons, .bmd-btn-icon.custom-file-control::before .material-icons {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.5rem;
      line-height: 1.5rem;
      transform: translate(-0.75rem, -0.75rem); }
  .btn.bmd-btn-fab, .bmd-btn-fab.custom-file-control::before {
    width: 3.5rem;
    min-width: 3.5rem;
    height: 3.5rem;
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.26); }
    .btn.bmd-btn-fab .ripple-container, .bmd-btn-fab.custom-file-control::before .ripple-container {
      border-radius: 50%; }
    .btn.bmd-btn-fab.bmd-btn-fab-sm, .bmd-btn-fab.bmd-btn-fab-sm.custom-file-control::before,
    .btn-group-sm .btn.bmd-btn-fab,
    .btn-group-sm .bmd-btn-fab.custom-file-control::before {
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem; }
  .btn.bmd-btn-icon, .bmd-btn-icon.custom-file-control::before {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    margin: 0;
    color: inherit;
    line-height: 0; }
    .btn.bmd-btn-icon.bmd-btn-icon-sm, .bmd-btn-icon.bmd-btn-icon-sm.custom-file-control::before,
    .btn-group-sm .btn.bmd-btn-icon,
    .btn-group-sm .bmd-btn-icon.custom-file-control::before {
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;
      font-size: 1.125rem; }
      .btn.bmd-btn-icon.bmd-btn-icon-sm .material-icons, .bmd-btn-icon.bmd-btn-icon-sm.custom-file-control::before .material-icons,
      .btn-group-sm .btn.bmd-btn-icon .material-icons,
      .btn-group-sm .bmd-btn-icon.custom-file-control::before .material-icons {
        width: 1.125rem;
        font-size: 1.125rem;
        line-height: 1;
        vertical-align: middle;
        transform: translate(-0.5625rem, -0.5625rem); }
  .btn .material-icons, .custom-file-control::before .material-icons {
    position: relative;
    display: inline-block;
    top: .25em;
    margin-top: -1em;
    margin-bottom: -1em;
    font-size: 1.5em;
    vertical-align: middle; }
  .btn.btn-lg, .btn-lg.custom-file-control::before,
  .btn-group-lg .btn,
  .btn-group-lg .custom-file-control::before {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem; }
  .btn.btn-sm, .btn-sm.custom-file-control::before,
  .btn-group-sm .btn,
  .btn-group-sm .custom-file-control::before {
    padding: 0.25rem 0.5rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    font-size: 0.8125rem; }

fieldset[disabled][disabled] .btn, fieldset[disabled][disabled] .custom-file-control::before, .btn.disabled, .disabled.custom-file-control::before, .btn:disabled, .custom-file-control:disabled::before, .btn[disabled], .custom-file-control[disabled]::before, fieldset[disabled][disabled] .input-group-btn .btn, fieldset[disabled][disabled] .input-group-btn .custom-file-control::before,
.input-group-btn .btn.disabled,
.input-group-btn .disabled.custom-file-control::before,
.input-group-btn .btn:disabled,
.input-group-btn .custom-file-control:disabled::before,
.input-group-btn .btn[disabled],
.input-group-btn .custom-file-control[disabled]::before, fieldset[disabled][disabled]
.btn-group,
.btn-group.disabled,
.btn-group:disabled,
.btn-group[disabled], fieldset[disabled][disabled]
.btn-group-vertical,
.btn-group-vertical.disabled,
.btn-group-vertical:disabled,
.btn-group-vertical[disabled] {
  color: rgba(0, 0, 0, 0.26); }
  .bg-inverse fieldset[disabled][disabled] .btn, .bg-inverse fieldset[disabled][disabled] .custom-file-control::before, .bg-inverse .btn.disabled, .bg-inverse .disabled.custom-file-control::before, .bg-inverse .btn:disabled, .bg-inverse .custom-file-control:disabled::before, .bg-inverse .btn[disabled], .bg-inverse .custom-file-control[disabled]::before, .bg-inverse fieldset[disabled][disabled] .input-group-btn .btn, .bg-inverse fieldset[disabled][disabled] .input-group-btn .custom-file-control::before, .bg-inverse .input-group-btn .btn.disabled, .bg-inverse .input-group-btn .disabled.custom-file-control::before, .bg-inverse .input-group-btn .btn:disabled, .bg-inverse .input-group-btn .custom-file-control:disabled::before, .bg-inverse .input-group-btn .btn[disabled], .bg-inverse .input-group-btn .custom-file-control[disabled]::before, .bg-inverse fieldset[disabled][disabled]
  .btn-group, .bg-inverse
  .btn-group.disabled, .bg-inverse
  .btn-group:disabled, .bg-inverse
  .btn-group[disabled], .bg-inverse fieldset[disabled][disabled]
  .btn-group-vertical, .bg-inverse
  .btn-group-vertical.disabled, .bg-inverse
  .btn-group-vertical:disabled, .bg-inverse
  .btn-group-vertical[disabled] {
    color: rgba(255, 255, 255, 0.3); }
  fieldset[disabled][disabled] .btn, fieldset[disabled][disabled] .custom-file-control::before, fieldset[disabled][disabled] .btn:hover, fieldset[disabled][disabled] .custom-file-control:hover::before, fieldset[disabled][disabled] .btn:focus, fieldset[disabled][disabled] .custom-file-control:focus::before, .btn.disabled, .disabled.custom-file-control::before, .btn.disabled:hover, .disabled.custom-file-control:hover::before, .btn.disabled:focus, .disabled.custom-file-control:focus::before, .btn:disabled, .custom-file-control:disabled::before, .btn:disabled:hover, .custom-file-control:disabled:hover::before, .btn:disabled:focus, .custom-file-control:disabled:focus::before, .btn[disabled], .custom-file-control[disabled]::before, .btn[disabled]:hover, .custom-file-control[disabled]:hover::before, .btn[disabled]:focus, .custom-file-control[disabled]:focus::before, fieldset[disabled][disabled] .input-group-btn .btn, fieldset[disabled][disabled] .input-group-btn .custom-file-control::before, fieldset[disabled][disabled] .input-group-btn .btn:hover, fieldset[disabled][disabled] .input-group-btn .custom-file-control:hover::before, fieldset[disabled][disabled] .input-group-btn .btn:focus, fieldset[disabled][disabled] .input-group-btn .custom-file-control:focus::before,
  .input-group-btn .btn.disabled,
  .input-group-btn .disabled.custom-file-control::before,
  .input-group-btn .btn.disabled:hover,
  .input-group-btn .disabled.custom-file-control:hover::before,
  .input-group-btn .btn.disabled:focus,
  .input-group-btn .disabled.custom-file-control:focus::before,
  .input-group-btn .btn:disabled,
  .input-group-btn .custom-file-control:disabled::before,
  .input-group-btn .btn:disabled:hover,
  .input-group-btn .custom-file-control:disabled:hover::before,
  .input-group-btn .btn:disabled:focus,
  .input-group-btn .custom-file-control:disabled:focus::before,
  .input-group-btn .btn[disabled],
  .input-group-btn .custom-file-control[disabled]::before,
  .input-group-btn .btn[disabled]:hover,
  .input-group-btn .custom-file-control[disabled]:hover::before,
  .input-group-btn .btn[disabled]:focus,
  .input-group-btn .custom-file-control[disabled]:focus::before, fieldset[disabled][disabled]
  .btn-group, fieldset[disabled][disabled]
  .btn-group:hover, fieldset[disabled][disabled]
  .btn-group:focus,
  .btn-group.disabled,
  .btn-group.disabled:hover,
  .btn-group.disabled:focus,
  .btn-group:disabled,
  .btn-group:disabled:hover,
  .btn-group:disabled:focus,
  .btn-group[disabled],
  .btn-group[disabled]:hover,
  .btn-group[disabled]:focus, fieldset[disabled][disabled]
  .btn-group-vertical, fieldset[disabled][disabled]
  .btn-group-vertical:hover, fieldset[disabled][disabled]
  .btn-group-vertical:focus,
  .btn-group-vertical.disabled,
  .btn-group-vertical.disabled:hover,
  .btn-group-vertical.disabled:focus,
  .btn-group-vertical:disabled,
  .btn-group-vertical:disabled:hover,
  .btn-group-vertical:disabled:focus,
  .btn-group-vertical[disabled],
  .btn-group-vertical[disabled]:hover,
  .btn-group-vertical[disabled]:focus {
    background: transparent; }

.btn-group,
.btn-group-vertical {
  position: relative;
  margin: 10px 1px; }
  .btn-group .dropdown-menu,
  .btn-group-vertical .dropdown-menu {
    border-radius: 0 0 0.25rem 0.25rem; }
  .btn-group.btn-group-raised,
  .btn-group-vertical.btn-group-raised {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .btn-group .btn + .btn, .btn-group .custom-file-control::before + .btn, .btn-group .btn + .custom-file-control::before, .btn-group .custom-file-control::before + .custom-file-control::before,
  .btn-group .btn,
  .btn-group .custom-file-control::before,
  .btn-group .btn:active,
  .btn-group .custom-file-control:active::before,
  .btn-group .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .custom-file-control::before + .btn,
  .btn-group-vertical .btn + .custom-file-control::before,
  .btn-group-vertical .custom-file-control::before + .custom-file-control::before,
  .btn-group-vertical .btn,
  .btn-group-vertical .custom-file-control::before,
  .btn-group-vertical .btn:active,
  .btn-group-vertical .custom-file-control:active::before,
  .btn-group-vertical .btn-group {
    margin: 0; }
  .btn-group > .btn-group,
  .btn-group-vertical > .btn-group {
    margin: 0; }

.checkbox label,
label.checkbox-inline {
  position: relative;
  padding-left: 1.5625rem; }
  .checkbox label .checkbox-decorator,
  label.checkbox-inline .checkbox-decorator {
    position: absolute;
    left: 0;
    padding: .7em;
    margin: -.7em;
    line-height: .7;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 100%; }
    .checkbox label .checkbox-decorator .check,
    label.checkbox-inline .checkbox-decorator .check {
      position: relative;
      z-index: 1;
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      overflow: hidden;
      border: 0.125rem solid rgba(0, 0, 0, 0.54);
      border-radius: 0.25rem; }
      .checkbox label .checkbox-decorator .check::before,
      label.checkbox-inline .checkbox-decorator .check::before {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        margin-top: -4px;
        margin-left: 6px;
        color: #757575;
        content: "";
        box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
        transform: rotate(45deg);
        animation: checkbox-off; }
        .is-focused .checkbox label .checkbox-decorator .check::before, .is-focused
        label.checkbox-inline .checkbox-decorator .check::before {
          animation: checkbox-off 0.3s forwards; }
  .checkbox label input[type=checkbox],
  label.checkbox-inline input[type=checkbox] {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 0;
    height: 0;
    margin: 0;
    overflow: hidden;
    pointer-events: none;
    opacity: 0; }
    .checkbox label input[type=checkbox]:focus + .checkbox-decorator .check::after,
    label.checkbox-inline input[type=checkbox]:focus + .checkbox-decorator .check::after {
      opacity: 0.2; }
    .checkbox label input[type=checkbox]:checked + .checkbox-decorator .check,
    label.checkbox-inline input[type=checkbox]:checked + .checkbox-decorator .check {
      color: #007fc0;
      border-color: #007fc0; }
    .checkbox label input[type=checkbox]:checked + .checkbox-decorator .check::before,
    label.checkbox-inline input[type=checkbox]:checked + .checkbox-decorator .check::before {
      color: #007fc0;
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 032px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
      animation: checkbox-on 0.3s forwards; }
    .checkbox label input[type=checkbox][disabled] + .checkbox-decorator .check::after,
    .checkbox label input[type=checkbox][disabled] .check::after,
    fieldset[disabled] .checkbox label input[type=checkbox] + .checkbox-decorator .check::after,
    fieldset[disabled] .checkbox label input[type=checkbox] .check::after,
    label.checkbox-inline input[type=checkbox][disabled] + .checkbox-decorator .check::after,
    label.checkbox-inline input[type=checkbox][disabled] .check::after,
    fieldset[disabled]
    label.checkbox-inline input[type=checkbox] + .checkbox-decorator .check::after,
    fieldset[disabled]
    label.checkbox-inline input[type=checkbox] .check::after {
      background-color: rgba(0, 0, 0, 0.87);
      transform: rotate(-45deg); }
    .checkbox label input[type=checkbox][disabled] + .checkbox-decorator .check,
    .checkbox label input[type=checkbox][disabled] .check,
    fieldset[disabled] .checkbox label input[type=checkbox] + .checkbox-decorator .check,
    fieldset[disabled] .checkbox label input[type=checkbox] .check,
    label.checkbox-inline input[type=checkbox][disabled] + .checkbox-decorator .check,
    label.checkbox-inline input[type=checkbox][disabled] .check,
    fieldset[disabled]
    label.checkbox-inline input[type=checkbox] + .checkbox-decorator .check,
    fieldset[disabled]
    label.checkbox-inline input[type=checkbox] .check {
      border-color: rgba(0, 0, 0, 0.26); }
    .checkbox label input[type=checkbox][disabled] + .checkbox-decorator .ripple-container,
    fieldset[disabled] .checkbox label input[type=checkbox] + .checkbox-decorator .ripple-container,
    label.checkbox-inline input[type=checkbox][disabled] + .checkbox-decorator .ripple-container,
    fieldset[disabled]
    label.checkbox-inline input[type=checkbox] + .checkbox-decorator .ripple-container {
      display: none; }

@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px; }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px; }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px; } }

@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset; }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset; }
  50% {
    width: 0;
    height: 0;
    margin-top: -4px;
    margin-left: 6px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
    transform: rotate(45deg); }
  51% {
    width: 20px;
    height: 20px;
    margin-top: -2px;
    margin-left: -2px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset;
    transform: rotate(0deg); }
  100% {
    width: 20px;
    height: 20px;
    margin-top: -2px;
    margin-left: -2px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    transform: rotate(0deg); } }

.custom-file-control [class^='bmd-label'],
.custom-file-control [class*=' bmd-label'] {
  color: rgba(0, 0, 0, 0.26); }

.custom-file-control .form-control, .custom-file-control .custom-file-control,
.is-focused .custom-file-control .form-control,
.is-focused .custom-file-control .custom-file-control {
  background-image: linear-gradient(to top, #007fc0 2px, rgba(0, 127, 192, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.custom-file-control .form-control:invalid, .custom-file-control .custom-file-control:invalid {
  background-image: linear-gradient(to top, #d50000 2px, rgba(213, 0, 0, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.custom-file-control .form-control:read-only, .custom-file-control .custom-file-control:read-only {
  background-image: linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

fieldset[disabled][disabled] .custom-file-control .form-control, fieldset[disabled][disabled] .custom-file-control .custom-file-control, .custom-file-control .form-control.disabled, .custom-file-control .disabled.custom-file-control, .custom-file-control .form-control:disabled, .custom-file-control .custom-file-control:disabled, .custom-file-control .form-control[disabled], .custom-file-control .custom-file-control[disabled] {
  background-image: linear-gradient(to right, #ced4da 0%, #ced4da 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px; }

.custom-file-control .form-control.form-control-success, .custom-file-control .form-control-success.custom-file-control,
.is-focused .custom-file-control .form-control.form-control-success,
.is-focused .custom-file-control .form-control-success.custom-file-control {
  background-image: linear-gradient(to top, #007fc0 2px, rgba(0, 127, 192, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="; }

.custom-file-control .form-control.form-control-warning, .custom-file-control .form-control-warning.custom-file-control,
.is-focused .custom-file-control .form-control.form-control-warning,
.is-focused .custom-file-control .form-control-warning.custom-file-control {
  background-image: linear-gradient(to top, #007fc0 2px, rgba(0, 127, 192, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"; }

.custom-file-control .form-control.form-control-danger, .custom-file-control .form-control-danger.custom-file-control,
.is-focused .custom-file-control .form-control.form-control-danger,
.is-focused .custom-file-control .form-control-danger.custom-file-control {
  background-image: linear-gradient(to top, #007fc0 2px, rgba(0, 127, 192, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="; }

.custom-file-control .is-focused .valid-feedback, .custom-file-control.is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: rgba(0, 0, 0, 0.26); }

.custom-file-control .is-focused .valid-tooltip, .custom-file-control.is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0.25rem; }

.was-validated .custom-file-control .is-focused .form-control:valid, .was-validated .custom-file-control .is-focused .custom-file-control:valid, .custom-file-control .is-focused .form-control.is-valid, .custom-file-control .is-focused .is-valid.custom-file-control, .was-validated .custom-file-control.is-focused .form-control:valid, .was-validated .custom-file-control.is-focused .custom-file-control:valid, .custom-file-control.is-focused .form-control.is-valid, .custom-file-control.is-focused .is-valid.custom-file-control {
  border-color: rgba(0, 0, 0, 0.26);
  padding-right: calc(1.5em + 0.75rem);
  background-image: "none";
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-file-control .is-focused .form-control:valid:focus, .was-validated .custom-file-control .is-focused .custom-file-control:valid:focus, .custom-file-control .is-focused .form-control.is-valid:focus, .custom-file-control .is-focused .is-valid.custom-file-control:focus, .was-validated .custom-file-control.is-focused .form-control:valid:focus, .was-validated .custom-file-control.is-focused .custom-file-control:valid:focus, .custom-file-control.is-focused .form-control.is-valid:focus, .custom-file-control.is-focused .is-valid.custom-file-control:focus {
    border-color: rgba(0, 0, 0, 0.26);
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
  .was-validated .custom-file-control .is-focused .form-control:valid ~ .valid-feedback, .was-validated .custom-file-control .is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .custom-file-control .is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .custom-file-control .is-focused .custom-file-control:valid ~ .valid-tooltip, .custom-file-control .is-focused .form-control.is-valid ~ .valid-feedback, .custom-file-control .is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .custom-file-control .is-focused .form-control.is-valid ~ .valid-tooltip,
  .custom-file-control .is-focused .is-valid.custom-file-control ~ .valid-tooltip, .was-validated .custom-file-control.is-focused .form-control:valid ~ .valid-feedback, .was-validated .custom-file-control.is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .custom-file-control.is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .custom-file-control.is-focused .custom-file-control:valid ~ .valid-tooltip, .custom-file-control.is-focused .form-control.is-valid ~ .valid-feedback, .custom-file-control.is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .custom-file-control.is-focused .form-control.is-valid ~ .valid-tooltip,
  .custom-file-control.is-focused .is-valid.custom-file-control ~ .valid-tooltip {
    display: block; }

.was-validated .custom-file-control .is-focused textarea.form-control:valid, .was-validated .custom-file-control .is-focused textarea.custom-file-control:valid, .custom-file-control .is-focused textarea.form-control.is-valid, .custom-file-control .is-focused textarea.is-valid.custom-file-control, .was-validated .custom-file-control.is-focused textarea.form-control:valid, .was-validated .custom-file-control.is-focused textarea.custom-file-control:valid, .custom-file-control.is-focused textarea.form-control.is-valid, .custom-file-control.is-focused textarea.is-valid.custom-file-control {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-file-control .is-focused .custom-select:valid, .custom-file-control .is-focused .custom-select.is-valid, .was-validated .custom-file-control.is-focused .custom-select:valid, .custom-file-control.is-focused .custom-select.is-valid {
  border-color: rgba(0, 0, 0, 0.26);
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, "none" #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-file-control .is-focused .custom-select:valid:focus, .custom-file-control .is-focused .custom-select.is-valid:focus, .was-validated .custom-file-control.is-focused .custom-select:valid:focus, .custom-file-control.is-focused .custom-select.is-valid:focus {
    border-color: rgba(0, 0, 0, 0.26);
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
  .was-validated .custom-file-control .is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-file-control .is-focused .custom-select:valid ~ .valid-tooltip, .custom-file-control .is-focused .custom-select.is-valid ~ .valid-feedback,
  .custom-file-control .is-focused .custom-select.is-valid ~ .valid-tooltip, .was-validated .custom-file-control.is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-file-control.is-focused .custom-select:valid ~ .valid-tooltip, .custom-file-control.is-focused .custom-select.is-valid ~ .valid-feedback,
  .custom-file-control.is-focused .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .custom-file-control .is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .custom-file-control .is-focused .form-control-file:valid ~ .valid-tooltip, .custom-file-control .is-focused .form-control-file.is-valid ~ .valid-feedback,
.custom-file-control .is-focused .form-control-file.is-valid ~ .valid-tooltip, .was-validated .custom-file-control.is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .custom-file-control.is-focused .form-control-file:valid ~ .valid-tooltip, .custom-file-control.is-focused .form-control-file.is-valid ~ .valid-feedback,
.custom-file-control.is-focused .form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-control .is-focused .form-check-input:valid ~ .form-check-label, .custom-file-control .is-focused .form-check-input.is-valid ~ .form-check-label, .was-validated .custom-file-control.is-focused .form-check-input:valid ~ .form-check-label, .custom-file-control.is-focused .form-check-input.is-valid ~ .form-check-label {
  color: rgba(0, 0, 0, 0.26); }

.was-validated .custom-file-control .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .custom-file-control .is-focused .form-check-input:valid ~ .valid-tooltip, .custom-file-control .is-focused .form-check-input.is-valid ~ .valid-feedback,
.custom-file-control .is-focused .form-check-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-control.is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .custom-file-control.is-focused .form-check-input:valid ~ .valid-tooltip, .custom-file-control.is-focused .form-check-input.is-valid ~ .valid-feedback,
.custom-file-control.is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-control .is-focused .custom-control-input:valid ~ .custom-control-label, .custom-file-control .is-focused .custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-file-control.is-focused .custom-control-input:valid ~ .custom-control-label, .custom-file-control.is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: rgba(0, 0, 0, 0.26); }
  .was-validated .custom-file-control .is-focused .custom-control-input:valid ~ .custom-control-label::before, .custom-file-control .is-focused .custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .custom-file-control.is-focused .custom-control-input:valid ~ .custom-control-label::before, .custom-file-control.is-focused .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: rgba(0, 0, 0, 0.26); }

.was-validated .custom-file-control .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-file-control .is-focused .custom-control-input:valid ~ .valid-tooltip, .custom-file-control .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.custom-file-control .is-focused .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-control.is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-file-control.is-focused .custom-control-input:valid ~ .valid-tooltip, .custom-file-control.is-focused .custom-control-input.is-valid ~ .valid-feedback,
.custom-file-control.is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-control .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-file-control .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-file-control.is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-file-control.is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: rgba(26, 26, 26, 0.26);
  background-color: rgba(26, 26, 26, 0.26); }

.was-validated .custom-file-control .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-file-control .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .custom-file-control.is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-file-control.is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }

.was-validated .custom-file-control .is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-file-control .is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-file-control.is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-file-control.is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(0, 0, 0, 0.26); }

.was-validated .custom-file-control .is-focused .custom-file-input:valid ~ .custom-file-label, .custom-file-control .is-focused .custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-control.is-focused .custom-file-input:valid ~ .custom-file-label, .custom-file-control.is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: rgba(0, 0, 0, 0.26); }

.was-validated .custom-file-control .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-control .is-focused .custom-file-input:valid ~ .valid-tooltip, .custom-file-control .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-control .is-focused .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-control.is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-control.is-focused .custom-file-input:valid ~ .valid-tooltip, .custom-file-control.is-focused .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-control.is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-control .is-focused .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-control .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-control.is-focused .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-control.is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: rgba(0, 0, 0, 0.26);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }

.custom-file-control .is-focused [class^='bmd-label'],
.custom-file-control .is-focused [class*=' bmd-label'], .custom-file-control.is-focused [class^='bmd-label'],
.custom-file-control.is-focused [class*=' bmd-label'] {
  color: #007fc0; }

.custom-file-control .is-focused .bmd-label-placeholder, .custom-file-control.is-focused .bmd-label-placeholder {
  color: rgba(0, 0, 0, 0.26); }

.custom-file-control .is-focused .form-control, .custom-file-control .is-focused .custom-file-control, .custom-file-control.is-focused .form-control, .custom-file-control.is-focused .custom-file-control {
  border-color: #ced4da; }

.custom-file-control .is-focused .bmd-help, .custom-file-control.is-focused .bmd-help {
  color: rgba(0, 0, 0, 0.54); }

.custom-file-control::before {
  position: absolute;
  height: calc(100% - 1px); }

.radio label,
label.radio-inline {
  position: relative;
  padding-left: 1.5625rem; }
  .radio label .bmd-radio,
  label.radio-inline .bmd-radio {
    position: absolute;
    left: 0;
    display: inline-block;
    padding: .7em;
    margin: -.7em;
    line-height: .7;
    border-radius: 100%; }
    .radio label .bmd-radio::after,
    label.radio-inline .bmd-radio::after {
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer;
      content: "";
      border: 0.125rem solid rgba(0, 0, 0, 0.54);
      border-radius: 50%;
      transition: border-color ease .28s;
      transition-duration: 0.2s; }
    .radio label .bmd-radio::before,
    label.radio-inline .bmd-radio::before {
      position: absolute;
      display: inline-block;
      width: 1.25rem;
      height: 1.25rem;
      content: "";
      background-color: #007fc0;
      border-radius: 50%;
      transition: transform ease .28s;
      transform: scale3d(0, 0, 0); }
  .radio label input[type=radio],
  label.radio-inline input[type=radio] {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 0;
    height: 0;
    margin: 0;
    overflow: hidden;
    pointer-events: none;
    opacity: 0; }
    .radio label input[type=radio]:checked ~ .bmd-radio::after,
    label.radio-inline input[type=radio]:checked ~ .bmd-radio::after {
      border-color: #007fc0; }
    .radio label input[type=radio]:checked ~ .bmd-radio::before,
    label.radio-inline input[type=radio]:checked ~ .bmd-radio::before {
      background-color: #007fc0; }
    .radio label input[type=radio]:checked ~ .bmd-radio::before,
    label.radio-inline input[type=radio]:checked ~ .bmd-radio::before {
      transform: scale3d(0.5, 0.5, 1); }
    .radio label input[type=radio][disabled] + .bmd-radio, .radio label input[type=radio]:disabled + .bmd-radio,
    fieldset[disabled] .radio label input[type=radio] + .bmd-radio,
    label.radio-inline input[type=radio][disabled] + .bmd-radio,
    label.radio-inline input[type=radio]:disabled + .bmd-radio,
    fieldset[disabled]
    label.radio-inline input[type=radio] + .bmd-radio {
      cursor: default; }
      .radio label input[type=radio][disabled] + .bmd-radio::after, .radio label input[type=radio]:disabled + .bmd-radio::after,
      fieldset[disabled] .radio label input[type=radio] + .bmd-radio::after,
      label.radio-inline input[type=radio][disabled] + .bmd-radio::after,
      label.radio-inline input[type=radio]:disabled + .bmd-radio::after,
      fieldset[disabled]
      label.radio-inline input[type=radio] + .bmd-radio::after {
        border-color: rgba(0, 0, 0, 0.26); }
      .radio label input[type=radio][disabled] + .bmd-radio::before, .radio label input[type=radio]:disabled + .bmd-radio::before,
      fieldset[disabled] .radio label input[type=radio] + .bmd-radio::before,
      label.radio-inline input[type=radio][disabled] + .bmd-radio::before,
      label.radio-inline input[type=radio]:disabled + .bmd-radio::before,
      fieldset[disabled]
      label.radio-inline input[type=radio] + .bmd-radio::before {
        background-color: rgba(0, 0, 0, 0.26); }
    .radio label input[type=radio][disabled] + .bmd-radio .ripple-container, .radio label input[type=radio]:disabled + .bmd-radio .ripple-container,
    fieldset[disabled] .radio label input[type=radio] + .bmd-radio .ripple-container,
    label.radio-inline input[type=radio][disabled] + .bmd-radio .ripple-container,
    label.radio-inline input[type=radio]:disabled + .bmd-radio .ripple-container,
    fieldset[disabled]
    label.radio-inline input[type=radio] + .bmd-radio .ripple-container {
      display: none; }
    .bg-inverse .radio label input[type=radio][disabled] + .bmd-radio::after, .bg-inverse .radio label input[type=radio]:disabled + .bmd-radio::after, .bg-inverse
    fieldset[disabled] .radio label input[type=radio] + .bmd-radio::after, .bg-inverse
    label.radio-inline input[type=radio][disabled] + .bmd-radio::after, .bg-inverse
    label.radio-inline input[type=radio]:disabled + .bmd-radio::after, .bg-inverse
    fieldset[disabled]
    label.radio-inline input[type=radio] + .bmd-radio::after {
      border-color: rgba(255, 255, 255, 0.3); }
    .bg-inverse .radio label input[type=radio][disabled] + .bmd-radio::before, .bg-inverse .radio label input[type=radio]:disabled + .bmd-radio::before, .bg-inverse
    fieldset[disabled] .radio label input[type=radio] + .bmd-radio::before, .bg-inverse
    label.radio-inline input[type=radio][disabled] + .bmd-radio::before, .bg-inverse
    label.radio-inline input[type=radio]:disabled + .bmd-radio::before, .bg-inverse
    fieldset[disabled]
    label.radio-inline input[type=radio] + .bmd-radio::before {
      background-color: rgba(255, 255, 255, 0.3); }

form {
  margin-bottom: 1.125rem; }
  .navbar form {
    margin-bottom: 0; }
    .navbar form .bmd-form-group {
      display: inline-block;
      padding-top: 0; }
    .navbar form .btn, .navbar form .custom-file-control::before {
      margin-bottom: 0; }

.form-control, .custom-file-control {
  background: no-repeat center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  border: 0;
  transition: background 0s ease-out;
  padding-left: 0;
  padding-right: 0; }
  .form-control:focus, .custom-file-control:focus,
  .bmd-form-group.is-focused .form-control,
  .bmd-form-group.is-focused .custom-file-control {
    background-size: 100% 100%, 100% 100%;
    transition-duration: 0.3s; }

.bmd-help {
  position: absolute;
  display: none;
  font-size: .8rem;
  font-weight: normal; }
  .bmd-form-group.is-focused .bmd-help {
    display: block; }
  .bmd-help:nth-of-type(2) {
    padding-top: 1rem; }
  .bmd-help + .bmd-help {
    position: relative;
    margin-bottom: 0; }

.radio label,
.is-focused .radio label,
.radio-inline,
.is-focused
.radio-inline,
.checkbox label,
.is-focused
.checkbox label,
.checkbox-inline,
.is-focused
.checkbox-inline,
.switch label,
.is-focused
.switch label {
  color: rgba(0, 0, 0, 0.26); }
  .radio label:hover, .radio label:focus, .radio label:active,
  .is-focused .radio label:hover,
  .is-focused .radio label:focus,
  .is-focused .radio label:active,
  .radio-inline:hover,
  .radio-inline:focus,
  .radio-inline:active,
  .is-focused
  .radio-inline:hover,
  .is-focused
  .radio-inline:focus,
  .is-focused
  .radio-inline:active,
  .checkbox label:hover,
  .checkbox label:focus,
  .checkbox label:active,
  .is-focused
  .checkbox label:hover,
  .is-focused
  .checkbox label:focus,
  .is-focused
  .checkbox label:active,
  .checkbox-inline:hover,
  .checkbox-inline:focus,
  .checkbox-inline:active,
  .is-focused
  .checkbox-inline:hover,
  .is-focused
  .checkbox-inline:focus,
  .is-focused
  .checkbox-inline:active,
  .switch label:hover,
  .switch label:focus,
  .switch label:active,
  .is-focused
  .switch label:hover,
  .is-focused
  .switch label:focus,
  .is-focused
  .switch label:active {
    color: rgba(0, 0, 0, 0.54); }
  .radio label label:has(input[type=radio][disabled]), .radio label label:has(input[type=radio][disabled]):hover, .radio label label:has(input[type=radio][disabled]):focus,
  .radio label label:has(input[type=checkbox][disabled]),
  .radio label label:has(input[type=checkbox][disabled]):hover,
  .radio label label:has(input[type=checkbox][disabled]):focus,
  fieldset[disabled] .radio label,
  fieldset[disabled] .radio label:hover,
  fieldset[disabled] .radio label:focus,
  .is-focused .radio label label:has(input[type=radio][disabled]),
  .is-focused .radio label label:has(input[type=radio][disabled]):hover,
  .is-focused .radio label label:has(input[type=radio][disabled]):focus,
  .is-focused .radio label label:has(input[type=checkbox][disabled]),
  .is-focused .radio label label:has(input[type=checkbox][disabled]):hover,
  .is-focused .radio label label:has(input[type=checkbox][disabled]):focus,
  fieldset[disabled]
  .is-focused .radio label,
  fieldset[disabled]
  .is-focused .radio label:hover,
  fieldset[disabled]
  .is-focused .radio label:focus,
  .radio-inline label:has(input[type=radio][disabled]),
  .radio-inline label:has(input[type=radio][disabled]):hover,
  .radio-inline label:has(input[type=radio][disabled]):focus,
  .radio-inline label:has(input[type=checkbox][disabled]),
  .radio-inline label:has(input[type=checkbox][disabled]):hover,
  .radio-inline label:has(input[type=checkbox][disabled]):focus,
  fieldset[disabled]
  .radio-inline,
  fieldset[disabled]
  .radio-inline:hover,
  fieldset[disabled]
  .radio-inline:focus,
  .is-focused
  .radio-inline label:has(input[type=radio][disabled]),
  .is-focused
  .radio-inline label:has(input[type=radio][disabled]):hover,
  .is-focused
  .radio-inline label:has(input[type=radio][disabled]):focus,
  .is-focused
  .radio-inline label:has(input[type=checkbox][disabled]),
  .is-focused
  .radio-inline label:has(input[type=checkbox][disabled]):hover,
  .is-focused
  .radio-inline label:has(input[type=checkbox][disabled]):focus,
  fieldset[disabled]
  .is-focused
  .radio-inline,
  fieldset[disabled]
  .is-focused
  .radio-inline:hover,
  fieldset[disabled]
  .is-focused
  .radio-inline:focus,
  .checkbox label label:has(input[type=radio][disabled]),
  .checkbox label label:has(input[type=radio][disabled]):hover,
  .checkbox label label:has(input[type=radio][disabled]):focus,
  .checkbox label label:has(input[type=checkbox][disabled]),
  .checkbox label label:has(input[type=checkbox][disabled]):hover,
  .checkbox label label:has(input[type=checkbox][disabled]):focus,
  fieldset[disabled]
  .checkbox label,
  fieldset[disabled]
  .checkbox label:hover,
  fieldset[disabled]
  .checkbox label:focus,
  .is-focused
  .checkbox label label:has(input[type=radio][disabled]),
  .is-focused
  .checkbox label label:has(input[type=radio][disabled]):hover,
  .is-focused
  .checkbox label label:has(input[type=radio][disabled]):focus,
  .is-focused
  .checkbox label label:has(input[type=checkbox][disabled]),
  .is-focused
  .checkbox label label:has(input[type=checkbox][disabled]):hover,
  .is-focused
  .checkbox label label:has(input[type=checkbox][disabled]):focus,
  fieldset[disabled]
  .is-focused
  .checkbox label,
  fieldset[disabled]
  .is-focused
  .checkbox label:hover,
  fieldset[disabled]
  .is-focused
  .checkbox label:focus,
  .checkbox-inline label:has(input[type=radio][disabled]),
  .checkbox-inline label:has(input[type=radio][disabled]):hover,
  .checkbox-inline label:has(input[type=radio][disabled]):focus,
  .checkbox-inline label:has(input[type=checkbox][disabled]),
  .checkbox-inline label:has(input[type=checkbox][disabled]):hover,
  .checkbox-inline label:has(input[type=checkbox][disabled]):focus,
  fieldset[disabled]
  .checkbox-inline,
  fieldset[disabled]
  .checkbox-inline:hover,
  fieldset[disabled]
  .checkbox-inline:focus,
  .is-focused
  .checkbox-inline label:has(input[type=radio][disabled]),
  .is-focused
  .checkbox-inline label:has(input[type=radio][disabled]):hover,
  .is-focused
  .checkbox-inline label:has(input[type=radio][disabled]):focus,
  .is-focused
  .checkbox-inline label:has(input[type=checkbox][disabled]),
  .is-focused
  .checkbox-inline label:has(input[type=checkbox][disabled]):hover,
  .is-focused
  .checkbox-inline label:has(input[type=checkbox][disabled]):focus,
  fieldset[disabled]
  .is-focused
  .checkbox-inline,
  fieldset[disabled]
  .is-focused
  .checkbox-inline:hover,
  fieldset[disabled]
  .is-focused
  .checkbox-inline:focus,
  .switch label label:has(input[type=radio][disabled]),
  .switch label label:has(input[type=radio][disabled]):hover,
  .switch label label:has(input[type=radio][disabled]):focus,
  .switch label label:has(input[type=checkbox][disabled]),
  .switch label label:has(input[type=checkbox][disabled]):hover,
  .switch label label:has(input[type=checkbox][disabled]):focus,
  fieldset[disabled]
  .switch label,
  fieldset[disabled]
  .switch label:hover,
  fieldset[disabled]
  .switch label:focus,
  .is-focused
  .switch label label:has(input[type=radio][disabled]),
  .is-focused
  .switch label label:has(input[type=radio][disabled]):hover,
  .is-focused
  .switch label label:has(input[type=radio][disabled]):focus,
  .is-focused
  .switch label label:has(input[type=checkbox][disabled]),
  .is-focused
  .switch label label:has(input[type=checkbox][disabled]):hover,
  .is-focused
  .switch label label:has(input[type=checkbox][disabled]):focus,
  fieldset[disabled]
  .is-focused
  .switch label,
  fieldset[disabled]
  .is-focused
  .switch label:hover,
  fieldset[disabled]
  .is-focused
  .switch label:focus {
    color: rgba(0, 0, 0, 0.26); }

[class^='bmd-label'],
[class*=' bmd-label'] {
  color: rgba(0, 0, 0, 0.26); }

.form-control, .custom-file-control,
.is-focused .form-control,
.is-focused .custom-file-control {
  background-image: linear-gradient(to top, #007fc0 2px, rgba(0, 127, 192, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.form-control:invalid, .custom-file-control:invalid {
  background-image: linear-gradient(to top, #d50000 2px, rgba(213, 0, 0, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.form-control:read-only, .custom-file-control:read-only {
  background-image: linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

fieldset[disabled][disabled] .form-control, fieldset[disabled][disabled] .custom-file-control, .form-control.disabled, .disabled.custom-file-control, .form-control:disabled, .custom-file-control:disabled, .form-control[disabled], .custom-file-control[disabled] {
  background-image: linear-gradient(to right, #ced4da 0%, #ced4da 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px; }

.form-control.form-control-success, .form-control-success.custom-file-control,
.is-focused .form-control.form-control-success,
.is-focused .form-control-success.custom-file-control {
  background-image: linear-gradient(to top, #007fc0 2px, rgba(0, 127, 192, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="; }

.form-control.form-control-warning, .form-control-warning.custom-file-control,
.is-focused .form-control.form-control-warning,
.is-focused .form-control-warning.custom-file-control {
  background-image: linear-gradient(to top, #007fc0 2px, rgba(0, 127, 192, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"; }

.form-control.form-control-danger, .form-control-danger.custom-file-control,
.is-focused .form-control.form-control-danger,
.is-focused .form-control-danger.custom-file-control {
  background-image: linear-gradient(to top, #007fc0 2px, rgba(0, 127, 192, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="; }

.is-focused .valid-feedback,
.is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: rgba(0, 0, 0, 0.26); }

.is-focused .valid-tooltip,
.is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0.25rem; }

.was-validated .is-focused .form-control:valid, .was-validated .is-focused .custom-file-control:valid, .is-focused .form-control.is-valid, .is-focused .is-valid.custom-file-control, .was-validated .is-focused .form-control:valid, .was-validated .is-focused .custom-file-control:valid, .is-focused .form-control.is-valid, .is-focused .is-valid.custom-file-control {
  border-color: rgba(0, 0, 0, 0.26);
  padding-right: calc(1.5em + 0.75rem);
  background-image: "none";
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .is-focused .form-control:valid:focus, .was-validated .is-focused .custom-file-control:valid:focus, .is-focused .form-control.is-valid:focus, .is-focused .is-valid.custom-file-control:focus, .was-validated .is-focused .form-control:valid:focus, .was-validated .is-focused .custom-file-control:valid:focus, .is-focused .form-control.is-valid:focus, .is-focused .is-valid.custom-file-control:focus {
    border-color: rgba(0, 0, 0, 0.26);
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
  .was-validated .is-focused .form-control:valid ~ .valid-feedback, .was-validated .is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .is-focused .custom-file-control:valid ~ .valid-tooltip, .is-focused .form-control.is-valid ~ .valid-feedback, .is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .is-focused .form-control.is-valid ~ .valid-tooltip,
  .is-focused .is-valid.custom-file-control ~ .valid-tooltip, .was-validated .is-focused .form-control:valid ~ .valid-feedback, .was-validated .is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .is-focused .custom-file-control:valid ~ .valid-tooltip, .is-focused .form-control.is-valid ~ .valid-feedback, .is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .is-focused .form-control.is-valid ~ .valid-tooltip,
  .is-focused .is-valid.custom-file-control ~ .valid-tooltip {
    display: block; }

.was-validated .is-focused textarea.form-control:valid, .was-validated .is-focused textarea.custom-file-control:valid, .is-focused textarea.form-control.is-valid, .is-focused textarea.is-valid.custom-file-control, .was-validated .is-focused textarea.form-control:valid, .was-validated .is-focused textarea.custom-file-control:valid, .is-focused textarea.form-control.is-valid, .is-focused textarea.is-valid.custom-file-control {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .is-focused .custom-select:valid, .is-focused .custom-select.is-valid, .was-validated
.is-focused .custom-select:valid,
.is-focused .custom-select.is-valid {
  border-color: rgba(0, 0, 0, 0.26);
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, "none" #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .is-focused .custom-select:valid:focus, .is-focused .custom-select.is-valid:focus, .was-validated
  .is-focused .custom-select:valid:focus,
  .is-focused .custom-select.is-valid:focus {
    border-color: rgba(0, 0, 0, 0.26);
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }
  .was-validated .is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .is-focused .custom-select:valid ~ .valid-tooltip, .is-focused .custom-select.is-valid ~ .valid-feedback,
  .is-focused .custom-select.is-valid ~ .valid-tooltip, .was-validated
  .is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated
  .is-focused .custom-select:valid ~ .valid-tooltip,
  .is-focused .custom-select.is-valid ~ .valid-feedback,
  .is-focused .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .is-focused .form-control-file:valid ~ .valid-tooltip, .is-focused .form-control-file.is-valid ~ .valid-feedback,
.is-focused .form-control-file.is-valid ~ .valid-tooltip, .was-validated
.is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated
.is-focused .form-control-file:valid ~ .valid-tooltip,
.is-focused .form-control-file.is-valid ~ .valid-feedback,
.is-focused .form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .is-focused .form-check-input:valid ~ .form-check-label, .is-focused .form-check-input.is-valid ~ .form-check-label, .was-validated
.is-focused .form-check-input:valid ~ .form-check-label,
.is-focused .form-check-input.is-valid ~ .form-check-label {
  color: rgba(0, 0, 0, 0.26); }

.was-validated .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .is-focused .form-check-input:valid ~ .valid-tooltip, .is-focused .form-check-input.is-valid ~ .valid-feedback,
.is-focused .form-check-input.is-valid ~ .valid-tooltip, .was-validated
.is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated
.is-focused .form-check-input:valid ~ .valid-tooltip,
.is-focused .form-check-input.is-valid ~ .valid-feedback,
.is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .is-focused .custom-control-input:valid ~ .custom-control-label, .is-focused .custom-control-input.is-valid ~ .custom-control-label, .was-validated
.is-focused .custom-control-input:valid ~ .custom-control-label,
.is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: rgba(0, 0, 0, 0.26); }
  .was-validated .is-focused .custom-control-input:valid ~ .custom-control-label::before, .is-focused .custom-control-input.is-valid ~ .custom-control-label::before, .was-validated
  .is-focused .custom-control-input:valid ~ .custom-control-label::before,
  .is-focused .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: rgba(0, 0, 0, 0.26); }

.was-validated .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .is-focused .custom-control-input:valid ~ .valid-tooltip, .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.is-focused .custom-control-input.is-valid ~ .valid-tooltip, .was-validated
.is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated
.is-focused .custom-control-input:valid ~ .valid-tooltip,
.is-focused .custom-control-input.is-valid ~ .valid-feedback,
.is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated
.is-focused .custom-control-input:valid:checked ~ .custom-control-label::before,
.is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: rgba(26, 26, 26, 0.26);
  background-color: rgba(26, 26, 26, 0.26); }

.was-validated .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated
.is-focused .custom-control-input:valid:focus ~ .custom-control-label::before,
.is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }

.was-validated .is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated
.is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(0, 0, 0, 0.26); }

.was-validated .is-focused .custom-file-input:valid ~ .custom-file-label, .is-focused .custom-file-input.is-valid ~ .custom-file-label, .was-validated
.is-focused .custom-file-input:valid ~ .custom-file-label,
.is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: rgba(0, 0, 0, 0.26); }

.was-validated .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .is-focused .custom-file-input:valid ~ .valid-tooltip, .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.is-focused .custom-file-input.is-valid ~ .valid-tooltip, .was-validated
.is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated
.is-focused .custom-file-input:valid ~ .valid-tooltip,
.is-focused .custom-file-input.is-valid ~ .valid-feedback,
.is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .is-focused .custom-file-input:valid:focus ~ .custom-file-label, .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated
.is-focused .custom-file-input:valid:focus ~ .custom-file-label,
.is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: rgba(0, 0, 0, 0.26);
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25); }

.is-focused [class^='bmd-label'],
.is-focused [class*=' bmd-label'],
.is-focused [class^='bmd-label'],
.is-focused [class*=' bmd-label'] {
  color: #007fc0; }

.is-focused .bmd-label-placeholder,
.is-focused .bmd-label-placeholder {
  color: rgba(0, 0, 0, 0.26); }


.is-focused .form-control,
.is-focused .custom-file-control,
.is-focused .form-control,
.is-focused .custom-file-control {
  border-color: #ced4da; }

.is-focused .bmd-help,
.is-focused .bmd-help {
  color: rgba(0, 0, 0, 0.54); }

.has-success [class^='bmd-label'],
.has-success [class*=' bmd-label'] {
  color: #28a745; }

.has-success .form-control, .has-success .custom-file-control,
.is-focused .has-success .form-control,
.is-focused .has-success .custom-file-control {
  background-image: linear-gradient(to top, #28a745 2px, rgba(40, 167, 69, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.has-success .form-control:invalid, .has-success .custom-file-control:invalid {
  background-image: linear-gradient(to top, #d50000 2px, rgba(213, 0, 0, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.has-success .form-control:read-only, .has-success .custom-file-control:read-only {
  background-image: linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

fieldset[disabled][disabled] .has-success .form-control, fieldset[disabled][disabled] .has-success .custom-file-control, .has-success .form-control.disabled, .has-success .disabled.custom-file-control, .has-success .form-control:disabled, .has-success .custom-file-control:disabled, .has-success .form-control[disabled], .has-success .custom-file-control[disabled] {
  background-image: linear-gradient(to right, #ced4da 0%, #ced4da 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px; }

.has-success .form-control.form-control-success, .has-success .form-control-success.custom-file-control,
.is-focused .has-success .form-control.form-control-success,
.is-focused .has-success .form-control-success.custom-file-control {
  background-image: linear-gradient(to top, #28a745 2px, rgba(40, 167, 69, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="; }

.has-success .form-control.form-control-warning, .has-success .form-control-warning.custom-file-control,
.is-focused .has-success .form-control.form-control-warning,
.is-focused .has-success .form-control-warning.custom-file-control {
  background-image: linear-gradient(to top, #28a745 2px, rgba(40, 167, 69, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"; }

.has-success .form-control.form-control-danger, .has-success .form-control-danger.custom-file-control,
.is-focused .has-success .form-control.form-control-danger,
.is-focused .has-success .form-control-danger.custom-file-control {
  background-image: linear-gradient(to top, #28a745 2px, rgba(40, 167, 69, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="; }

.has-success .is-focused .valid-feedback, .has-success.is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.has-success .is-focused .valid-tooltip, .has-success.is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .has-success .is-focused .form-control:valid, .was-validated .has-success .is-focused .custom-file-control:valid, .has-success .is-focused .form-control.is-valid, .has-success .is-focused .is-valid.custom-file-control, .was-validated .has-success.is-focused .form-control:valid, .was-validated .has-success.is-focused .custom-file-control:valid, .has-success.is-focused .form-control.is-valid, .has-success.is-focused .is-valid.custom-file-control {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: "none";
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .has-success .is-focused .form-control:valid:focus, .was-validated .has-success .is-focused .custom-file-control:valid:focus, .has-success .is-focused .form-control.is-valid:focus, .has-success .is-focused .is-valid.custom-file-control:focus, .was-validated .has-success.is-focused .form-control:valid:focus, .was-validated .has-success.is-focused .custom-file-control:valid:focus, .has-success.is-focused .form-control.is-valid:focus, .has-success.is-focused .is-valid.custom-file-control:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .has-success .is-focused .form-control:valid ~ .valid-feedback, .was-validated .has-success .is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .has-success .is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .has-success .is-focused .custom-file-control:valid ~ .valid-tooltip, .has-success .is-focused .form-control.is-valid ~ .valid-feedback, .has-success .is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .has-success .is-focused .form-control.is-valid ~ .valid-tooltip,
  .has-success .is-focused .is-valid.custom-file-control ~ .valid-tooltip, .was-validated .has-success.is-focused .form-control:valid ~ .valid-feedback, .was-validated .has-success.is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .has-success.is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .has-success.is-focused .custom-file-control:valid ~ .valid-tooltip, .has-success.is-focused .form-control.is-valid ~ .valid-feedback, .has-success.is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .has-success.is-focused .form-control.is-valid ~ .valid-tooltip,
  .has-success.is-focused .is-valid.custom-file-control ~ .valid-tooltip {
    display: block; }

.was-validated .has-success .is-focused textarea.form-control:valid, .was-validated .has-success .is-focused textarea.custom-file-control:valid, .has-success .is-focused textarea.form-control.is-valid, .has-success .is-focused textarea.is-valid.custom-file-control, .was-validated .has-success.is-focused textarea.form-control:valid, .was-validated .has-success.is-focused textarea.custom-file-control:valid, .has-success.is-focused textarea.form-control.is-valid, .has-success.is-focused textarea.is-valid.custom-file-control {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .has-success .is-focused .custom-select:valid, .has-success .is-focused .custom-select.is-valid, .was-validated .has-success.is-focused .custom-select:valid, .has-success.is-focused .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, "none" #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .has-success .is-focused .custom-select:valid:focus, .has-success .is-focused .custom-select.is-valid:focus, .was-validated .has-success.is-focused .custom-select:valid:focus, .has-success.is-focused .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .has-success .is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .has-success .is-focused .custom-select:valid ~ .valid-tooltip, .has-success .is-focused .custom-select.is-valid ~ .valid-feedback,
  .has-success .is-focused .custom-select.is-valid ~ .valid-tooltip, .was-validated .has-success.is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .has-success.is-focused .custom-select:valid ~ .valid-tooltip, .has-success.is-focused .custom-select.is-valid ~ .valid-feedback,
  .has-success.is-focused .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .has-success .is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .form-control-file:valid ~ .valid-tooltip, .has-success .is-focused .form-control-file.is-valid ~ .valid-feedback,
.has-success .is-focused .form-control-file.is-valid ~ .valid-tooltip, .was-validated .has-success.is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .has-success.is-focused .form-control-file:valid ~ .valid-tooltip, .has-success.is-focused .form-control-file.is-valid ~ .valid-feedback,
.has-success.is-focused .form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-success .is-focused .form-check-input:valid ~ .form-check-label, .has-success .is-focused .form-check-input.is-valid ~ .form-check-label, .was-validated .has-success.is-focused .form-check-input:valid ~ .form-check-label, .has-success.is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .has-success .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .form-check-input:valid ~ .valid-tooltip, .has-success .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-success .is-focused .form-check-input.is-valid ~ .valid-tooltip, .was-validated .has-success.is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-success.is-focused .form-check-input:valid ~ .valid-tooltip, .has-success.is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-success.is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-success .is-focused .custom-control-input:valid ~ .custom-control-label, .has-success .is-focused .custom-control-input.is-valid ~ .custom-control-label, .was-validated .has-success.is-focused .custom-control-input:valid ~ .custom-control-label, .has-success.is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .has-success .is-focused .custom-control-input:valid ~ .custom-control-label::before, .has-success .is-focused .custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .has-success.is-focused .custom-control-input:valid ~ .custom-control-label::before, .has-success.is-focused .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .has-success .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .custom-control-input:valid ~ .valid-tooltip, .has-success .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-success .is-focused .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .has-success.is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-success.is-focused .custom-control-input:valid ~ .valid-tooltip, .has-success.is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-success.is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-success .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .has-success .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .has-success.is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .has-success.is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .has-success .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .has-success .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .has-success.is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .has-success.is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .has-success .is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .has-success .is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .has-success.is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .has-success.is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .has-success .is-focused .custom-file-input:valid ~ .custom-file-label, .has-success .is-focused .custom-file-input.is-valid ~ .custom-file-label, .was-validated .has-success.is-focused .custom-file-input:valid ~ .custom-file-label, .has-success.is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .has-success .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-success .is-focused .custom-file-input:valid ~ .valid-tooltip, .has-success .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-success .is-focused .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .has-success.is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-success.is-focused .custom-file-input:valid ~ .valid-tooltip, .has-success.is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-success.is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-success .is-focused .custom-file-input:valid:focus ~ .custom-file-label, .has-success .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .has-success.is-focused .custom-file-input:valid:focus ~ .custom-file-label, .has-success.is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.has-success .is-focused [class^='bmd-label'],
.has-success .is-focused [class*=' bmd-label'], .has-success.is-focused [class^='bmd-label'],
.has-success.is-focused [class*=' bmd-label'] {
  color: #28a745; }

.has-success .is-focused .bmd-label-placeholder, .has-success.is-focused .bmd-label-placeholder {
  color: #28a745; }

.has-success .is-focused .form-control, .has-success .is-focused .custom-file-control, .has-success.is-focused .form-control, .has-success.is-focused .custom-file-control {
  border-color: #28a745; }

.has-success .is-focused .bmd-help, .has-success.is-focused .bmd-help {
  color: rgba(0, 0, 0, 0.54); }

.has-info [class^='bmd-label'],
.has-info [class*=' bmd-label'] {
  color: #17a2b8; }

.has-info .form-control, .has-info .custom-file-control,
.is-focused .has-info .form-control,
.is-focused .has-info .custom-file-control {
  background-image: linear-gradient(to top, #17a2b8 2px, rgba(23, 162, 184, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.has-info .form-control:invalid, .has-info .custom-file-control:invalid {
  background-image: linear-gradient(to top, #d50000 2px, rgba(213, 0, 0, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.has-info .form-control:read-only, .has-info .custom-file-control:read-only {
  background-image: linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

fieldset[disabled][disabled] .has-info .form-control, fieldset[disabled][disabled] .has-info .custom-file-control, .has-info .form-control.disabled, .has-info .disabled.custom-file-control, .has-info .form-control:disabled, .has-info .custom-file-control:disabled, .has-info .form-control[disabled], .has-info .custom-file-control[disabled] {
  background-image: linear-gradient(to right, #ced4da 0%, #ced4da 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px; }

.has-info .form-control.form-control-success, .has-info .form-control-success.custom-file-control,
.is-focused .has-info .form-control.form-control-success,
.is-focused .has-info .form-control-success.custom-file-control {
  background-image: linear-gradient(to top, #17a2b8 2px, rgba(23, 162, 184, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="; }

.has-info .form-control.form-control-warning, .has-info .form-control-warning.custom-file-control,
.is-focused .has-info .form-control.form-control-warning,
.is-focused .has-info .form-control-warning.custom-file-control {
  background-image: linear-gradient(to top, #17a2b8 2px, rgba(23, 162, 184, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"; }

.has-info .form-control.form-control-danger, .has-info .form-control-danger.custom-file-control,
.is-focused .has-info .form-control.form-control-danger,
.is-focused .has-info .form-control-danger.custom-file-control {
  background-image: linear-gradient(to top, #17a2b8 2px, rgba(23, 162, 184, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="; }

.has-info .is-focused .valid-feedback, .has-info.is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #17a2b8; }

.has-info .is-focused .valid-tooltip, .has-info.is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(23, 162, 184, 0.9);
  border-radius: 0.25rem; }

.was-validated .has-info .is-focused .form-control:valid, .was-validated .has-info .is-focused .custom-file-control:valid, .has-info .is-focused .form-control.is-valid, .has-info .is-focused .is-valid.custom-file-control, .was-validated .has-info.is-focused .form-control:valid, .was-validated .has-info.is-focused .custom-file-control:valid, .has-info.is-focused .form-control.is-valid, .has-info.is-focused .is-valid.custom-file-control {
  border-color: #17a2b8;
  padding-right: calc(1.5em + 0.75rem);
  background-image: "none";
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .has-info .is-focused .form-control:valid:focus, .was-validated .has-info .is-focused .custom-file-control:valid:focus, .has-info .is-focused .form-control.is-valid:focus, .has-info .is-focused .is-valid.custom-file-control:focus, .was-validated .has-info.is-focused .form-control:valid:focus, .was-validated .has-info.is-focused .custom-file-control:valid:focus, .has-info.is-focused .form-control.is-valid:focus, .has-info.is-focused .is-valid.custom-file-control:focus {
    border-color: #17a2b8;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25); }
  .was-validated .has-info .is-focused .form-control:valid ~ .valid-feedback, .was-validated .has-info .is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .has-info .is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .has-info .is-focused .custom-file-control:valid ~ .valid-tooltip, .has-info .is-focused .form-control.is-valid ~ .valid-feedback, .has-info .is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .has-info .is-focused .form-control.is-valid ~ .valid-tooltip,
  .has-info .is-focused .is-valid.custom-file-control ~ .valid-tooltip, .was-validated .has-info.is-focused .form-control:valid ~ .valid-feedback, .was-validated .has-info.is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .has-info.is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .has-info.is-focused .custom-file-control:valid ~ .valid-tooltip, .has-info.is-focused .form-control.is-valid ~ .valid-feedback, .has-info.is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .has-info.is-focused .form-control.is-valid ~ .valid-tooltip,
  .has-info.is-focused .is-valid.custom-file-control ~ .valid-tooltip {
    display: block; }

.was-validated .has-info .is-focused textarea.form-control:valid, .was-validated .has-info .is-focused textarea.custom-file-control:valid, .has-info .is-focused textarea.form-control.is-valid, .has-info .is-focused textarea.is-valid.custom-file-control, .was-validated .has-info.is-focused textarea.form-control:valid, .was-validated .has-info.is-focused textarea.custom-file-control:valid, .has-info.is-focused textarea.form-control.is-valid, .has-info.is-focused textarea.is-valid.custom-file-control {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .has-info .is-focused .custom-select:valid, .has-info .is-focused .custom-select.is-valid, .was-validated .has-info.is-focused .custom-select:valid, .has-info.is-focused .custom-select.is-valid {
  border-color: #17a2b8;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, "none" #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .has-info .is-focused .custom-select:valid:focus, .has-info .is-focused .custom-select.is-valid:focus, .was-validated .has-info.is-focused .custom-select:valid:focus, .has-info.is-focused .custom-select.is-valid:focus {
    border-color: #17a2b8;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25); }
  .was-validated .has-info .is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .has-info .is-focused .custom-select:valid ~ .valid-tooltip, .has-info .is-focused .custom-select.is-valid ~ .valid-feedback,
  .has-info .is-focused .custom-select.is-valid ~ .valid-tooltip, .was-validated .has-info.is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .has-info.is-focused .custom-select:valid ~ .valid-tooltip, .has-info.is-focused .custom-select.is-valid ~ .valid-feedback,
  .has-info.is-focused .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .has-info .is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .form-control-file:valid ~ .valid-tooltip, .has-info .is-focused .form-control-file.is-valid ~ .valid-feedback,
.has-info .is-focused .form-control-file.is-valid ~ .valid-tooltip, .was-validated .has-info.is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .has-info.is-focused .form-control-file:valid ~ .valid-tooltip, .has-info.is-focused .form-control-file.is-valid ~ .valid-feedback,
.has-info.is-focused .form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-info .is-focused .form-check-input:valid ~ .form-check-label, .has-info .is-focused .form-check-input.is-valid ~ .form-check-label, .was-validated .has-info.is-focused .form-check-input:valid ~ .form-check-label, .has-info.is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #17a2b8; }

.was-validated .has-info .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .form-check-input:valid ~ .valid-tooltip, .has-info .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-info .is-focused .form-check-input.is-valid ~ .valid-tooltip, .was-validated .has-info.is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-info.is-focused .form-check-input:valid ~ .valid-tooltip, .has-info.is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-info.is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-info .is-focused .custom-control-input:valid ~ .custom-control-label, .has-info .is-focused .custom-control-input.is-valid ~ .custom-control-label, .was-validated .has-info.is-focused .custom-control-input:valid ~ .custom-control-label, .has-info.is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: #17a2b8; }
  .was-validated .has-info .is-focused .custom-control-input:valid ~ .custom-control-label::before, .has-info .is-focused .custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .has-info.is-focused .custom-control-input:valid ~ .custom-control-label::before, .has-info.is-focused .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #17a2b8; }

.was-validated .has-info .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .custom-control-input:valid ~ .valid-tooltip, .has-info .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-info .is-focused .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .has-info.is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-info.is-focused .custom-control-input:valid ~ .valid-tooltip, .has-info.is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-info.is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-info .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .has-info .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .has-info.is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .has-info.is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #1fc8e3;
  background-color: #1fc8e3; }

.was-validated .has-info .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .has-info .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .has-info.is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .has-info.is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25); }

.was-validated .has-info .is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .has-info .is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .has-info.is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .has-info.is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #17a2b8; }

.was-validated .has-info .is-focused .custom-file-input:valid ~ .custom-file-label, .has-info .is-focused .custom-file-input.is-valid ~ .custom-file-label, .was-validated .has-info.is-focused .custom-file-input:valid ~ .custom-file-label, .has-info.is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #17a2b8; }

.was-validated .has-info .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-info .is-focused .custom-file-input:valid ~ .valid-tooltip, .has-info .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-info .is-focused .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .has-info.is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-info.is-focused .custom-file-input:valid ~ .valid-tooltip, .has-info.is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-info.is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-info .is-focused .custom-file-input:valid:focus ~ .custom-file-label, .has-info .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .has-info.is-focused .custom-file-input:valid:focus ~ .custom-file-label, .has-info.is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #17a2b8;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25); }

.has-info .is-focused [class^='bmd-label'],
.has-info .is-focused [class*=' bmd-label'], .has-info.is-focused [class^='bmd-label'],
.has-info.is-focused [class*=' bmd-label'] {
  color: #17a2b8; }

.has-info .is-focused .bmd-label-placeholder, .has-info.is-focused .bmd-label-placeholder {
  color: #17a2b8; }

.has-info .is-focused .form-control, .has-info .is-focused .custom-file-control, .has-info.is-focused .form-control, .has-info.is-focused .custom-file-control {
  border-color: #17a2b8; }

.has-info .is-focused .bmd-help, .has-info.is-focused .bmd-help {
  color: rgba(0, 0, 0, 0.54); }

.has-warning [class^='bmd-label'],
.has-warning [class*=' bmd-label'] {
  color: #ffc107; }

.has-warning .form-control, .has-warning .custom-file-control,
.is-focused .has-warning .form-control,
.is-focused .has-warning .custom-file-control {
  background-image: linear-gradient(to top, #ffc107 2px, rgba(255, 193, 7, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.has-warning .form-control:invalid, .has-warning .custom-file-control:invalid {
  background-image: linear-gradient(to top, #d50000 2px, rgba(213, 0, 0, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.has-warning .form-control:read-only, .has-warning .custom-file-control:read-only {
  background-image: linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

fieldset[disabled][disabled] .has-warning .form-control, fieldset[disabled][disabled] .has-warning .custom-file-control, .has-warning .form-control.disabled, .has-warning .disabled.custom-file-control, .has-warning .form-control:disabled, .has-warning .custom-file-control:disabled, .has-warning .form-control[disabled], .has-warning .custom-file-control[disabled] {
  background-image: linear-gradient(to right, #ced4da 0%, #ced4da 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px; }

.has-warning .form-control.form-control-success, .has-warning .form-control-success.custom-file-control,
.is-focused .has-warning .form-control.form-control-success,
.is-focused .has-warning .form-control-success.custom-file-control {
  background-image: linear-gradient(to top, #ffc107 2px, rgba(255, 193, 7, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="; }

.has-warning .form-control.form-control-warning, .has-warning .form-control-warning.custom-file-control,
.is-focused .has-warning .form-control.form-control-warning,
.is-focused .has-warning .form-control-warning.custom-file-control {
  background-image: linear-gradient(to top, #ffc107 2px, rgba(255, 193, 7, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"; }

.has-warning .form-control.form-control-danger, .has-warning .form-control-danger.custom-file-control,
.is-focused .has-warning .form-control.form-control-danger,
.is-focused .has-warning .form-control-danger.custom-file-control {
  background-image: linear-gradient(to top, #ffc107 2px, rgba(255, 193, 7, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="; }

.has-warning .is-focused .valid-feedback, .has-warning.is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ffc107; }

.has-warning .is-focused .valid-tooltip, .has-warning.is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(255, 193, 7, 0.9);
  border-radius: 0.25rem; }

.was-validated .has-warning .is-focused .form-control:valid, .was-validated .has-warning .is-focused .custom-file-control:valid, .has-warning .is-focused .form-control.is-valid, .has-warning .is-focused .is-valid.custom-file-control, .was-validated .has-warning.is-focused .form-control:valid, .was-validated .has-warning.is-focused .custom-file-control:valid, .has-warning.is-focused .form-control.is-valid, .has-warning.is-focused .is-valid.custom-file-control {
  border-color: #ffc107;
  padding-right: calc(1.5em + 0.75rem);
  background-image: "none";
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .has-warning .is-focused .form-control:valid:focus, .was-validated .has-warning .is-focused .custom-file-control:valid:focus, .has-warning .is-focused .form-control.is-valid:focus, .has-warning .is-focused .is-valid.custom-file-control:focus, .was-validated .has-warning.is-focused .form-control:valid:focus, .was-validated .has-warning.is-focused .custom-file-control:valid:focus, .has-warning.is-focused .form-control.is-valid:focus, .has-warning.is-focused .is-valid.custom-file-control:focus {
    border-color: #ffc107;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25); }
  .was-validated .has-warning .is-focused .form-control:valid ~ .valid-feedback, .was-validated .has-warning .is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .has-warning .is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .has-warning .is-focused .custom-file-control:valid ~ .valid-tooltip, .has-warning .is-focused .form-control.is-valid ~ .valid-feedback, .has-warning .is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .has-warning .is-focused .form-control.is-valid ~ .valid-tooltip,
  .has-warning .is-focused .is-valid.custom-file-control ~ .valid-tooltip, .was-validated .has-warning.is-focused .form-control:valid ~ .valid-feedback, .was-validated .has-warning.is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .has-warning.is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .has-warning.is-focused .custom-file-control:valid ~ .valid-tooltip, .has-warning.is-focused .form-control.is-valid ~ .valid-feedback, .has-warning.is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .has-warning.is-focused .form-control.is-valid ~ .valid-tooltip,
  .has-warning.is-focused .is-valid.custom-file-control ~ .valid-tooltip {
    display: block; }

.was-validated .has-warning .is-focused textarea.form-control:valid, .was-validated .has-warning .is-focused textarea.custom-file-control:valid, .has-warning .is-focused textarea.form-control.is-valid, .has-warning .is-focused textarea.is-valid.custom-file-control, .was-validated .has-warning.is-focused textarea.form-control:valid, .was-validated .has-warning.is-focused textarea.custom-file-control:valid, .has-warning.is-focused textarea.form-control.is-valid, .has-warning.is-focused textarea.is-valid.custom-file-control {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .has-warning .is-focused .custom-select:valid, .has-warning .is-focused .custom-select.is-valid, .was-validated .has-warning.is-focused .custom-select:valid, .has-warning.is-focused .custom-select.is-valid {
  border-color: #ffc107;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, "none" #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .has-warning .is-focused .custom-select:valid:focus, .has-warning .is-focused .custom-select.is-valid:focus, .was-validated .has-warning.is-focused .custom-select:valid:focus, .has-warning.is-focused .custom-select.is-valid:focus {
    border-color: #ffc107;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25); }
  .was-validated .has-warning .is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .has-warning .is-focused .custom-select:valid ~ .valid-tooltip, .has-warning .is-focused .custom-select.is-valid ~ .valid-feedback,
  .has-warning .is-focused .custom-select.is-valid ~ .valid-tooltip, .was-validated .has-warning.is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .has-warning.is-focused .custom-select:valid ~ .valid-tooltip, .has-warning.is-focused .custom-select.is-valid ~ .valid-feedback,
  .has-warning.is-focused .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .has-warning .is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .form-control-file:valid ~ .valid-tooltip, .has-warning .is-focused .form-control-file.is-valid ~ .valid-feedback,
.has-warning .is-focused .form-control-file.is-valid ~ .valid-tooltip, .was-validated .has-warning.is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .has-warning.is-focused .form-control-file:valid ~ .valid-tooltip, .has-warning.is-focused .form-control-file.is-valid ~ .valid-feedback,
.has-warning.is-focused .form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-warning .is-focused .form-check-input:valid ~ .form-check-label, .has-warning .is-focused .form-check-input.is-valid ~ .form-check-label, .was-validated .has-warning.is-focused .form-check-input:valid ~ .form-check-label, .has-warning.is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #ffc107; }

.was-validated .has-warning .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .form-check-input:valid ~ .valid-tooltip, .has-warning .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-warning .is-focused .form-check-input.is-valid ~ .valid-tooltip, .was-validated .has-warning.is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-warning.is-focused .form-check-input:valid ~ .valid-tooltip, .has-warning.is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-warning.is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-warning .is-focused .custom-control-input:valid ~ .custom-control-label, .has-warning .is-focused .custom-control-input.is-valid ~ .custom-control-label, .was-validated .has-warning.is-focused .custom-control-input:valid ~ .custom-control-label, .has-warning.is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: #ffc107; }
  .was-validated .has-warning .is-focused .custom-control-input:valid ~ .custom-control-label::before, .has-warning .is-focused .custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .has-warning.is-focused .custom-control-input:valid ~ .custom-control-label::before, .has-warning.is-focused .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #ffc107; }

.was-validated .has-warning .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .custom-control-input:valid ~ .valid-tooltip, .has-warning .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-warning .is-focused .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .has-warning.is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-warning.is-focused .custom-control-input:valid ~ .valid-tooltip, .has-warning.is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-warning.is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-warning .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .has-warning .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .has-warning.is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .has-warning.is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #ffce3a;
  background-color: #ffce3a; }

.was-validated .has-warning .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .has-warning .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .has-warning.is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .has-warning.is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25); }

.was-validated .has-warning .is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .has-warning .is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .has-warning.is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .has-warning.is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ffc107; }

.was-validated .has-warning .is-focused .custom-file-input:valid ~ .custom-file-label, .has-warning .is-focused .custom-file-input.is-valid ~ .custom-file-label, .was-validated .has-warning.is-focused .custom-file-input:valid ~ .custom-file-label, .has-warning.is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #ffc107; }

.was-validated .has-warning .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-warning .is-focused .custom-file-input:valid ~ .valid-tooltip, .has-warning .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-warning .is-focused .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .has-warning.is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-warning.is-focused .custom-file-input:valid ~ .valid-tooltip, .has-warning.is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-warning.is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-warning .is-focused .custom-file-input:valid:focus ~ .custom-file-label, .has-warning .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .has-warning.is-focused .custom-file-input:valid:focus ~ .custom-file-label, .has-warning.is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #ffc107;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.25); }

.has-warning .is-focused [class^='bmd-label'],
.has-warning .is-focused [class*=' bmd-label'], .has-warning.is-focused [class^='bmd-label'],
.has-warning.is-focused [class*=' bmd-label'] {
  color: #ffc107; }

.has-warning .is-focused .bmd-label-placeholder, .has-warning.is-focused .bmd-label-placeholder {
  color: #ffc107; }

.has-warning .is-focused .form-control, .has-warning .is-focused .custom-file-control, .has-warning.is-focused .form-control, .has-warning.is-focused .custom-file-control {
  border-color: #ffc107; }

.has-warning .is-focused .bmd-help, .has-warning.is-focused .bmd-help {
  color: rgba(0, 0, 0, 0.54); }

.has-danger [class^='bmd-label'],
.has-danger [class*=' bmd-label'] {
  color: #dc3545; }

.has-danger .form-control, .has-danger .custom-file-control,
.is-focused .has-danger .form-control,
.is-focused .has-danger .custom-file-control {
  background-image: linear-gradient(to top, #dc3545 2px, rgba(220, 53, 69, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.has-danger .form-control:invalid, .has-danger .custom-file-control:invalid {
  background-image: linear-gradient(to top, #d50000 2px, rgba(213, 0, 0, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.has-danger .form-control:read-only, .has-danger .custom-file-control:read-only {
  background-image: linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

fieldset[disabled][disabled] .has-danger .form-control, fieldset[disabled][disabled] .has-danger .custom-file-control, .has-danger .form-control.disabled, .has-danger .disabled.custom-file-control, .has-danger .form-control:disabled, .has-danger .custom-file-control:disabled, .has-danger .form-control[disabled], .has-danger .custom-file-control[disabled] {
  background-image: linear-gradient(to right, #ced4da 0%, #ced4da 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px; }

.has-danger .form-control.form-control-success, .has-danger .form-control-success.custom-file-control,
.is-focused .has-danger .form-control.form-control-success,
.is-focused .has-danger .form-control-success.custom-file-control {
  background-image: linear-gradient(to top, #dc3545 2px, rgba(220, 53, 69, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg=="; }

.has-danger .form-control.form-control-warning, .has-danger .form-control-warning.custom-file-control,
.is-focused .has-danger .form-control.form-control-warning,
.is-focused .has-danger .form-control-warning.custom-file-control {
  background-image: linear-gradient(to top, #dc3545 2px, rgba(220, 53, 69, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+"; }

.has-danger .form-control.form-control-danger, .has-danger .form-control-danger.custom-file-control,
.is-focused .has-danger .form-control.form-control-danger,
.is-focused .has-danger .form-control-danger.custom-file-control {
  background-image: linear-gradient(to top, #dc3545 2px, rgba(220, 53, 69, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4="; }

.has-danger .is-focused .valid-feedback, .has-danger.is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.has-danger .is-focused .valid-tooltip, .has-danger.is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .has-danger .is-focused .form-control:valid, .was-validated .has-danger .is-focused .custom-file-control:valid, .has-danger .is-focused .form-control.is-valid, .has-danger .is-focused .is-valid.custom-file-control, .was-validated .has-danger.is-focused .form-control:valid, .was-validated .has-danger.is-focused .custom-file-control:valid, .has-danger.is-focused .form-control.is-valid, .has-danger.is-focused .is-valid.custom-file-control {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: "none";
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .has-danger .is-focused .form-control:valid:focus, .was-validated .has-danger .is-focused .custom-file-control:valid:focus, .has-danger .is-focused .form-control.is-valid:focus, .has-danger .is-focused .is-valid.custom-file-control:focus, .was-validated .has-danger.is-focused .form-control:valid:focus, .was-validated .has-danger.is-focused .custom-file-control:valid:focus, .has-danger.is-focused .form-control.is-valid:focus, .has-danger.is-focused .is-valid.custom-file-control:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .has-danger .is-focused .form-control:valid ~ .valid-feedback, .was-validated .has-danger .is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .has-danger .is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .has-danger .is-focused .custom-file-control:valid ~ .valid-tooltip, .has-danger .is-focused .form-control.is-valid ~ .valid-feedback, .has-danger .is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .has-danger .is-focused .form-control.is-valid ~ .valid-tooltip,
  .has-danger .is-focused .is-valid.custom-file-control ~ .valid-tooltip, .was-validated .has-danger.is-focused .form-control:valid ~ .valid-feedback, .was-validated .has-danger.is-focused .custom-file-control:valid ~ .valid-feedback,
  .was-validated .has-danger.is-focused .form-control:valid ~ .valid-tooltip,
  .was-validated .has-danger.is-focused .custom-file-control:valid ~ .valid-tooltip, .has-danger.is-focused .form-control.is-valid ~ .valid-feedback, .has-danger.is-focused .is-valid.custom-file-control ~ .valid-feedback,
  .has-danger.is-focused .form-control.is-valid ~ .valid-tooltip,
  .has-danger.is-focused .is-valid.custom-file-control ~ .valid-tooltip {
    display: block; }

.was-validated .has-danger .is-focused textarea.form-control:valid, .was-validated .has-danger .is-focused textarea.custom-file-control:valid, .has-danger .is-focused textarea.form-control.is-valid, .has-danger .is-focused textarea.is-valid.custom-file-control, .was-validated .has-danger.is-focused textarea.form-control:valid, .was-validated .has-danger.is-focused textarea.custom-file-control:valid, .has-danger.is-focused textarea.form-control.is-valid, .has-danger.is-focused textarea.is-valid.custom-file-control {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .has-danger .is-focused .custom-select:valid, .has-danger .is-focused .custom-select.is-valid, .was-validated .has-danger.is-focused .custom-select:valid, .has-danger.is-focused .custom-select.is-valid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, "none" #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .has-danger .is-focused .custom-select:valid:focus, .has-danger .is-focused .custom-select.is-valid:focus, .was-validated .has-danger.is-focused .custom-select:valid:focus, .has-danger.is-focused .custom-select.is-valid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .has-danger .is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .has-danger .is-focused .custom-select:valid ~ .valid-tooltip, .has-danger .is-focused .custom-select.is-valid ~ .valid-feedback,
  .has-danger .is-focused .custom-select.is-valid ~ .valid-tooltip, .was-validated .has-danger.is-focused .custom-select:valid ~ .valid-feedback,
  .was-validated .has-danger.is-focused .custom-select:valid ~ .valid-tooltip, .has-danger.is-focused .custom-select.is-valid ~ .valid-feedback,
  .has-danger.is-focused .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .has-danger .is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .form-control-file:valid ~ .valid-tooltip, .has-danger .is-focused .form-control-file.is-valid ~ .valid-feedback,
.has-danger .is-focused .form-control-file.is-valid ~ .valid-tooltip, .was-validated .has-danger.is-focused .form-control-file:valid ~ .valid-feedback,
.was-validated .has-danger.is-focused .form-control-file:valid ~ .valid-tooltip, .has-danger.is-focused .form-control-file.is-valid ~ .valid-feedback,
.has-danger.is-focused .form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-danger .is-focused .form-check-input:valid ~ .form-check-label, .has-danger .is-focused .form-check-input.is-valid ~ .form-check-label, .was-validated .has-danger.is-focused .form-check-input:valid ~ .form-check-label, .has-danger.is-focused .form-check-input.is-valid ~ .form-check-label {
  color: #dc3545; }

.was-validated .has-danger .is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .form-check-input:valid ~ .valid-tooltip, .has-danger .is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-danger .is-focused .form-check-input.is-valid ~ .valid-tooltip, .was-validated .has-danger.is-focused .form-check-input:valid ~ .valid-feedback,
.was-validated .has-danger.is-focused .form-check-input:valid ~ .valid-tooltip, .has-danger.is-focused .form-check-input.is-valid ~ .valid-feedback,
.has-danger.is-focused .form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-danger .is-focused .custom-control-input:valid ~ .custom-control-label, .has-danger .is-focused .custom-control-input.is-valid ~ .custom-control-label, .was-validated .has-danger.is-focused .custom-control-input:valid ~ .custom-control-label, .has-danger.is-focused .custom-control-input.is-valid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .has-danger .is-focused .custom-control-input:valid ~ .custom-control-label::before, .has-danger .is-focused .custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .has-danger.is-focused .custom-control-input:valid ~ .custom-control-label::before, .has-danger.is-focused .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .has-danger .is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .custom-control-input:valid ~ .valid-tooltip, .has-danger .is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-danger .is-focused .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .has-danger.is-focused .custom-control-input:valid ~ .valid-feedback,
.was-validated .has-danger.is-focused .custom-control-input:valid ~ .valid-tooltip, .has-danger.is-focused .custom-control-input.is-valid ~ .valid-feedback,
.has-danger.is-focused .custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-danger .is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .has-danger .is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .has-danger.is-focused .custom-control-input:valid:checked ~ .custom-control-label::before, .has-danger.is-focused .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .has-danger .is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .has-danger .is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .has-danger.is-focused .custom-control-input:valid:focus ~ .custom-control-label::before, .has-danger.is-focused .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .has-danger .is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .has-danger .is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .has-danger.is-focused .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .has-danger.is-focused .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .has-danger .is-focused .custom-file-input:valid ~ .custom-file-label, .has-danger .is-focused .custom-file-input.is-valid ~ .custom-file-label, .was-validated .has-danger.is-focused .custom-file-input:valid ~ .custom-file-label, .has-danger.is-focused .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .has-danger .is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-danger .is-focused .custom-file-input:valid ~ .valid-tooltip, .has-danger .is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-danger .is-focused .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .has-danger.is-focused .custom-file-input:valid ~ .valid-feedback,
.was-validated .has-danger.is-focused .custom-file-input:valid ~ .valid-tooltip, .has-danger.is-focused .custom-file-input.is-valid ~ .valid-feedback,
.has-danger.is-focused .custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .has-danger .is-focused .custom-file-input:valid:focus ~ .custom-file-label, .has-danger .is-focused .custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .has-danger.is-focused .custom-file-input:valid:focus ~ .custom-file-label, .has-danger.is-focused .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.has-danger .is-focused [class^='bmd-label'],
.has-danger .is-focused [class*=' bmd-label'], .has-danger.is-focused [class^='bmd-label'],
.has-danger.is-focused [class*=' bmd-label'] {
  color: #dc3545; }

.has-danger .is-focused .bmd-label-placeholder, .has-danger.is-focused .bmd-label-placeholder {
  color: #dc3545; }

.has-danger .is-focused .form-control, .has-danger .is-focused .custom-file-control, .has-danger.is-focused .form-control, .has-danger.is-focused .custom-file-control {
  border-color: #dc3545; }

.has-danger .is-focused .bmd-help, .has-danger.is-focused .bmd-help {
  color: rgba(0, 0, 0, 0.54); }

.bmd-form-group {
  position: relative;
  padding-top: 1.75rem; }
  .bmd-form-group [class^='bmd-label'],
  .bmd-form-group [class*=' bmd-label'] {
    position: absolute;
    pointer-events: none;
    transition: 0.3s ease all; }
    .bmd-form-group [class^='bmd-label'].bmd-label-floating,
    .bmd-form-group [class*=' bmd-label'].bmd-label-floating {
      will-change: left, top, contents; }
  .bmd-form-group.is-filled .bmd-label-placeholder {
    display: none; }
  .bmd-form-group.bmd-collapse-inline {
    display: flex;
    align-items: center;
    padding: 0;
    min-height: 2.1em; }
    .bmd-form-group.bmd-collapse-inline .collapse {
      flex: 1;
      display: none; }
      .bmd-form-group.bmd-collapse-inline .collapse.show {
        max-width: 1200px; }
    .bmd-form-group.bmd-collapse-inline .collapsing,
    .bmd-form-group.bmd-collapse-inline .width:not(.collapse),
    .bmd-form-group.bmd-collapse-inline .collapse.show {
      display: block; }
    .bmd-form-group.bmd-collapse-inline .collapsing {
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .bmd-form-group .form-control, .bmd-form-group .custom-file-control,
  .bmd-form-group label,
  .bmd-form-group input::placeholder {
    line-height: 1; }
  .bmd-form-group input.form-control, .bmd-form-group input.custom-file-control,
  .bmd-form-group input.form-control::placeholder,
  .bmd-form-group input.custom-file-control::placeholder {
    padding: 0.28125rem 0;
    line-height: 1.5; }
  .bmd-form-group .radio label,
  .bmd-form-group label.radio-inline,
  .bmd-form-group .checkbox label,
  .bmd-form-group label.checkbox-inline,
  .bmd-form-group .switch label {
    line-height: 1.5; }
  .bmd-form-group input::placeholder {
    font-size: 1rem; }
  .bmd-form-group .checkbox label,
  .bmd-form-group .radio label,
  .bmd-form-group label {
    font-size: 1rem; }
  .bmd-form-group .bmd-label-floating,
  .bmd-form-group .bmd-label-placeholder {
    top: 2.125rem; }
  .bmd-form-group.is-focused .bmd-label-floating,
  .bmd-form-group .is-focused .bmd-label-floating, .bmd-form-group.is-filled .bmd-label-floating,
  .bmd-form-group .is-filled .bmd-label-floating {
    top: 1rem;
    left: 0;
    font-size: 0.75rem; }
  .bmd-form-group .bmd-label-static {
    top: 1rem;
    left: 0;
    font-size: 0.75rem; }
  .bmd-form-group .bmd-help {
    margin-top: 0;
    font-size: 0.75rem; }
  .bmd-form-group .form-control.form-control-success, .bmd-form-group .form-control-success.custom-file-control, .bmd-form-group .form-control.form-control-warning, .bmd-form-group .form-control-warning.custom-file-control, .bmd-form-group .form-control.form-control-danger, .bmd-form-group .form-control-danger.custom-file-control {
    background-size: 0 100%, 100% 100%, 0.875rem 0.875rem; }
    .bmd-form-group .form-control.form-control-success, .bmd-form-group .form-control-success.custom-file-control, .bmd-form-group .form-control.form-control-success:focus, .bmd-form-group .form-control-success.custom-file-control:focus,
    .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
    .bmd-form-group.is-focused .bmd-form-group .form-control-success.custom-file-control, .bmd-form-group .form-control.form-control-warning, .bmd-form-group .form-control-warning.custom-file-control, .bmd-form-group .form-control.form-control-warning:focus, .bmd-form-group .form-control-warning.custom-file-control:focus,
    .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning,
    .bmd-form-group.is-focused .bmd-form-group .form-control-warning.custom-file-control, .bmd-form-group .form-control.form-control-danger, .bmd-form-group .form-control-danger.custom-file-control, .bmd-form-group .form-control.form-control-danger:focus, .bmd-form-group .form-control-danger.custom-file-control:focus,
    .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger,
    .bmd-form-group.is-focused .bmd-form-group .form-control-danger.custom-file-control {
      padding-right: 2.25rem;
      background-repeat: no-repeat, no-repeat;
      background-position: center bottom, center calc(100% - 1px), center right 0.4375rem; }
    .bmd-form-group .form-control.form-control-success:focus, .bmd-form-group .form-control-success.custom-file-control:focus,
    .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
    .bmd-form-group.is-focused .bmd-form-group .form-control-success.custom-file-control, .bmd-form-group .form-control.form-control-warning:focus, .bmd-form-group .form-control-warning.custom-file-control:focus,
    .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning,
    .bmd-form-group.is-focused .bmd-form-group .form-control-warning.custom-file-control, .bmd-form-group .form-control.form-control-danger:focus, .bmd-form-group .form-control-danger.custom-file-control:focus,
    .bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger,
    .bmd-form-group.is-focused .bmd-form-group .form-control-danger.custom-file-control {
      background-size: 100% 100%, 100% 100%, 0.875rem 0.875rem; }
  .bmd-form-group.bmd-form-group-sm {
    padding-top: 1.40625rem; }
    .bmd-form-group.bmd-form-group-sm .form-control, .bmd-form-group.bmd-form-group-sm .custom-file-control,
    .bmd-form-group.bmd-form-group-sm label,
    .bmd-form-group.bmd-form-group-sm input::placeholder {
      line-height: 1; }
    .bmd-form-group.bmd-form-group-sm input.form-control, .bmd-form-group.bmd-form-group-sm input.custom-file-control,
    .bmd-form-group.bmd-form-group-sm input.form-control::placeholder,
    .bmd-form-group.bmd-form-group-sm input.custom-file-control::placeholder {
      padding: 0.28125rem 0;
      line-height: 1.5; }
    .bmd-form-group.bmd-form-group-sm .radio label,
    .bmd-form-group.bmd-form-group-sm label.radio-inline,
    .bmd-form-group.bmd-form-group-sm .checkbox label,
    .bmd-form-group.bmd-form-group-sm label.checkbox-inline,
    .bmd-form-group.bmd-form-group-sm .switch label {
      line-height: 1.5; }
    .bmd-form-group.bmd-form-group-sm input::placeholder {
      font-size: 0.875rem; }
    .bmd-form-group.bmd-form-group-sm .checkbox label,
    .bmd-form-group.bmd-form-group-sm .radio label,
    .bmd-form-group.bmd-form-group-sm label {
      font-size: 0.875rem; }
    .bmd-form-group.bmd-form-group-sm .bmd-label-floating,
    .bmd-form-group.bmd-form-group-sm .bmd-label-placeholder {
      top: 1.65625rem; }
    .bmd-form-group.bmd-form-group-sm.is-focused .bmd-label-floating,
    .bmd-form-group.bmd-form-group-sm .is-focused .bmd-label-floating, .bmd-form-group.bmd-form-group-sm.is-filled .bmd-label-floating,
    .bmd-form-group.bmd-form-group-sm .is-filled .bmd-label-floating {
      top: 0.75rem;
      left: 0;
      font-size: 0.65625rem; }
    .bmd-form-group.bmd-form-group-sm .bmd-label-static {
      top: 0.75rem;
      left: 0;
      font-size: 0.65625rem; }
    .bmd-form-group.bmd-form-group-sm .bmd-help {
      margin-top: 0;
      font-size: 0.65625rem; }
    .bmd-form-group.bmd-form-group-sm .form-control.form-control-success, .bmd-form-group.bmd-form-group-sm .form-control-success.custom-file-control, .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning, .bmd-form-group.bmd-form-group-sm .form-control-warning.custom-file-control, .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger, .bmd-form-group.bmd-form-group-sm .form-control-danger.custom-file-control {
      background-size: 0 100%, 100% 100%, 0.6875rem 0.6875rem; }
      .bmd-form-group.bmd-form-group-sm .form-control.form-control-success, .bmd-form-group.bmd-form-group-sm .form-control-success.custom-file-control, .bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus, .bmd-form-group.bmd-form-group-sm .form-control-success.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control-success.custom-file-control, .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning, .bmd-form-group.bmd-form-group-sm .form-control-warning.custom-file-control, .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus, .bmd-form-group.bmd-form-group-sm .form-control-warning.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control-warning.custom-file-control, .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger, .bmd-form-group.bmd-form-group-sm .form-control-danger.custom-file-control, .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus, .bmd-form-group.bmd-form-group-sm .form-control-danger.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control-danger.custom-file-control {
        padding-right: 2.25rem;
        background-repeat: no-repeat, no-repeat;
        background-position: center bottom, center calc(100% - 1px), center right 0.34375rem; }
      .bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus, .bmd-form-group.bmd-form-group-sm .form-control-success.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control-success.custom-file-control, .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus, .bmd-form-group.bmd-form-group-sm .form-control-warning.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control-warning.custom-file-control, .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus, .bmd-form-group.bmd-form-group-sm .form-control-danger.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control-danger.custom-file-control {
        background-size: 100% 100%, 100% 100%, 0.6875rem 0.6875rem; }
  .bmd-form-group.bmd-form-group-lg {
    padding-top: 1.9375rem; }
    .bmd-form-group.bmd-form-group-lg .form-control, .bmd-form-group.bmd-form-group-lg .custom-file-control,
    .bmd-form-group.bmd-form-group-lg label,
    .bmd-form-group.bmd-form-group-lg input::placeholder {
      line-height: 1; }
    .bmd-form-group.bmd-form-group-lg input.form-control, .bmd-form-group.bmd-form-group-lg input.custom-file-control,
    .bmd-form-group.bmd-form-group-lg input.form-control::placeholder,
    .bmd-form-group.bmd-form-group-lg input.custom-file-control::placeholder {
      padding: 0.28125rem 0;
      line-height: 1.5; }
    .bmd-form-group.bmd-form-group-lg .radio label,
    .bmd-form-group.bmd-form-group-lg label.radio-inline,
    .bmd-form-group.bmd-form-group-lg .checkbox label,
    .bmd-form-group.bmd-form-group-lg label.checkbox-inline,
    .bmd-form-group.bmd-form-group-lg .switch label {
      line-height: 1.5; }
    .bmd-form-group.bmd-form-group-lg input::placeholder {
      font-size: 1.25rem; }
    .bmd-form-group.bmd-form-group-lg .checkbox label,
    .bmd-form-group.bmd-form-group-lg .radio label,
    .bmd-form-group.bmd-form-group-lg label {
      font-size: 1.25rem; }
    .bmd-form-group.bmd-form-group-lg .bmd-label-floating,
    .bmd-form-group.bmd-form-group-lg .bmd-label-placeholder {
      top: 2.4375rem; }
    .bmd-form-group.bmd-form-group-lg.is-focused .bmd-label-floating,
    .bmd-form-group.bmd-form-group-lg .is-focused .bmd-label-floating, .bmd-form-group.bmd-form-group-lg.is-filled .bmd-label-floating,
    .bmd-form-group.bmd-form-group-lg .is-filled .bmd-label-floating {
      top: 1rem;
      left: 0;
      font-size: 0.9375rem; }
    .bmd-form-group.bmd-form-group-lg .bmd-label-static {
      top: 1rem;
      left: 0;
      font-size: 0.9375rem; }
    .bmd-form-group.bmd-form-group-lg .bmd-help {
      margin-top: 0;
      font-size: 0.9375rem; }
    .bmd-form-group.bmd-form-group-lg .form-control.form-control-success, .bmd-form-group.bmd-form-group-lg .form-control-success.custom-file-control, .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning, .bmd-form-group.bmd-form-group-lg .form-control-warning.custom-file-control, .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger, .bmd-form-group.bmd-form-group-lg .form-control-danger.custom-file-control {
      background-size: 0 100%, 100% 100%, 1.125rem 1.125rem; }
      .bmd-form-group.bmd-form-group-lg .form-control.form-control-success, .bmd-form-group.bmd-form-group-lg .form-control-success.custom-file-control, .bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus, .bmd-form-group.bmd-form-group-lg .form-control-success.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control-success.custom-file-control, .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning, .bmd-form-group.bmd-form-group-lg .form-control-warning.custom-file-control, .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus, .bmd-form-group.bmd-form-group-lg .form-control-warning.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control-warning.custom-file-control, .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger, .bmd-form-group.bmd-form-group-lg .form-control-danger.custom-file-control, .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus, .bmd-form-group.bmd-form-group-lg .form-control-danger.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control-danger.custom-file-control {
        padding-right: 2.25rem;
        background-repeat: no-repeat, no-repeat;
        background-position: center bottom, center calc(100% - 1px), center right 0.5625rem; }
      .bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus, .bmd-form-group.bmd-form-group-lg .form-control-success.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control-success.custom-file-control, .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus, .bmd-form-group.bmd-form-group-lg .form-control-warning.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control-warning.custom-file-control, .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus, .bmd-form-group.bmd-form-group-lg .form-control-danger.custom-file-control:focus,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
      .bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control-danger.custom-file-control {
        background-size: 100% 100%, 100% 100%, 1.125rem 1.125rem; }

.form-control, .custom-file-control,
label,
input::placeholder {
  line-height: 1; }

input.form-control, input.custom-file-control,
input.form-control::placeholder,
input.custom-file-control::placeholder {
  padding: 0.28125rem 0;
  line-height: 1.5; }

.radio label,
label.radio-inline,
.checkbox label,
label.checkbox-inline,
.switch label {
  line-height: 1.5; }

input::placeholder {
  font-size: 1rem; }

.checkbox label,
.radio label,
label {
  font-size: 1rem; }

.bmd-label-floating,
.bmd-label-placeholder {
  top: 2.125rem; }

.is-focused .bmd-label-floating,
.is-focused .bmd-label-floating,
.is-filled .bmd-label-floating,
.is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.75rem; }

.bmd-label-static {
  top: 1rem;
  left: 0;
  font-size: 0.75rem; }

.bmd-help {
  margin-top: 0;
  font-size: 0.75rem; }

.form-control.form-control-success, .form-control-success.custom-file-control, .form-control.form-control-warning, .form-control-warning.custom-file-control, .form-control.form-control-danger, .form-control-danger.custom-file-control {
  background-size: 0 100%, 100% 100%, 0.875rem 0.875rem; }
  .form-control.form-control-success, .form-control-success.custom-file-control, .form-control.form-control-success:focus, .form-control-success.custom-file-control:focus,
  .bmd-form-group.is-focused .form-control.form-control-success,
  .bmd-form-group.is-focused .form-control-success.custom-file-control, .form-control.form-control-warning, .form-control-warning.custom-file-control, .form-control.form-control-warning:focus, .form-control-warning.custom-file-control:focus,
  .bmd-form-group.is-focused .form-control.form-control-warning,
  .bmd-form-group.is-focused .form-control-warning.custom-file-control, .form-control.form-control-danger, .form-control-danger.custom-file-control, .form-control.form-control-danger:focus, .form-control-danger.custom-file-control:focus,
  .bmd-form-group.is-focused .form-control.form-control-danger,
  .bmd-form-group.is-focused .form-control-danger.custom-file-control {
    padding-right: 2.25rem;
    background-repeat: no-repeat, no-repeat;
    background-position: center bottom, center calc(100% - 1px), center right 0.4375rem; }
  .form-control.form-control-success:focus, .form-control-success.custom-file-control:focus,
  .bmd-form-group.is-focused .form-control.form-control-success,
  .bmd-form-group.is-focused .form-control-success.custom-file-control, .form-control.form-control-warning:focus, .form-control-warning.custom-file-control:focus,
  .bmd-form-group.is-focused .form-control.form-control-warning,
  .bmd-form-group.is-focused .form-control-warning.custom-file-control, .form-control.form-control-danger:focus, .form-control-danger.custom-file-control:focus,
  .bmd-form-group.is-focused .form-control.form-control-danger,
  .bmd-form-group.is-focused .form-control-danger.custom-file-control {
    background-size: 100% 100%, 100% 100%, 0.875rem 0.875rem; }

select, select.form-control, select.custom-file-control {
  -moz-appearance: none;
  -webkit-appearance: none; }

@media (min-width: 576px) {
  .form-inline .input-group {
    display: inline-flex;
    align-items: center; } }

.input-group .input-group-addon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-color: transparent; }

.input-group .input-group-addon + input,
.input-group input + .input-group-addon {
  margin-left: .75rem; }

.table-inverse {
  color: rgba(255, 255, 255, 0.84); }

.table thead th {
  font-size: 0.95rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  border-top-width: 0;
  border-bottom-width: 1px; }

thead.thead-inverse th,
.table-inverse thead th {
  color: rgba(255, 255, 255, 0.54); }

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: rgba(255, 255, 255, 0.06); }

.nav-link {
  text-transform: uppercase; }

.navbar-nav .nav-link {
  padding: 0.5321rem;
  font-size: 0.875rem;
  font-weight: 400; }

.nav-tabs,
.nav-pills {
  border: 0; }
  .nav-tabs .nav-link,
  .nav-pills .nav-link {
    padding: 1.4286em 0.8575em;
    font-size: 0.875rem;
    font-weight: 500;
    border: 0; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link,
  .nav-pills .nav-link.active,
  .nav-pills .nav-item.show .nav-link {
    background-color: transparent;
    color: inherit; }

.nav-tabs .nav-link {
  border-bottom: 0.214rem solid transparent; }

.nav-tabs .nav-link {
  color: rgba(0, 0, 0, 0.54); }
  .nav-tabs .nav-link.active {
    color: rgba(0, 0, 0, 0.87);
    border-color: #007fc0; }
    .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus {
      border-color: #007fc0; }
  .nav-tabs .nav-link.disabled {
    color: rgba(0, 0, 0, 0.26); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link.disabled:hover, .nav-tabs .nav-link.disabled:focus {
      color: rgba(0, 0, 0, 0.26); }

.nav-tabs.bg-primary .nav-link {
  color: white; }
  .nav-tabs.bg-primary .nav-link.active {
    color: #fff;
    border-color: #fff; }
    .nav-tabs.bg-primary .nav-link.active:hover, .nav-tabs.bg-primary .nav-link.active:focus {
      border-color: #fff; }
  .nav-tabs.bg-primary .nav-link.disabled {
    color: rgba(255, 255, 255, 0.84); }
    .nav-tabs.bg-primary .nav-link.disabled, .nav-tabs.bg-primary .nav-link.disabled:hover, .nav-tabs.bg-primary .nav-link.disabled:focus {
      color: rgba(255, 255, 255, 0.84); }

.nav-tabs.bg-dark .nav-link {
  color: white; }
  .nav-tabs.bg-dark .nav-link.active {
    color: #fff;
    border-color: #fff; }
    .nav-tabs.bg-dark .nav-link.active:hover, .nav-tabs.bg-dark .nav-link.active:focus {
      border-color: #fff; }
  .nav-tabs.bg-dark .nav-link.disabled {
    color: rgba(255, 255, 255, 0.84); }
    .nav-tabs.bg-dark .nav-link.disabled, .nav-tabs.bg-dark .nav-link.disabled:hover, .nav-tabs.bg-dark .nav-link.disabled:focus {
      color: rgba(255, 255, 255, 0.84); }

.nav .nav-item {
  position: relative; }

.navbar {
  z-index: 1;
  border: 0;
  border-radius: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .navbar .navbar-brand {
    position: relative; }
  .navbar > .container {
    flex: 1; }

.navbar-dark .form-control, .navbar-dark .custom-file-control {
  color: #fff; }
  .navbar-dark .form-control::placeholder, .navbar-dark .custom-file-control::placeholder {
    color: #fff; }

.alert {
  border: 0;
  border-radius: 0; }

.page-item:first-child .page-link {
  border-top-left-radius: 4em;
  border-bottom-left-radius: 4em; }

.page-item:last-child .page-link {
  border-top-right-radius: 4em;
  border-bottom-right-radius: 4em;
  margin-right: 0; }

.page-item:first-child .page-link, .page-item:last-child .page-link {
  display: flex;
  justify-content: center;
  padding-right: 0;
  padding-left: 0; }
  .page-item:first-child .page-link > .material-icons, .page-item:last-child .page-link > .material-icons {
    font-size: 1.5em; }

.page-link {
  min-width: 2.6em;
  margin-right: 0.5em;
  text-align: center;
  border-radius: 4em; }
  .page-link:hover, .page-link:focus {
    text-decoration: none; }

.pagination-lg .page-link {
  min-width: 2.8em; }

.pagination-lg .page-link {
  padding: 0.75rem 0;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 4em;
  border-bottom-left-radius: 4em; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 4em;
  border-bottom-right-radius: 4em; }

.pagination-sm .page-link {
  min-width: 2.2em; }

.pagination-sm .page-link {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 4em;
  border-bottom-left-radius: 4em; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 4em;
  border-bottom-right-radius: 4em; }

h6 {
  font-weight: 500; }

.tooltip-arrow {
  display: none; }

.dropdown-menu {
  display: none;
  padding: .25rem 0;
  border: 0;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  will-change: transform, opacity;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1); }
  @media (min-width: 768px) {
    .dropdown-menu {
      padding: .5rem 0; } }
  .dropdown-menu.showing {
    animation-name: bmd-dropdown-animation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }
  .open > .dropdown-menu, .dropdown-menu.show {
    display: block;
    opacity: 1;
    transform: scale(1); }
  .dropdown-menu.hiding {
    display: block;
    opacity: 0;
    transform: scale(0); }
  .dropdown-menu[x-placement="bottom-start"], .dropdown-menu.dropdown-menu-left {
    transform-origin: 0 0; }
  .dropdown-menu[x-placement="bottom-end"], .dropdown-menu.dropdown-menu-right {
    transform-origin: 100% 0; }
  .dropdown-menu[x-placement="top-start"] {
    transform-origin: 0 100%; }
  .dropdown-menu[x-placement="top-end"] {
    transform-origin: 100% 100%; }
  .dropdown-menu .dropdown-item {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    min-width: 7rem;
    max-width: 17.5rem;
    min-height: 3rem;
    padding: 0.8rem 1rem 0.8rem 1rem;
    overflow: hidden;
    line-height: 1;
    text-overflow: ellipsis;
    word-wrap: break-word; }
    .dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
      background-color: inherit;
      color: inherit; }
    @media (min-width: 768px) {
      .dropdown-menu .dropdown-item {
        padding-right: 1.5rem;
        padding-left: 1.5rem; } }

.dropdown-toggle.bmd-btn-icon::after, .dropdown-toggle.bmd-btn-fab::after {
  display: none; }

.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu.dropdown-menu-top-left, .dropdown-toggle.bmd-btn-icon ~ .dropdown-menu.dropdown-menu-top-right, .dropdown-toggle.bmd-btn-fab ~ .dropdown-menu.dropdown-menu-top-left, .dropdown-toggle.bmd-btn-fab ~ .dropdown-menu.dropdown-menu-top-right {
  bottom: 2rem; }

.dropdown-toggle.bmd-btn-fab-sm ~ .dropdown-menu.dropdown-menu-top-left, .dropdown-toggle.bmd-btn-fab-sm ~ .dropdown-menu.dropdown-menu-top-right {
  bottom: 2.5rem; }

.dropdown-toggle.bmd-btn-icon ~ .dropdown-menu {
  margin: 0; }

.show .dropdown-toggle.btn, .show .dropdown-toggle.custom-file-control::before, .show .dropdown-toggle.btn-secondary, .show .dropdown-toggle.btn-success, .show .dropdown-toggle.btn-info, .show .dropdown-toggle.btn-warning, .show .dropdown-toggle.btn-danger {
  background-color: transparent; }

@keyframes bmd-dropdown-animation {
  from {
    opacity: 0;
    transform: scale(0); }
  to {
    opacity: 1;
    transform: scale(1); } }

.ripple {
  position: relative; }

.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  border-radius: inherit; }
  .ripple-container .ripple-decorator {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 100%;
    opacity: 0;
    transform: scale(1);
    transform-origin: 50%; }
    .ripple-container .ripple-decorator.ripple-on {
      opacity: 0.1;
      transition: opacity 0.15s ease-in 0s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s; }
    .ripple-container .ripple-decorator.ripple-out {
      opacity: 0;
      transition: opacity 0.1s linear 0s !important; }

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"), url(../fonts/Lato/Lato-Light-Italic.ttf) format("truetype"), url(../fonts/Lato/Lato-Light-Italic.woff2) format("woff2"), url(../fonts/Lato/Lato-Light-Italic.woff) format("woff"); }

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local("Lato Italic"), local("Lato-Italic"), url(../fonts/Lato/Lato-Italic.ttf) format("truetype"), url(../fonts/Lato/Lato-Italic.woff2) format("woff2"), url(../fonts/Lato/Lato-Italic.woff) format("woff"); }

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url(../fonts/Lato/Lato-Bold-Italic.ttf) format("truetype"), url(../fonts/Lato/Lato-Bold-Italic.woff2) format("woff2"), url(../fonts/Lato/Lato-Bold-Italic.woff) format("woff"); }

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url(../fonts/Lato/Lato-Light.ttf) format("truetype"), url(../fonts/Lato/Lato-Light.woff2) format("woff2"), url(../fonts/Lato/Lato-Light.woff) format("woff"); }

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Lato/Lato-Regular.eot);
  src: local("Lato Regular"), local("Lato-Regular"), local("Lato"), url(../fonts/Lato/Lato-Regular.ttf) format("truetype"), url(../fonts/Lato/Lato-Regular.svg#Lato) format("svg"), url(../fonts/Lato/Lato-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/Lato/Lato-Regular.woff2) format("woff2"), url(../fonts/Lato/Lato-Regular.woff) format("woff"); }

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url(../fonts/Lato/Lato-Bold.ttf) format("truetype"), url(../fonts/Lato/Lato-Bold.woff2) format("woff2"), url(../fonts/Lato/Lato-Bold.woff) format("woff"); }

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"), url(../fonts/Lato/Lato-Black.ttf) format("truetype"), url(../fonts/Lato/Lato-Black.woff2) format("woff2"), url(../fonts/Lato/Lato-Black.woff) format("woff"); }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("../fonts/MaterialIcons/Material Icons"), local("../fonts/MaterialIcons/MaterialIcons-Regular"), url(../fonts/MaterialIcons/MaterialIcons-Regular.woff2) format("woff2"), url(../fonts/MaterialIcons/MaterialIcons-Regular.woff) format("woff"), url(../fonts/MaterialIcons/MaterialIcons-Regular.ttf) format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

h1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 3px solid #000;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    h1 {
      font-size: 1.875rem; } }

h2 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem; }
  h2.big {
    font-size: 1.375rem; }

h3 {
  text-transform: uppercase;
  font-size: 1rem; }
  h3.big {
    font-size: 1.25rem; }

a:focus {
  outline: 1px dotted #000; }

.status--active,
.status--active a {
  color: green; }

.status--inactive,
.status--inactive a {
  color: red; }

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px; }

.material-icons.md-24 {
  font-size: 24px; }

.material-icons.md-36 {
  font-size: 36px; }

.material-icons.md-48 {
  font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: white; }

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3); }

.icon-more {
  display: inline-block;
  border: 1px solid #007fc0;
  border-radius: 7px; }
  .icon-more i {
    vertical-align: middle; }

.icon-pdf-small {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background: url("img/ico-pdf-small.png") no-repeat; }
  .svg .icon-pdf-small {
    background-image: url("img/ico-pdf-small.svg"); }

.icon-pdf:hover .icon-pdf-small {
  background-position: -24px 0; }

.icon-mailchimp {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  width: 26px;
  height: 27px;
  background: url("img/ico-mailchimp.png") no-repeat; }
  .svg .icon-mailchimp {
    background-image: url("img/ico-mailchimp.svg"); }

.icon-mailerlite {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
  width: 26px;
  height: 27px;
  background: url("img/ico-mailerlite.png") no-repeat; }
  .svg .icon-mailerlite {
    background-image: url("img/ico-mailerlite.svg"); }

.icon-mail:hover .icon-mailchimp {
  background-position: -26px 0; }

.icon-mail:hover .icon-mailerlite {
  background-position: -24px 0; }

.material-icons + strong {
  margin-left: 0.25rem; }

/* components */
.skip-link {
  position: absolute;
  top: 1.5rem;
  right: 100%;
  /* moves off screen */
  background-color: #fff;
  padding: 0.5rem;
  font-size: 1rem;
  text-decoration: none;
  color: #000; }
  .skip-link:focus {
    right: auto;
    left: 1.5rem;
    z-index: 10;
    outline: 2px solid #007fc0; }

.grid {
  margin-bottom: 2rem; }
  .grid--simple {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .grid--border {
      border-bottom: solid 1px #979797; } }
  @media (min-width: 768px) {
    .grid {
      display: flex;
      flex-wrap: wrap;
      margin-left: -0.5rem;
      margin-right: -0.5rem; } }
  .grid__item {
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      .grid__item {
        padding-left: 0.5rem;
        padding-right: 0.5rem; } }
  @media (min-width: 768px) {
    .grid--6 .grid__item {
      width: 33.33%; } }
  @media (min-width: 1200px) {
    .grid--6 .grid__item {
      width: 16.66%; } }
  @media (min-width: 768px) {
    .grid--4 .grid__item {
      width: 50%; } }
  @media (min-width: 992px) {
    .grid--4 .grid__item {
      width: 25%; } }
  @media (min-width: 768px) {
    .grid--3 .grid__item {
      width: 50%; } }
  @media (min-width: 992px) {
    .grid--3 .grid__item {
      width: 33.333%; } }
  @media (min-width: 768px) {
    .grid--2 .grid__item {
      width: 50%; } }

header {
  background-image: linear-gradient(to right, #ffffff 0%, #ffffff 40%, #00befc 100%); }
  @media (min-width: 992px) {
    header > .container,
    header .navbar > .container {
      padding-left: 3rem;
      padding-right: 3rem; } }

.site-logo {
  width: 70px;
  padding-bottom: 0;
  margin-bottom: 0;
  border: 0;
  font-size: 1rem; }
  .site-logo span {
    text-transform: none;
    display: none; }
  .site-logo a:hover {
    text-decoration: none; }
  @media (min-width: 576px) {
    .site-logo {
      width: auto; }
      .site-logo span {
        display: inline;
        font-size: 1.2rem;
        margin-left: 1em;
        font-weight: bold; } }
  .site-logo.site-title span {
    display: block;
    /*         font-size: 1.2rem; */
    /*         margin-left: 0;  */
    font-weight: bold;
    margin-top: .5em;
    color: #007fc0; }

.navbar {
  box-shadow: none;
  background-color: #007fc0;
  margin-bottom: 2rem; }
  @media (max-width: 767.98px) {
    .navbar {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; } }
  @media (min-width: 768px) {
    .navbar {
      margin-bottom: 3rem; } }
  @media (min-width: 992px) {
    .navbar {
      margin-bottom: 4rem; } }
  @media (min-width: 992px) {
    .navbar .container {
      padding-left: 0;
      padding-right: 0; } }
  .navbar .nav-item {
    border-right: 1px solid rgba(255, 255, 255, 0.4); }
    @media (max-width: 767.98px) {
      .navbar .nav-item {
        border-right: 0; } }
  .navbar .nav-link {
    text-transform: none;
    color: #fff !important;
    font-size: 1.125rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem; }
    @media (max-width: 991.98px) {
      .navbar .nav-link {
        font-size: 1rem; } }
    @media (max-width: 767.98px) {
      .navbar .nav-link {
        padding: 0.5rem 0; } }
    .navbar .nav-link i {
      vertical-align: bottom; }
    .navbar .nav-link--config {
      padding-left: 0;
      padding-right: 0; }
  .navbar .dropdown-toggle:after {
    display: none; }
  .navbar .dropdown-menu {
    box-shadow: none;
    background-color: #007fc0;
    background-image: linear-gradient(to top, #00b6e1, #007fc0);
    padding: 1rem 1rem 0; }
    @media (max-width: 767.98px) {
      .navbar .dropdown-menu {
        padding: 0; } }
    .navbar .dropdown-menu ul {
      list-style: none;
      padding-left: 0; }
      .navbar .dropdown-menu ul li:last-child a {
        border-bottom: none; }
  .navbar .dropdown-item {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    padding-left: 1rem;
    padding-right: 1rem; }
    @media (max-width: 767.98px) {
      .navbar .dropdown-item {
        max-width: none;
        text-align-last: left; } }
    .navbar .dropdown-item:hover {
      background-color: #007fc0; }
    .navbar .dropdown-item.active {
      color: #fff;
      background-color: #007fc0; }

@media (max-width: 767.98px) {
  .navbar .navbar-collapse {
    margin: 1rem 0; } }

@media (min-width: 576px) {
  .navbar .navbar-collapse .navbar-nav .nav-link {
    padding-left: 0.7rem;
    padding-right: 0.7rem; } }

@media (min-width: 992px) {
  .navbar .navbar-collapse .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem; } }

.navbar-expand-md .navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 992px) {
    .navbar-expand-md .navbar-nav .nav-link {
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }

.navbar-expand-md .navbar-nav > li:first-child a {
  padding-left: 0; }

.usr-dropdown a {
  color: #000;
  font-size: 1rem; }
  @media (min-width: 768px) {
    .usr-dropdown a {
      font-size: 1.125rem; } }
  .usr-dropdown a img {
    vertical-align: middle; }

.usr-dropdown .dropdown-header {
  padding: 0.5rem 1rem; }

.usr-dropdown .dropdown-menu {
  top: 45px !important; }
  .usr-dropdown .dropdown-menu .dropdown-item {
    padding: 0.5rem 1rem; }
    .usr-dropdown .dropdown-menu .dropdown-item.active {
      font-weight: 700; }
      .usr-dropdown .dropdown-menu .dropdown-item.active:hover {
        background-color: #f8f9fa; }
  .usr-dropdown .dropdown-menu a {
    font-size: 1rem; }

footer {
  background-color: #007fc0; }
  footer p {
    color: #fff;
    margin-bottom: 0; }

.heading {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .heading--icon:before {
    content: "";
    display: block;
    margin: 0 0 2rem;
    background-repeat: no-repeat; }
  .heading--butlleti:before {
    width: 100px;
    height: 38px;
    background-image: url("img/ico-butlleti-2.svg"); }
  .heading--pdf:before {
    width: 62px;
    height: 57px;
    background-image: url("img/ico-pdf-2.svg"); }
  .heading--compartir:before {
    width: 59px;
    height: 59px;
    background-image: url("img/ico-compartir-2.svg"); }
  .heading--crear-doc:before {
    width: 84px;
    height: 82px;
    background-image: url("img/ico-personalizar-informes-2.svg"); }
  .heading--crear-poster:before {
    width: 101px;
    height: 82px;
    background-image: url("img/ico-posters.svg"); }
  .heading--crear-fulleto:before {
    width: 78px;
    height: 47px;
    background-image: url("img/ico-folletos.svg"); }
  .heading--crear-informe:before {
    width: 90px;
    height: 72px;
    background-image: url("img/ico-informes.svg"); }
  .heading--crear-documentsrisc:before {
    width: 90px;
    height: 72px;
    background-image: url("img/ico-documentsrisc.svg"); }
  .heading--crear-documentaciogescom:before {
    width: 90px;
    height: 85px;
    background-image: url("img/ico-documentaciogescom.svg"); }
  .heading--crear-banner:before {
    width: 106px;
    height: 47px;
    background-image: url("img/ico-crear-personalizar-imagenes-2.svg"); }
  .heading--logo:before {
    width: 91px;
    height: 54px;
    background-image: url("img/logo-mcps.svg"); }

.steps {
  list-style: none;
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 2rem;
  padding-left: 0; }
  @media (min-width: 768px) {
    .steps {
      font-size: 1.5rem;
      margin-bottom: 3rem; } }
  .steps li {
    display: inline-block; }
    .steps li.current {
      font-weight: bold; }
  .steps i {
    vertical-align: middle;
    color: #6c757d; }
  .steps a {
    color: #000; }

.box {
  padding: 1.5rem;
  height: 100%; }
  .box--action {
    background-color: #9dbecf;
    position: relative;
    transition: background-color 0.2s ease-in-out;
    text-align: center; }
    @media (min-width: 768px) {
      .box--action {
        text-align: left; } }
    .box--action:hover {
      background-color: #7b9ba5; }
    .box--action a {
      color: #fff; }
      .box--action a:hover {
        text-decoration: none; }
      .box--action a:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%; }
    .box--action .box__title:before {
      content: "";
      background-repeat: no-repeat;
      display: block;
      margin: 0 auto 1rem; }
      @media (max-width: 767.98px) {
        .box--action .box__title:before {
          transform: scale(0.8);
          transform-origin: left; } }
      @media (min-width: 768px) {
        .box--action .box__title:before {
          margin-left: 0; } }
  .box--butlleti .box__title:before {
    background-image: url("img/ico-butlleti.svg");
    width: 100px;
    height: 38px; }
    @media (min-width: 768px) {
      .box--butlleti .box__title:before {
        margin-top: 44px; } }
  .box--pdf .box__title:before {
    background-image: url("img/ico-pdf.svg");
    width: 62px;
    height: 57px; }
    @media (min-width: 768px) {
      .box--pdf .box__title:before {
        margin-top: 25px; } }
  .box--compartir .box__title:before {
    background-image: url("img/ico-compartir.svg");
    width: 53px;
    height: 53px; }
    @media (min-width: 768px) {
      .box--compartir .box__title:before {
        margin-top: 17px; } }
  .box--crear-doc .box__title:before {
    background-image: url("img/ico-personalizar-informes.svg");
    width: 84px;
    height: 82px; }
  .box--crear-poster .box__title:before {
    background-image: url("img/ico-posters-neg.svg");
    width: 101px;
    height: 82px; }
  .box--crear-fulleto .box__title:before {
    background-image: url("img/ico-folletos-neg.svg");
    width: 78px;
    height: 47px; }
    @media (min-width: 768px) {
      .box--crear-fulleto .box__title:before {
        margin-top: 35px; } }
  .box--crear-informe .box__title:before {
    background-image: url("img/ico-informes-neg.svg");
    width: 90px;
    height: 72px; }
    @media (min-width: 768px) {
      .box--crear-informe .box__title:before {
        margin-top: 10px; } }
  .box--crear-banner .box__title:before {
    background-image: url("img/ico-crear-personalizar-imagenes.svg");
    width: 106px;
    height: 48px; }
    @media (min-width: 768px) {
      .box--crear-banner .box__title:before {
        margin-top: 34px; } }
  .box--crear-documentsrisc .box__title:before {
    background-image: url("img/ico-documentsrisc-neg.svg");
    width: 90px;
    height: 72px; }
    @media (min-width: 768px) {
      .box--crear-documentsrisc .box__title:before {
        margin-top: 10px; } }
  .box--crear-documentaciogescom .box__title:before {
    background-image: url("img/ico-documentaciogescom-neg.svg");
    width: 90px;
    height: 85px; }
    @media (min-width: 768px) {
      .box--crear-documentaciogescom .box__title:before {
        margin-top: 10px; } }
  @media (min-width: 768px) {
    .box--news {
      position: relative; } }
  .box--news .row {
    height: 100%; }
  .box--news-1 {
    background-color: #E3E8EB; }
    .box--news-1 .box__title {
      color: #7e9eae; }
  .box--news-2 {
    background-color: #00befc; }
    .box--news-2 .box__title {
      color: #fff; }
  .box--news .box__title {
    margin-bottom: 1rem; }
  .box--news .box__text {
    line-height: 1.25;
    color: #343a40; }
    @media (min-width: 992px) {
      .box--news .box__text {
        width: 60%; } }
  @media (min-width: 768px) {
    .box--news .btn, .box--news .custom-file-control::before {
      position: absolute;
      right: 1.5rem;
      bottom: 1.5rem; } }
  @media (min-width: 992px) {
    .box--news-image .box__text {
      width: 100%; } }
  .box--news-image img {
    max-width: 100%; }
    @media (min-width: 768px) {
      .box--news-image img {
        margin-top: .5rem; } }
  @media (min-width: 768px) {
    .box--news-image .btn, .box--news-image .custom-file-control::before {
      position: absolute;
      right: auto;
      bottom: 0; } }

.box__title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .box__title {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .box__title {
      font-size: 1rem; } }
  @media screen and (min-width: 1400px) {
    .box__title {
      font-size: 1.25rem; } }

.wrapper {
  padding: 3rem 0; }
  .wrapper--simple {
    padding: 0; }
  .wrapper--1 {
    background-color: #f8f9fa; }
  .wrapper--2 {
    background-color: #eaeaea;
    background-image: linear-gradient(to bottom, #eaeaea, #efefef); }

.table--docs {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 1.5rem; }
  .home .table--docs {
    border-top: 2px solid #000; }
  .table--docs th,
  .table--docs td {
    vertical-align: middle; }
  .table--docs thead th {
    font-weight: 700;
    color: #000;
    font-size: 1rem; }
  .tab-content .table--docs td,
  .tab-content .table--docs th {
    padding-left: 0;
    padding-right: 0;
    border-top: 0;
    border-bottom: 1px solid #afafaf; }
  .tab-content .table--docs .icons .icon {
    display: inline-block;
    vertical-align: middle; }
    .tab-content .table--docs .icons .icon i {
      vertical-align: bottom; }
  .table--docs .meta {
    font-size: 0.875rem;
    color: #007fc0;
    margin-bottom: 0.5rem;
    font-weight: 400; }
  .table--docs .title {
    font-size: 1.0625rem;
    margin-bottom: 0;
    font-weight: bold; }
    .table--docs .title a {
      color: #000; }

.table--icons .icons {
  text-align: right;
  white-space: nowrap; }
  @media (min-width: 768px) {
    .table--icons .icons {
      white-space: nowrap; } }
  .table--icons .icons .icon {
    color: #000;
    display: inline-flex;
    padding: 0.5rem 0.75rem;
    align-items: center; }
    @media (min-width: 768px) {
      .table--icons .icons .icon {
        padding-left: 1rem;
        padding-right: 1rem; } }
    .table--icons .icons .icon.removeFavorites {
      color: #00befc; }
    .table--icons .icons .icon:hover {
      color: #00befc;
      text-decoration: none; }
  .table--icons .icons .btn, .table--icons .icons .custom-file-control::before {
    margin-bottom: 0; }

table.dataTable {
  margin-top: 0 !important;
  margin-bottom: 1.5rem !important; }

.dataTables_length select {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-width: 0 0 1px 0;
  border-radius: 0;
  height: calc(2.4375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  background: rgba(0, 0, 0, 0) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center !important;
  background-color: rgba(0, 0, 0, 0) !important;
  background-size: auto auto;
  background-size: 8px 10px !important; }

@media (min-width: 768px) {
  .dataTables_length label {
    margin-bottom: 0 !important; } }

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: center !important; }
  @media (min-width: 768px) {
    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
      justify-content: right !important; } }

div.dataTables_wrapper div.dataTables_info {
  margin-bottom: 1rem; }

.dataTables_filter input {
  background: #e9ecef url("img/round-search-24px.svg") 90% 50% no-repeat;
  background-positon: calc(100% - 32px) 50%;
  padding-right: 3rem; }
  .dataTables_filter input:focus {
    background-image: url("img/round-search-24px.svg");
    background-size: 24px 24px; }

.btn.btn, .custom-file-control::before {
  border-radius: 7px;
  border: 0; }
  .btn.btn:focus, .custom-file-control:focus::before {
    box-shadow: none; }
  .btn.btn-sm, .btn-sm.custom-file-control::before {
    font-size: 0.75rem; }
  .btn.btn-lg, .btn-lg.custom-file-control::before {
    font-size: 0.875rem;
    padding: 1rem 1.5rem; }
    @media (min-width: 768px) {
      .btn.btn-lg, .btn-lg.custom-file-control::before {
        padding-left: 2.5rem;
        padding-right: 2.5rem; } }
  .btn.btn-primary, .btn-primary.custom-file-control::before {
    color: #fff;
    background-color: #007fc0; }
  .btn.btn-more, .btn-more.custom-file-control::before {
    color: #fff;
    background-color: #7b9ba5; }
    @media (max-width: 767.98px) {
      .btn.btn-more, .btn-more.custom-file-control::before {
        white-space: normal; } }
    .btn.btn-more:hover, .btn-more.custom-file-control:hover::before, .btn.btn-more:focus, .btn-more.custom-file-control:focus::before {
      background-color: #60828d; }
  .btn.btn-news, .btn-news.custom-file-control::before {
    color: #7b9ba5;
    background-color: #fff; }
    .btn.btn-news:hover, .btn-news.custom-file-control:hover::before, .btn.btn-news:focus, .btn-news.custom-file-control:focus::before {
      /*       background-color: darken(#fff, 10%); */
      background-color: #9dbecf !important;
      color: #fff !important; }
  .btn.btn-preview, .btn-preview.custom-file-control::before {
    color: #fff;
    background-color: #727272; }
    .btn.btn-preview:hover, .btn-preview.custom-file-control:hover::before, .btn.btn-preview:focus, .btn-preview.custom-file-control:focus::before {
      background-color: #595959; }
  .btn.btn-save, .btn-save.custom-file-control::before {
    background-image: url("img/round-save-24px.png");
    background-repeat: no-repeat;
    background-position: 1.5rem 50%;
    padding-left: 3.5rem; }
    @media (max-width: 767.98px) {
      .btn.btn-save, .btn-save.custom-file-control::before {
        white-space: normal; } }
    .svg .btn.btn-save, .svg .btn-save.custom-file-control::before {
      background-image: url("img/round-save-24px.svg"); }
  .btn.btn-delete, .btn-delete.custom-file-control::before {
    color: #fff;
    background-color: #737373;
    background-image: url("img/round-delete-24px.png");
    background-repeat: no-repeat;
    background-position: 1.5rem 50%;
    padding-left: 3.5rem; }
    .svg .btn.btn-delete, .svg .btn-delete.custom-file-control::before {
      background-image: url("img/round-delete-24px.svg"); }
    .btn.btn-delete:hover, .btn-delete.custom-file-control:hover::before, .btn.btn-delete:focus, .btn-delete.custom-file-control:focus::before {
      background-color: #5a5a5a; }
  .btn.btn-nuevo-contenido, .btn-nuevo-contenido.custom-file-control::before {
    margin-bottom: 1.5rem; }
    @media (min-width: 768px) {
      .btn.btn-nuevo-contenido, .btn-nuevo-contenido.custom-file-control::before {
        float: right;
        margin-top: -13rem; } }
  .btn.btn-loading, .btn-loading.custom-file-control::before {
    opacity: .4; }

.btn-preview i {
  vertical-align: bottom;
  margin-right: 0.25rem; }

.btn-container {
  text-align: center; }

fieldset {
  margin-bottom: 2rem; }

legend {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem; }

.col-form-label {
  text-transform: uppercase;
  display: block; }

.custom-file-control, .form-control, .custom-file-control {
  background-color: #e9ecef;
  padding: 1.125rem 1rem;
  height: auto;
  border-radius: 0; }
  .wrapper--1 .custom-file-control,
  .wrapper--2 .custom-file-control, .wrapper--1 .form-control, .wrapper--1 .custom-file-control,
  .wrapper--2 .form-control,
  .wrapper--2 .custom-file-control {
    background-color: #fff; }

.custom-file-control:focus, .form-control:focus, .custom-file-control:focus {
  background-color: #f8f9fa;
  box-shadow: none; }

.form-groups-wrapper {
  margin-bottom: 3rem; }
  @media (min-width: 1200px) {
    .form-groups-wrapper {
      padding-right: 15rem; } }

.form-group--search {
  position: relative; }
  .form-group--search input {
    padding-right: 4rem; }

.btn-search {
  border: 0;
  position: absolute;
  right: 0;
  bottom: 1px;
  line-height: 51px;
  padding: 0 1rem;
  background-color: transparent; }
  .btn-search i {
    vertical-align: middle; }

.custom-select {
  background-color: #fff;
  border-width: 0 0 1px 0;
  border-radius: 0;
  padding: 0.9rem 1rem;
  height: auto; }

textarea.form-control, textarea.custom-file-control {
  height: 10rem; }

.checkbox label {
  color: #000; }

.custom-control-label:before {
  top: 0;
  background-color: #eaeaea;
  box-shadow: none; }
  .wrapper--1 .custom-control-label:before,
  .wrapper--2 .custom-control-label:before {
    background-color: #fff; }

.custom-control-label:after {
  top: 0; }

.c-toggle {
  margin-top: 0.25rem; }
  .c-toggle label {
    cursor: pointer; }
  .c-toggle input[type="radio"] {
    display: inline-block;
    margin-right: -2px;
    width: 50%;
    height: 100%;
    opacity: 0;
    position: relative;
    z-index: 1;
    cursor: pointer; }
    .c-toggle input[type="radio"]:focus ~ .c-toggle__switcher {
      border: 2px solid #fff; }

.c-toggle__wrapper {
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
  width: 4em;
  height: 2em;
  border-radius: 2em;
  /* = height */
  position: relative;
  margin: 0 0.5rem; }

.c-toggle__switcher {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 100%;
  width: 50%;
  height: 100%;
  border-radius: 50%;
  background-color: #007fc0;
  transition: all 0.1s ease-out;
  z-index: 2; }

.c-toggle__background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 3.5em;
  background-color: #fff;
  transition: all 0.1s ease-out; }

#inactive:checked ~ .c-toggle__switcher {
  right: 0;
  left: 50%; }

#inactive:checked ~ .c-toggle__background {
  background-color: #737373; }

/* support Windows High Contrast Mode. Credit: Adrian Roselli https://twitter.com/aardrian/status/1021372139990134785 */
@media screen and (-ms-high-contrast: active) {
  .c-toggle__switcher {
    background-color: windowText; } }

.editform h2 {
  display: inline-block;
  vertical-align: middle;
  float: left;
  width: calc(100% - 50px); }
  .editform h2 + a {
    display: inline-block;
    vertical-align: middle;
    padding: 0.25rem;
    color: #000;
    float: right; }

.form-control-color {
  padding: 10px;
  height: 52px; }

.deleteFile {
  margin-top: 10px; }
  .deleteFile span {
    vertical-align: text-bottom; }
    .deleteFile span i {
      vertical-align: bottom; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 0; }

.select2-container--default .select2-selection--multiple {
  border-radius: 0;
  border: 0; }

.select2-container .select2-selection--multiple {
  min-height: 52px; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 12px;
  margin-top: 9px; }

.select2-dropdown {
  border: 0;
  border-radius: 0; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #007fc0; }

.custom-file-control:invalid, .form-control:invalid, .custom-file-control:invalid {
  box-shadow: none;
  background-image: linear-gradient(to top, #007fc0 2px, rgba(0, 127, 192, 0) 2px), linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px); }

.form-text.error {
  color: #d50000; }

#pexels .fileThumbnail img {
  max-width: 150px; }

#pexels_search {
  padding: 20px;
  border: 1px solid #ccc; }

.nav-tabs .nav-link {
  border-bottom: 0;
  font-size: 0.75rem;
  background-color: #d8d8d8;
  color: #000;
  border-radius: 0;
  margin-right: 0.25rem;
  padding: 0.5rem; }
  @media (min-width: 768px) {
    .nav-tabs .nav-link {
      padding: 0.875rem 1rem;
      font-size: 1rem;
      margin-right: 0.5rem;
      border: solid  #d8d8d8; } }
  .nav-tabs .nav-link.active {
    background-color: #007fc0;
    color: #fff;
    border: solid #424242; }

.nav-tabs .cad-link {
  background-color: #007fc0;
  color: #fff;
  border: solid  #007fc0; }
  .nav-tabs .cad-link.active {
    border: solid #424242; }

.tab-content {
  margin-bottom: 1rem; }

#xxss-img .nav-tabs {
  margin-top: 30px; }
  @media (max-width: 767.98px) {
    #xxss-img .nav-tabs .nav-item {
      width: 100%; } }
  #xxss-img .nav-tabs .nav-link {
    font-size: 1rem;
    padding: 0.875rem 1rem;
    margin-bottom: 1px;
    text-transform: none; }
    @media (min-width: 768px) {
      #xxss-img .nav-tabs .nav-link {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border: 0; } }
    #xxss-img .nav-tabs .nav-link.active {
      background-color: #fff;
      color: #000; }

#xxss-img .tab-content {
  padding: 30px;
  margin-bottom: 50px;
  background-color: #fff;
  border-radius: 8px;
  border-top-left-radius: 0;
  -webkit-box-shadow: 0px 3px 23px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 23px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 23px -1px rgba(0, 0, 0, 0.2); }
  @media (min-width: 768px) {
    #xxss-img .tab-content {
      padding: 50px; } }
  #xxss-img .tab-content #pexels_search {
    border: 0px; }
    #xxss-img .tab-content #pexels_search .pexels-input-search {
      position: relative; }
    #xxss-img .tab-content #pexels_search input {
      background-color: #eaeaea;
      height: 56px;
      padding-left: 20px;
      border-radius: 6px; }
    #xxss-img .tab-content #pexels_search button {
      position: absolute;
      right: 15px;
      top: 0; }
  #xxss-img .tab-content #imatge_pexels {
    margin: 0 auto;
    width: fit-content; }
  #xxss-img .tab-content #pexels_search_xarxes {
    border: 0px; }
    #xxss-img .tab-content #pexels_search_xarxes .pexels-input-search_xarxes {
      position: relative; }
    #xxss-img .tab-content #pexels_search_xarxes input {
      background-color: #eaeaea;
      height: 56px;
      padding-left: 20px;
      border-radius: 6px; }
    #xxss-img .tab-content #pexels_search_xarxes button {
      position: absolute;
      right: 15px;
      top: 0; }
  #xxss-img .tab-content #imatge_pexels_xarxes {
    margin: 0 auto;
    width: fit-content; }

.tooltip {
  font-weight: 700;
  text-transform: uppercase; }

.tooltip.show {
  opacity: 1; }

.tooltip-inner {
  color: #fff;
  background-color: #00befc;
  border-radius: 0;
  font-size: 11px; }

.bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #00befc; }

.bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #00befc; }

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #00befc; }

.bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #00befc; }

.actions-list {
  list-style: none;
  font-size: 0.75rem;
  font-weight: 900;
  text-transform: uppercase;
  border-top: 1px solid #afafaf;
  padding-left: 0; }
  .actions-list > li > a,
  .actions-list > li > div > a {
    display: block;
    padding: 1.25rem 0.5rem;
    color: #5a5a5a;
    border-bottom: 1px solid #afafaf; }
    .actions-list > li > a:hover i,
    .actions-list > li > div > a:hover i {
      color: #00befc;
      text-decoration: none; }
  .actions-list i,
  .actions-list span {
    color: #000;
    vertical-align: middle;
    margin-right: 1rem; }
  .actions-list .dropdown .icon {
    display: inline-flex; }

.plantilla-select {
  text-align: center;
  padding: 2rem 0;
  margin: 0; }
  @media (min-width: 768px) {
    .plantilla-select {
      padding: 3rem 0; } }
  .plantilla-select--selected {
    background-color: #a2e7fe; }
  .plantilla-select h2 {
    font-weight: 400; }
    @media (min-width: 768px) {
      .plantilla-select h2 {
        margin-bottom: 1.5rem; } }
  .plantilla-select img {
    margin-bottom: 1rem;
    max-width: 145px; }
    @media (min-width: 768px) {
      .plantilla-select img {
        margin-bottom: 1.5rem; } }

.actions .icon {
  color: #000;
  vertical-align: middle;
  margin-left: 0.5rem;
  padding-top: 7px; }

.item--image {
  position: relative; }
  .item--image .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease; }
  .item--image img {
    width: 100%;
    height: 8.8125rem;
    object-fit: cover; }
  .item--image:hover .overlay {
    display: block;
    background: rgba(0, 0, 0, 0.3); }
  .item--image:hover .item--image-actions {
    opacity: 1; }
  .item--image-actions {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    text-align: center;
    opacity: 0;
    transition: opacity 0.35s ease;
    transform: translateY(-50%); }
  .item--image .btn, .item--image .custom-file-control::before {
    background-color: #ffffff;
    color: #007fc0; }
    .item--image .btn:hover, .item--image .custom-file-control:hover::before {
      background-color: rgba(153, 153, 153, 0.9);
      border-color: rgba(153, 153, 153, 0.9); }
  .item--image--selected .overlay {
    display: block;
    background: rgba(0, 127, 192, 0.6); }
  .item--image--selected .item--image-actions {
    opacity: 1; }

.text-normal {
  text-transform: none !important; }

.plantilla-edit {
  position: relative; }
  @media (max-width: 767.98px) {
    .plantilla-edit {
      margin-top: 3rem; } }
  @media (min-width: 768px) {
    .plantilla-edit {
      top: -5px; } }
  .plantilla-edit h2 {
    font-weight: 400;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem; }
    .plantilla-edit h2 + a {
      display: inline-block;
      vertical-align: middle;
      padding: 0.25rem;
      color: #000; }
      .plantilla-edit h2 + a i {
        vertical-align: middle; }

.plantilla-preview {
  background-color: #fff;
  margin: 1rem 0;
  padding: 1rem;
  clear: both; }
  @media (min-width: 768px) {
    .plantilla-preview {
      padding: 2rem 4rem; } }
  .plantilla-preview .logo {
    display: block;
    margin: 0 auto 2rem; }
  .plantilla-preview ul {
    list-style: none;
    padding-left: 0; }

.plantilla-element {
  background-color: #a2e7fe;
  padding: 1rem 3rem 1rem 1rem;
  margin-bottom: 1rem;
  position: relative;
  transition: background-color 0.2s ease-in-out; }
  .plantilla-element:last-child {
    margin-bottom: 0; }
  .plantilla-element:hover {
    cursor: move; }
  .plantilla-element--empty {
    min-height: 4rem;
    background-color: #f2f2f2;
    padding-right: 1rem; }
    .plantilla-element--empty:hover {
      cursor: default; }
  .plantilla-element span.move {
    margin: -1rem -3rem -1rem -1rem;
    padding: 1rem 3rem 1rem 1rem;
    display: block; }
  .plantilla-element .title {
    font-weight: bold;
    text-transform: none;
    color: #323232;
    margin-bottom: 0; }
  .plantilla-element .deleteContent,
  .plantilla-element .editContent,
  .plantilla-element .icon-done {
    display: block;
    position: absolute;
    color: #000; }
  .plantilla-element .deleteContent {
    right: -1.75rem;
    top: 0;
    color: #000; }
  .plantilla-element .editContent {
    color: #000;
    top: 0.5rem;
    right: 0.5rem; }
  .plantilla-element .icon-done {
    display: none;
    top: 0.25rem;
    right: 0.25rem;
    color: #fff; }
  .plantilla-element--highlight {
    background-color: #56d3fd; }
    .plantilla-element--highlight .deleteContent {
      display: none; }
    .plantilla-element--highlight .icon-done {
      display: block; }
  .plantilla-element .alert {
    margin: 0 0 .75rem;
    padding: .5rem;
    line-height: 110%;
    font-size: .85rem;
    position: relative; }
    .plantilla-element .alert.alert-warning:after {
      left: 100%;
      top: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-left-color: #fff3cd;
      border-top-color: #fff3cd;
      border-width: 5px; }
    .plantilla-element .alert.alert-danger:after {
      left: 100%;
      top: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-left-color: #f8d7da;
      border-top-color: #f8d7da;
      border-width: 5px; }

.buttons {
  display: flex;
  justify-content: space-between; }

ul.ui-sortable li.ui-sortable-placeholder {
  background-color: #ebebeb;
  padding: 1rem 0;
  margin-bottom: 1rem; }

.editContent.rippleEffect:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
  animation: ripple 1s infinite; }

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1; }
  20% {
    transform: scale(10, 10);
    opacity: 1; }
  100% {
    opacity: 0;
    transform: scale(20, 20); } }

.preview-template {
  width: 100%;
  margin-bottom: 1.5rem;
  height: 40rem;
  border: 0; }
  @media (min-width: 768px) {
    .preview-template {
      height: 45rem;
      margin-bottom: 0; } }
  @media (min-width: 768px) {
    .preview-template {
      height: 50rem;
      margin-bottom: 0; } }

.preview-text {
  margin-bottom: 1.5rem; }
  .mfp-content .preview-text {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .preview-text {
      margin-bottom: 0; } }
  .preview-text h1 {
    text-transform: none;
    padding-bottom: 0;
    border: 0;
    font-size: normal; }
  .preview-text p,
  .preview-text ul,
  .preview-text ol {
    font-size: 1.125rem; }
    @media (min-width: 768px) {
      .preview-text p,
      .preview-text ul,
      .preview-text ol {
        font-size: 1.25rem; }
        .mfp-content .preview-text p, .mfp-content
        .preview-text ul, .mfp-content
        .preview-text ol {
          font-size: 1.125rem; } }
    .preview-text p.smallest,
    .preview-text ul.smallest,
    .preview-text ol.smallest {
      font-size: .75rem; }
    .preview-text p.smaller,
    .preview-text ul.smaller,
    .preview-text ol.smaller {
      font-size: .875rem; }
    .preview-text p.small,
    .preview-text ul.small,
    .preview-text ol.small {
      font-size: 1rem; }
    .preview-text p.big,
    .preview-text ul.big,
    .preview-text ol.big {
      font-size: 1.5rem; }
    .preview-text p.bigger,
    .preview-text ul.bigger,
    .preview-text ol.bigger {
      font-size: 1.75rem; }
    .preview-text p.biggest,
    .preview-text ul.biggest,
    .preview-text ol.biggest {
      font-size: 2rem; }
  .preview-text .summary {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem; }
    .mfp-content .preview-text .summary {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0; }
    .preview-text .summary p {
      margin-bottom: 0; }
      @media (min-width: 768px) {
        .preview-text .summary p {
          font-size: 1.25rem; }
          .mfp-content .preview-text .summary p {
            font-size: 1.125rem; } }
      @media (min-width: 992px) {
        .preview-text .summary p {
          font-size: 1.5rem; }
          .mfp-content .preview-text .summary p {
            font-size: 1.125rem; } }
  .preview-text blockquote {
    margin: 3rem 0;
    font-style: italic;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    padding: 1.5rem 0 1.5rem 6rem;
    position: relative; }
    .preview-text blockquote:before {
      content: "";
      display: block;
      background: url("img/round-format_quote-24px.svg") no-repeat;
      background-size: 100%;
      width: 80px;
      height: 80px;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      transform: scaleX(-1); }
    .preview-text blockquote p {
      margin-bottom: 0; }
  .preview-text ul,
  .preview-text ol {
    padding-left: 4rem; }
  .preview-text ul {
    list-style: none; }
    .preview-text ul li {
      position: relative; }
      .preview-text ul li:before {
        content: "";
        width: 0.5em;
        height: 0.5em;
        display: block;
        position: absolute;
        background-color: #007fc0;
        left: -1em;
        top: 0.5em; }
  .preview-text img {
    max-width: 100%; }
  .preview-text p.attachment {
    /*font-size: 1rem;*/
    margin-top: 2rem;
    margin-bottom: 2rem; }
    .preview-text p.attachment a {
      padding: 0.25em 0 0.25em 30px;
      background: url("img/round-attach_file-24px.png") 0 50% no-repeat; }
      .svg .preview-text p.attachment a {
        background-image: url("img/round-attach_file-24px.svg"); }
  .preview-text .subtitle {
    font-weight: 700;
    text-transform: uppercase;
    margin: 2rem 0; }
  .preview-text table td,
  .preview-text table th {
    vertical-align: top;
    border-bottom: 1px solid #666;
    padding: 0.5rem; }
  .preview-text table th {
    vertical-align: bottom; }
  .preview-text p img {
    margin-bottom: 1.5rem; }

#the-canvas {
  width: 90%;
  max-width: 900px;
  display: block;
  margin: 0 auto; }

.pdf_navigation {
  text-align: center;
  padding: 20px; }
  .pdf_navigation button {
    background-color: transparent !important; }

.image-preview img {
  max-width: 100%; }

.page-item:first-child .page-link:hover,
.page-item:last-child .page-link:hover {
  background-color: transparent; }

.page-link {
  border-radius: 0 !important;
  border: 0; }

.resetSearchFilter i {
  vertical-align: bottom; }

.alert.alert-flash {
  position: fixed;
  width: 60%;
  left: 50%;
  margin-left: -30%;
  /* Half of the width */
  text-align: center;
  top: 3rem;
  z-index: 10;
  opacity: 0.95; }
  .alert.alert-flash p {
    margin-bottom: 0; }

.dropdown-menu {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }

.dropdown--icon {
  display: inline; }

.dropdown-share {
  min-width: 7rem;
  font-size: 0.875rem;
  text-transform: none;
  padding-top: 0;
  padding-bottom: 0; }
  .dropdown-share .dropdown-item {
    padding: 0.5rem 1rem;
    min-height: none; }

.profiled-content a {
  color: #666666; }
  .profiled-content a:hover {
    color: #333; }

.profiled-content header {
  background: none; }

.profiled-content footer {
  background: none;
  color: #333; }
  .profiled-content footer p {
    color: #333;
    margin-bottom: 1rem; }
  .profiled-content footer a {
    text-decoration: none; }

.profiled-content .corporative--mark {
  height: 33px;
  background-color: #ccc; }

.profiled-content .preview-text .summary {
  border-bottom: 5px solid #ccc; }

.profiled-content .preview-text blockquote {
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc; }

.profiled-content .preview-text blockquote::before {
  content: '“';
  font-size: 110px;
  color: #ccc;
  display: block;
  text-align: left;
  width: 100%;
  transform: none;
  font-weight: bold;
  top: 0;
  left: .5rem; }

.profiled-content .preview-text ul li::before {
  background-color: #ccc; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 15%;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #ccc; }
    .mfp-preloader a:hover {
      color: #fff; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 1;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #000; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 1;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #fff;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3f3f3f; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #fff;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3f3f3f; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: none;
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: none;
    background: #444; }
  .mfp-figure small {
    color: #bdbdbd;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem; } }

.mfp-preloader {
  color: #000; }

.mfp-container {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.mfp-content .wrapper {
  border-radius: 6px; }

.mfp-content .btn.btn-primary:hover, .mfp-content .btn-primary.custom-file-control:hover::before {
  background-color: rgba(153, 153, 153, 0.6);
  color: #fff; }

.mfp-close {
  width: 70px;
  height: 70px;
  line-height: 70px; }

.mfp-content .mfp-iframe-actions {
  position: absolute;
  width: 100%;
  bottom: -80px; }

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
  color: #000;
  right: 0;
  top: 0;
  text-align: center;
  padding-right: 0;
  width: 70px; }

.mfp-image-holder .mfp-close {
  color: #fff;
  right: -50px;
  top: -7px; }

.mfp-iframe-scaler iframe {
  border-radius: 6px; }

/*edge + ie*/
_:-ms-lang(x),
body.vex-open  {
  overflow: auto; }

.vex-overlay {
  background: rgba(0, 0, 0, 0.3);
  padding: 1.5rem; }

.vex.vex-theme-plain .vex-content {
  font-family: lato, sans-serif;
  color: #000;
  border-radius: 6px; }

.vex.vex-theme-plain .vex-dialog-button {
  border-radius: 7px;
  letter-spacing: 0;
  font-size: 0.875rem;
  padding: 1rem 1.5rem;
  font-family: lato, sans-serif;
  font-weight: 900; }

.vex.vex-theme-plain .vex-dialog-button.vex-dialog-button-primary {
  background: #007fc0; }

/* pages */
body.login {
  background: url(./img/logo-fons.png) 50% 7em no-repeat #F8F8F8;
  background-size: auto auto;
  background-size: 60%; }
  @media (min-width: 768px) {
    body.login {
      background-size: 20%; } }
  body.login .card {
    margin-top: 190px; }
  @media (min-width: 576px) {
    body.login .card {
      margin-top: 260px; } }
  @media (min-width: 992px) {
    body.login .card {
      margin-top: 300px; } }
  body.login .formlogin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto; }
  body.login .container-404 {
    margin-top: 12em; }
    @media (min-width: 576px) {
      body.login .container-404 {
        margin-top: 15em; } }
    @media (min-width: 992px) {
      body.login .container-404 {
        margin-top: 20em; } }

picture,
.login-banner {
  background: #025a96;
  text-align: center;
  width: 100%;
  margin-bottom: 4em; }
  picture img,
  .login-banner img {
    max-width: 100%;
    height: auto; }
