.wrapper {
  padding: 3rem 0;
  &--simple {
    padding: 0;
  }
  &--1 {
    background-color: $gray-100;
  }
  &--2 {
    background-color: #eaeaea;
    background-image: linear-gradient(to bottom, #eaeaea, #efefef);
  }
}
