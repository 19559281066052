.nav-tabs {
    .nav-link {
        border-bottom: 0;
        font-size: 0.75rem;
        background-color: #d8d8d8;
        color: #000;
        border-radius: 0;
        margin-right: 0.25rem;
        padding: 0.5rem;
        @include media-breakpoint-up(md) {
        padding: 0.875rem 1rem;
        font-size: 1rem;
        margin-right: 0.5rem;
        border: solid  #d8d8d8;

        }
        &.active {
            background-color: #007fc0;
            color: #fff;
            border: solid #424242;
        }
    }
    .cad-link {
        background-color: #007fc0;
        color: #fff;
        border: solid  #007fc0;
        &.active {
            border: solid #424242;
        }
    }
}
.tab-content {
  margin-bottom: 1rem;
}
#xxss-img {
 .nav-tabs {
    margin-top: 30px;
    .nav-item {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    .nav-link {
        font-size: 1rem;
        padding: 0.875rem 1rem;
        @include media-breakpoint-up(md) {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border: 0;
        }
        margin-bottom: 1px;
        text-transform: none;
        &.active {
            background-color: #fff;
            color: #000;
          }

    }
 }
 .tab-content {
    padding: 30px;
    margin-bottom: 50px;
    background-color: #fff;
    border-radius: 8px;
    border-top-left-radius: 0;
    -webkit-box-shadow: 0px 3px 23px -1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 3px 23px -1px rgba(0,0,0,0.2);
    box-shadow: 0px 3px 23px -1px rgba(0,0,0,0.2);
    @include media-breakpoint-up(md) {
        padding: 50px;
    }
    #pexels_search {
        border: 0px;
        .pexels-input-search {
            position: relative;
        }
        input {
            background-color: rgb(234, 234, 234);
            height: 56px;
            padding-left: 20px;
            border-radius: 6px;
        }
        button {
            position: absolute;
            right: 15px;
            top: 0;
        }
    }
    #imatge_pexels {
        margin: 0 auto;
        width: fit-content;
    }

    #pexels_search_xarxes {
      border: 0px;
      .pexels-input-search_xarxes {
          position: relative;
      }
      input {
          background-color: rgb(234, 234, 234);
          height: 56px;
          padding-left: 20px;
          border-radius: 6px;
      }
      button {
          position: absolute;
          right: 15px;
          top: 0;
      }
    }
    #imatge_pexels_xarxes {
        margin: 0 auto;
        width: fit-content;
    }
 }
}
